<template>
  <!-- 生态水位满足率 -->
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  export default {
    components: {
      Pie
    },
    props: {
      ecologicalWaterLevelSatisfactionInfo: {
        type: [Number || String],
        default: null
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      let total = this.ecologicalWaterLevelSatisfactionInfo
      let dayOfYear = this.ecologicalWaterLevelSatisfactionInfo > 365 ? this.ecologicalWaterLevelSatisfactionInfo : 365
      this.options = {
        title: [
          {
            text: (total / dayOfYear).toFixed(2) * 100 + '%',
            x: 'center',
            top: 'center',
            textStyle: {
              fontSize: '20',
              color: 'rgb(60, 212, 149)',
              fontFamily: 'Lato',
              foontWeight: '600'
            }
          }
        ],
        polar: {
          radius: ['74%', '84%'],
          center: ['50%', '50%']
        },
        angleAxis: {
          max: dayOfYear,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: false,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(66, 66, 66, .3)'
            },
            data: [total],
            coordinateSystem: 'polar',

            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: 'rgb(60, 212, 149)'
                  },
                  {
                    offset: 1,
                    color: '#38a700'
                  }
                ])
              }
            }
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            radius: ['90%', '92%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: 'rgba(66, 66, 66, .6)',
                shadowBlur: 10,
                shadowColor: 'rgba(253, 249, 20, .3)'
              }
            },
            data: [
              {
                value: 100
              }
            ]
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            radius: ['92.5%', '94%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: 'rgba(66, 66, 66, .4)',
                shadowBlur: 10,
                shadowColor: 'rgba(253, 249, 20, .3)'
              }
            },
            data: [
              {
                value: 100
              }
            ]
          },
          {
            name: '',
            type: 'pie',
            startAngle: 90,
            radius: ['94.5%', '95.8%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: 'rgba(66, 66, 66, .3)',
                shadowBlur: 10,
                shadowColor: 'rgba(253, 249, 20, .3)'
              }
            },
            data: [
              {
                value: 100
              }
            ]
          },
          {
            name: '',
            type: 'pie',
            startAngle: 0,
            radius: ['96.5%', '97.5%'],
            hoverAnimation: false,
            center: ['50%', '50%'],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false
                },
                color: 'rgba(66, 66, 66, .2)',
                shadowBlur: 10,
                shadowColor: 'rgba(253, 249, 20, .3)'
              }
            },
            data: [
              {
                value: 100
              }
            ]
          }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
  }
</style>
