<template>
  <div></div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      targetData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {}
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val) {
          if (val.length > 0) {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData(data) {
        console.log(data)
      }
    }
  }
</script>
<style lang="less" scoped></style>
