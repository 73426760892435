import { render, staticRenderFns } from "./singleScore.vue?vue&type=template&id=54bb59d6&scoped=true&"
import script from "./singleScore.vue?vue&type=script&lang=js&"
export * from "./singleScore.vue?vue&type=script&lang=js&"
import style0 from "./singleScore.vue?vue&type=style&index=0&id=54bb59d6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54bb59d6",
  null
  
)

export default component.exports