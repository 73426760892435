<template>
  <div id="bar" ref="myEchart"></div>
</template>

<script type="text/babel">
  import * as echarts from 'echarts'
  export default {
    name: 'Bar',
    props: {
      options: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        data: this.options
      }
    },
    watch: {
      options: {
        handler: function (val, oldVal) {
          // this.initChart(val);
          this.myChart.setOption(val)
        },
        deep: true
      }
    },
    mounted() {
      let obj = this.$refs.myEchart
      if (obj) {
        this.initChart(this.data)
      }
    },

    methods: {
      // 图表初始化数据
      initChart(option) {
        if (this.$refs.myEchart == null) {
          return
        }
        echarts.dispose(this.$refs.myEchart)
        let myChart = echarts.init(this.$refs.myEchart)
        myChart.setOption(option)
        window.onresize = myChart.resize

        this.myChart = myChart
      }
    }
  }
</script>

<style lang="less" scoped>
  #bar {
    width: 100%;
    height: 100%;
  }
</style>
