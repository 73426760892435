// 苏州标准--湖泊对象服务功能维度指标数据展示页面
<template>
  <div class="page-body">
    <div style="height: 66%; display: flex">
      <div style="width: 33%; display: grid; height: 100%">
        <div style="width: 100%; height: 50%" :class="{ isActive: showTarget == 'D11' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              防洪达标率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ floodControlStandard.score }}</span>
            </div>
          </div>
          <div style="display: flex; justify-content: center">
            <div style="height: 160px; width: 150px">
              <FloodControlStandardRate :flood-control-standard-info="floodControlStandard.data" />
            </div>
            <div>
              <div style="margin-top: 60px; margin-left: 10px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{
                    floodControlStandard.length && floodControlStandard.length.toFixed(0)
                  }}</span>m
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">达标河流长度</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D12' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              水功能区达标率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ waterFunctionalArea.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 160px; width: 150px; margin-left: 50px">
              <FloodControlStandardRate :flood-control-standard-info="waterFunctionalArea.data" />
              <!-- <WaterFunctionQu :water-functional-area-info="waterFunctionalArea.data" /> -->
            </div>
            <div>
              <div style="margin-top:60px;margin-left：10px;">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{
                    waterFunctionalArea.times
                  }}</span>
                  次
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">水质达标测次</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 66%; display: table-cell; text-align: center; vertical-align: middle; position: relative">
        <Map :rl-data="objInfo.obj" />
        <div class="pic-container">
          <!-- <el-carousel :interval="2000" type="card" height="180px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="300px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel> -->
          <MySwiper :url-list="picList" />
        </div>
      </div>
    </div>
    <div style="height: 33%; display: flex">
      <div style="width: 100%" :class="{ isActive: showTarget == 'D13' ? true : false }">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            饮用水源地水质达标率
          </div>
          <div class="target-title-score">
            得分:
            <span>-</span>
          </div>
        </div>
        <div style="text-align: center">
          <div style="height: 50px">
            <div style="margin-top: 30px">
              <p style="color: rgb(255, 255, 255); font-size: 1.5vh">可选指标：本次未选用评判</p>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%" :class="{ isActive: showTarget == 'D14' ? true : false }">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            景观舒适度
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ landscapeComfort.score }}</span>
          </div>
        </div>
        <div style="display: flex">
          <div style="height: 200px; width: 300px; margin: 0 auto">
            <LandscapeComfort :landscape-comfort-info="landscapeComfort.data" />
          </div>
        </div>
      </div>
      <div style="width: 100%" :class="{ isActive: showTarget == 'D15' ? true : false }">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            公众满意度
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ publicSatisfactionScore }}</span>
          </div>
        </div>
        <div style="display: flex">
          <div style="height: 160px; width: 300px; margin: 0 auto">
            <BarChart :public-satisfaction="publicSatisfaction" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FloodControlStandardRate from './pageChart/FloodControlStandardRate.vue'
import WaterFunctionQu from './pageChart/WaterFunctionQu.vue'
import BarChart from '../mapChart/barChart.vue'
import LandscapeComfort from './pageChart/LandscapeComfort.vue'

import Map from '../map/objMap.vue'

const log = console.log
export default {
  components: {
    FloodControlStandardRate,
    // WaterFunctionQu,
    BarChart,
    LandscapeComfort,
    Map
  },
  props: {
    lakeService: {
      type: Object,
      default: () => { }
    },
    publicSatisfaction: {
      type: Array,
      default: () => []
    },
    objInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      targetDInfoData: [],
      floodControlStandard: {},
      waterFunctionalArea: {},
      landscapeComfort: {},
      publicSatisfactionScore: null,
      showTarget: '',

      picList: []
    }
  },
  created() {
    this.childData(this.lakeService)
    console.log(this.targetDInfoData)
    this.picList = []

    this.targetDInfoData.forEach((item) => {
      if (item.targetEntity?.queryExt) {
        for (let key in item.targetEntity.queryExt) {
          if (key.length == 32) {
            this.picList.push(item.targetEntity.queryExt[key])
          }
        }
      }
      if (item.code == 'D11') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: item.targetEntity.funVal,
          length: item.targetEntity.standardLength
        }
        this.floodControlStandard = param
      } else if (item.code == 'D12') {
        // let param = {
        //   score: item.scoreVal?.toFixed(1),
        //   data: {
        //     total: item.targetEntity?.zdDetailList?.length,
        //     standardMeasurement:
        //       (item.targetEntity?.zdDetailList?.length * item.targetEntity?.funVal?.toFixed(1)) / 100
        //   }
        // }
        // this.waterFunctionalArea = param
        let param = {
          score: item.scoreVal?.toFixed(1),
          data: item.targetEntity?.funVal || 100, // 达标测次比例
          times: item.targetEntity?.okNum || 0// 达标测次
        }
        this.waterFunctionalArea = param
      } else if (item.code == 'D13') {
        let param = {
          score: item.scoreVal?.toFixed(1)
        }
      } else if (item.code == 'D14') {
        let guanShangXing = 0
        let gsxLen = 0
        let qinShuiBianLiXing = 0
        let qsxLen = 0
        let shuiWenHuaJianShe = 0
        let swhLen = 0
        item.targetEntity?.detailList.forEach((par) => {
          if (par.gsxScore && par.gsxScore != '') {
            guanShangXing += parseInt(par.gsxScore)
            gsxLen++
          }
          if (par.qmdScore && par.qmdScore != '') {
            qinShuiBianLiXing += parseInt(par.qmdScore)
            qsxLen++
          }
          if (par.swhScore && par.swhScore != '') {
            shuiWenHuaJianShe += parseInt(par.swhScore)
            swhLen++
          }
        })
        let param = {
          score: item.scoreVal.toFixed(1),
          data: {
            guanShangXing: guanShangXing / (gsxLen > 0 ? gsxLen : 1),
            qinShuiBianLiXing: qinShuiBianLiXing / (qsxLen > 0 ? qsxLen : 1),
            shuiWenHuaJianShe: shuiWenHuaJianShe / (swhLen > 0 ? swhLen : 1)
          }
        }
        this.landscapeComfort = param
      } else if (item.code == 'D15') {
        this.publicSatisfactionScore = item.scoreVal.toFixed(1)
      }
    })
  },
  methods: {
    /** 遍历D层指标数据 */
    childData(info) {
      if (info['children']) {
        info.children.forEach((item) => {
          if (item['children']) {
            this.childData(item)
          } else {
            this.targetDInfoData.push(item)
          }
        })
      } else {
        this.targetDInfoData.push(info)
      }
    },
    changeTarget(val) {
      this.showTarget = this.picList[val].code
    }
  }
}
</script>
<style lang="less" scoped>
.page-body {
  height: 72vh;
  overflow: hidden;
  //   background: rgba(20, 31, 41, 0.3);
  //   margin: -20px;
}

.target-title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.9);

  .target-title-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(60, 212, 149, 0.9);
    border-radius: 50%;
    // <!--设置圆点与其后面的内容水平显示-->
    display: inline-block;
    margin-right: 5px;
  }

  .target-title-name {
    font-size: 1.7vh;
    margin-left: 20px;
  }

  .target-title-score {
    vertical-align: middle;
    margin-right: 20px;

    span {
      color: rgb(60, 212, 149);
      font-size: 20px;
    }
  }
}

.isActive {
  background-color: rgba(60, 212, 149, 0.1);
  margin: 2px 0;
}

.pic-container {
  position: absolute;
  bottom: 2px;
  left: 5%;
  width: 90%;
  margin: 0 auto;
}
</style>
