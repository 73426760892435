<template>
  <div class="main-container">
    <div id="cmap1" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script>
  import 'ol/ol.css'
  import { Map, View, Feature } from 'ol'
  import { defaults as defaultControls } from 'ol/control'
  import { defaults } from 'ol/interaction'
  import TileLayer from 'ol/layer/Tile'
  import VectorLayer from 'ol/layer/Vector'
  import OSM from 'ol/source/OSM'
  import Vector from 'ol/source/Vector'
  import { Style, Circle, Fill, Stroke, Text, Icon } from 'ol/style'
  import WKT from 'ol/format/WKT'
  import XYZ from 'ol/source/XYZ'
  import { getCenter } from 'ol/extent'
  import WMTSTileGrid from 'ol/tilegrid/WMTS'
  import WMTS from 'ol/source/WMTS'
  import { getTopLeft, getWidth } from 'ol/extent'
  import { get as getProjection } from 'ol/proj'

  const extent = [120.3569667238902, 30.757833911398727, 120.89901672389161, 31.219523911397924]
  const pointJson = require('./point.json')

  export default {
    name: '',
    components: {},
    props: {
      rlData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        map: null, // 地图对象
        osmTile: null, // 图片图层

        imageAnnotation: null, // 影像注记图层

        featureList: [] //河湖对象图层列表
      }
    },
    computed: {},
    watch: {
      rlData: {
        immediate: true,
        handler(val) {
          if (val && val != {}) {
            // this.layerDataHandle(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {
      /** 地图底图 */
      this.osmTile = new TileLayer({
        source: new XYZ({
          name: '中国',
          url:
            'http://' +
            this.MY_MAP_LEVEL_NUM +
            '.tianditu.com/DataServer?T=img_c&x={x}&y={y}&l={z}&tk=26ee4341dc4c0c4269d2222be779e168',
          projection: 'EPSG:4326',
          tileLoadFunction: function (imageTitle, src) {
            const img = new Image()
            img.crossOrigin = ''
            img.onload = () => {
              const canvas = document.createElement('canvas')
              const w = img.width
              const h = img.height
              canvas.width = w
              canvas.height = h
              const context = canvas.getContext('2d')
              context.drawImage(img, 0, 0, w, h, 0, 0, w, h)
              const imgData = context.getImageData(0, 0, w, h)
              context.putImageData(imgData, 0, 0)
              imageTitle.getImage().src = canvas.toDataURL('image/png')
            }
            img.src = src
          }
        })
      })
      /** 影像注记--图层 */
      let resolutions = []
      for (let z = 2; z < 19; ++z) {
        resolutions[z] = getWidth(getProjection('EPSG:4326').getExtent()) / 256 / Math.pow(2, z)
      }
      this.imageAnnotation = new TileLayer({
        source: new WMTS({
          name: '中国矢量注记1-4级',
          url: 'http://' + this.MY_MAP_LEVEL_NUM + '.tianditu.gov.cn/cia_c/wmts?tk=65235e208cae9448e9f3a85d55c74bda',
          layer: 'cia',
          style: 'default',
          matrixSet: 'c',
          format: 'tiles',
          wrapX: true,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(getProjection('EPSG:4326').getExtent()),
            resolutions: resolutions,
            matrixIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
          })
        })
      })

      this.map = new Map({
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false
        }),
        target: 'cmap1',
        layers: [this.osmTile],
        // 地图视图信息（中心店，坐标系，图层级别）
        view: new View({
          // extent: [120.1069667238902, 30.707833911398727, 121.12601672389161, 31.519523911397924],
          projection: 'EPSG:4326',
          center: getCenter(extent),
          zoom: 8,
          maxZoom: 18
        }),
        interactions: defaults({
          // doubleClickZoom: false, // 取消双击放大功能交互
          // mouseWheelZoom: false, // 取消滚动鼠标中间的滑轮交互
          // shiftDragZoom: false // 取消shift+wheel左键拖动交互
        })
      })
      this.layerDataHandle(this.rlData)
    },
    methods: {
      /** 地图加载渲染 */
      drawMap() {
        // if (this.map.getLayers().array_[1]) {
        //   this.map.removeLayer(this.vector)
        // }
        /** 区域水系对象--图层 */
        this.vector = new VectorLayer({
          source: new Vector({
            features: [...this.featureList]
          })
        })
        this.map.addLayer(this.vector)
      },
      /** 图层资源数据处理方法 */
      layerDataHandle(info) {
        let geoData = []
        geoData.push(info)
        this.featureList = []

        geoData.forEach((item) => {
          let wkt = item.geom
          let format = new WKT()
          let feature = format.readFeature(wkt, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326'
          })
          feature.setProperties({
            id: item.id,
            name: item.name
          })
          let colorStroke = 'rgba(26, 177, 214, 1)'
          let colorFill = 'rgba(26, 177, 214, 0.1)'
          let styleHh = new Style({
            stroke: new Stroke({
              color: colorStroke,
              width: 2
            }),
            fill: new Fill({
              color: colorFill
            })
          })
          feature.setStyle(styleHh)

          this.featureList.push(feature)
        })
        let format = new WKT()
        let feature = format.readFeature(info.geom, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326'
        })
        var geo = feature.getGeometry()
        var extent = geo.getExtent()

        let resolution = this.map.getView().getResolutionForExtent(extent, this.map.getSize())
        this.map.getView().fit(extent, {
          // duration: 2000, //动画的持续时间,
          // minZoom: 1
        })
        this.map.getView().setResolution(resolution)

        this.drawMap()
      }
    }
  }
</script>
<style lang="less" scoped>
  .main-container {
    width: 98%;
    height: 98%;
    margin: 0 auto;
  }
</style>
