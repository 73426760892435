// 苏州标准--湖泊对象生态健康维度指标数据展示页面
<template>
  <div class="page-body">
    <div style="height: 65%; display: flex">
      <div style="width: 24%; display: grid; height: 100%">
        <div style="width: 100%" :class="{ isActive: showTarget == 'D1' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              口门畅通率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ openDoorData.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 100px; width: 150px; margin-left: 50px; padding-top: 20px">
              <OpenDoor :open-door-info="openDoorData.data" />
            </div>
            <div style="height: 100px">
              <div style="margin-top: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ openDoorData.data.ni }}</span>&nbsp;个
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">畅通口门数</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D2' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              湖滨带植被覆盖度
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ lakesideVegetation.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 100px; width: 150px; margin-left: 50px; padding-top: 20px">
              <LakesideVegetation :lakeside-vegetation-info="lakesideVegetation.data" />
            </div>
            <div style="height: 100px">
              <div style="margin-top: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{
                    lakesideVegetation.data.haveVegetationArea
                  }}</span>
                  m<sup>2</sup>
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">植被覆盖面积</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D3' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              湖水交换率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ lakeWaterExchange.score }}</span>
            </div>
          </div>
          <div style="text-align: center">
            <div style="height: 100px; width: 250px; padding-top: 20px; margin: 0 auto">
              <LakeWaterExchange :lake-water-exchange-info="lakeWaterExchange.data" />
            </div>
            <div style="height: 50px">
              <div style="margin-top: -25px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ lakeWaterExchange.data.Q }}</span>m³
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">入湖水量</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="
          width: 52%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          overflow-y: auto;
          position: relative;
        ">
        <Map :rl-data="objInfo.obj" />
        <div class="pic-container">
          <!-- <el-carousel :interval="2000" type="card" height="250px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="420px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel> -->
          <MySwiper :url-list="picList" />
        </div>
      </div>
      <div style="width: 24%; display: grid">
        <div style="width: 100%" :class="{ isActive: showTarget == 'D4' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              生态水位满足率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ ecologicalWaterLevelSatisfaction.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 100px; width: 150px; margin-left: 50px; padding-top: 20px">
              <EcologicalWaterLevelSatisfaction
                :ecological-water-level-satisfaction-info="ecologicalWaterLevelSatisfaction.data" />
            </div>
            <div style="height: 100px">
              <div style="margin-top: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{
                    ecologicalWaterLevelSatisfaction.data
                  }}</span>天
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">满足目标水位</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D8' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              入湖河流水质达标率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ waterQualityStandard.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 100px; width: 150px; margin-left: 50px; padding-top: 20px">
              <WaterQualityStandard :water-quality-standard-info="waterQualityStandard.data" />
            </div>
            <div style="height: 100px">
              <div style="margin-top: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{
                    waterQualityStandard.data.standardMeasurement
                  }}</span>次
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">满足水功能达标测次</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D9' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              蓝藻密度
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ cyanobacteriaDensity.score }}</span>
            </div>
          </div>
          <div style="display: flex; text-align: center">
            <div style="height: 100px; margin: 0 auto">
              <div style="margin-top: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ cyanobacteriaDensity.data }}</span>万cell/L
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">单位体积湖泊水体蓝藻个数</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 35%; width: 100%; overflow: auto">
      <Szhi :target-data="waterQuality" :score="szScore" :class="{ isActive: showTarget == 'D5' ? true : false }" />
      <Dqsw :target-data="benthos" :score="dxScore" :class="{ isActive: showTarget == 'D10' ? true : false }" />
      <Yyzt :target-data="nutritionalStatus" :score="yyztScore"
        :class="{ isActive: showTarget == 'D6' ? true : false }" />
    </div>
  </div>
</template>
<script>
import OpenDoor from './pageChart/OpenDoor.vue'
import LakesideVegetation from './pageChart/LakesideVegetation.vue'
import LakeWaterExchange from './pageChart/LakeWaterExchange.vue'
import EcologicalWaterLevelSatisfaction from './pageChart/EcologicalWaterLevelSatisfaction.vue'
import WaterQualityStandard from './pageChart/WaterQualityStandard.vue'

import Szhi from './targetTable/shuizhi.vue'
import Dqsw from './targetTable/dqsw.vue'
import Yyzt from './targetTable/yyzt.vue'

import Map from '../map/objMap.vue'

const log = console.log

export default {
  components: {
    OpenDoor,
    LakesideVegetation,
    LakeWaterExchange,
    EcologicalWaterLevelSatisfaction,
    WaterQualityStandard,
    Szhi,
    Dqsw,
    Yyzt,

    Map
  },
  props: {
    lakeEcology: {
      type: Object,
      default: () => { }
    },
    waterPic: {
      type: String,
      default: null
    },
    objInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      data1: [],
      targetDInfoData: [],

      openDoorData: {}, // 口门畅通率数据
      lakesideVegetation: {}, // 湖滨带植被覆盖度
      lakeWaterExchange: {}, // 湖水交换率
      ecologicalWaterLevelSatisfaction: {}, // 生态水位满足率
      waterQualityStandard: {}, // 入湖水质达标
      cyanobacteriaDensity: {}, //蓝藻密度
      waterQuality: [], //水质
      nutritionalStatus: [], // 营养程度
      benthos: [], // 底栖生物
      waterSystemUrl: this.waterPic,
      szScore: null,
      yyztScore: null,
      dnScore: null,
      dxScore: null,

      showTarget: '',

      picList: []
    }
  },
  created() {
    this.childData(this.lakeEcology)
    console.log(this.targetDInfoData)
    this.picList = []
    this.targetDInfoData.forEach((item) => {
      if (item.targetEntity.queryExt) {
        for (let key in item.targetEntity.queryExt) {
          if (key.length == 32) {
            this.picList.push(item.targetEntity.queryExt[key])
          }
        }
      }
      if (item.code == 'D1') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: {
            total: item.targetEntity?.detailList?.length,
            ni: (item.targetEntity?.detailList?.length * item.targetEntity?.funVal) / 100
          }
        }
        this.openDoorData = param
      } else if (item.code == 'D2') {
        let totalArea = 0
        let lakesideVegetationArea = 0
        item.targetEntity?.detailList.forEach((par) => {
          totalArea += parseInt((par.length || 0) * (par.width || 0))
          lakesideVegetationArea += parseInt(((par.length || 0) * (par.width || 0) * (par.percentage || 0)) / 100)
        })
        let param = {
          score: item.scoreVal.toFixed(1),
          data: {
            total: totalArea,
            haveVegetationArea: lakesideVegetationArea
          }
        }
        this.lakesideVegetation = param
      } else if (item.code == 'D3') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: {
            Q: item.targetEntity.qp,
            V: item.targetEntity.vp
          }
        }
        this.lakeWaterExchange = param
      } else if (item.code == 'D4') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: item.targetEntity.tp
        }
        this.ecologicalWaterLevelSatisfaction = param
      } else if (item.code == 'D8') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: {
            total: item.targetEntity.np,
            standardMeasurement: item.targetEntity.rp
          }
        }
        this.waterQualityStandard = param
      } else if (item.code == 'D9') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: item.targetEntity.funVal
        }
        this.cyanobacteriaDensity = param
      } else if (item.code == 'D5') {
        this.szScore = item.scoreVal.toFixed(1)
        this.waterQuality = item.targetEntity?.detailList
      } else if (item.code == 'D6') {
        this.yyztScore = item.scoreVal.toFixed(1)
        this.nutritionalStatus = item.targetEntity
      } else if (item.code == 'D10') {
        this.dxScore = item.scoreVal.toFixed(1)
        this.benthos = item.targetEntity
      } else if (item.code == 'D7') {
        this.dnScore = item.scoreVal.toFixed(1)
        console.log(item)
      }
    })
  },
  methods: {
    childData(info) {
      if (info['children']) {
        info.children.forEach((item) => {
          if (item['children']) {
            this.childData(item)
          } else {
            this.targetDInfoData.push(item)
          }
        })
      } else {
        this.targetDInfoData.push(info)
      }
    },
    tableData(info) {
      let arr = []
      for (let i = 1; i < info[0].length; i++) {
        let name = info[0][i].val
        let value
        if (info[1][i].val.indexOf('.') != -1) {
          value = info[1][i].val.substr(0, info[1][i].val.indexOf('.') + 3)
        } else {
          value = info[1][i].val
        }

        arr.push({ name, value })
      }
      return arr
    },
    changeTarget(val) {
      this.showTarget = this.picList[val].code
    }
  }
}
</script>
<style lang="less" scoped>
.page-body {
  height: 90vh;
  max-height: 90vh;
  overflow: hidden;
  background: rgba(20, 31, 41, 0.3);
}

.target-title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.9);

  .target-title-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(60, 212, 149, 0.9);
    border-radius: 50%;
    // <!--设置圆点与其后面的内容水平显示-->
    display: inline-block;
    margin-right: 5px;
  }

  .target-title-name {
    font-size: 1.7vh;
    margin-left: 20px;
  }

  .target-title-score {
    vertical-align: middle;
    margin-right: 20px;

    span {
      color: rgb(60, 212, 149);
      font-size: 20px;
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
  /*滚动条宽度*/
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  /*滑动条表面*/
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  /*滑动条边框*/
  border-radius: 2px;
  /*滑动条圆角宽度*/
}

/deep/ .el-table,
.el-table__expanded-cell {
  background-color: transparent;
}

/deep/ .el-table th {
  background-color: transparent !important;
  font-size: 20px;
  color: #fefefe;
}

/deep/ .el-table tr {
  background-color: transparent !important;
  color: #fefefe;
}

/deep/ .el-table tr.hover {
  background-color: transparent !important;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
/deep/.el-table .cell {
  background-color: transparent;
}

/deep/ .el-table .el-table__body tr.hover-row>td {
  background-color: transparent !important;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: transparent !important;
}

/deep/ .el-table--border th.el-table__cell,
.el-table__fixed-right-patch {
  border: 1px solid #f6f6f669;
}

/deep/ .el-table--border .el-table__cell,
.el-table td.el-table__cell {
  border: 1px solid #f6f6f669;
}

.isActive {
  background-color: rgba(60, 212, 149, 0.1);
  margin: 2px 0;
}

.pic-container {
  position: absolute;
  bottom: 2px;
  left: 5%;
  width: 90%;
  margin: 0 auto;
}

// .el-carousel__item.el-carousel__item--card.is-in-stage {
//   // width: 30%;
//   img {
//     position: absolute;
//     width: 200px;
//     top: 50%;
//     aspect-ratio: 16/9;
//   }
// }
// .el-carousel__item.is-active.el-carousel__item--card.is-in-stage {
//   // width: 50%;
//   img {
//     top: 0%;
//     width: 400px;
//     aspect-ratio: 16/9;
//   }
// }
</style>
