<template>
  <div id="pie" ref="myEchart"></div>
</template>

<script type="text/babel">
  import * as echarts from 'echarts'
  export default {
    name: 'Pie',
    props: {
      options: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        data: this.options
      }
    },
    mounted() {
      let obj = this.$refs.myEchart
      if (obj) {
        this.initChart()
      }
    },
    methods: {
      // 图表初始化数据
      initChart() {
        if (this.$refs.myEchart == null) {
          return
        }
        echarts.dispose(this.$refs.myEchart)
        let myChart = echarts.init(this.$refs.myEchart)
        let option = this.data
        let that = this
        myChart.on('click', function (event) {
          that.$store.dispatch('targetInfo/changeTargetId', event.data.id)
        })

        myChart.setOption(option)
        window.onresize = myChart.resize
      },
      eConsole(param) {
        if (typeof param.seriesIndex == 'undefined') {
          return
        }
        if (param.type == 'click') {
          alert(param.data + 1)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  #pie {
    width: 100%;
    height: 100%;
  }
</style>
