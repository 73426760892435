<template>
  <div>
    <div id="fraction" class="lakeFraction">
      <div class="lakeFraction-top">
        <div class="area-rl-score-img">
          <img src="../../../assets/icon/area_river.png" alt />
        </div>
        <div class="area-rl-score">
          <p>河流</p>
          <code>{{ riverScore }}</code
          >分
        </div>
      </div>
      <div class="lakeFraction-top">
        <div class="area-rl-score-img">
          <img src="../../../assets/icon/area_lake.png" alt />
        </div>
        <div class="area-rl-score">
          <p>湖泊</p>
          <code>{{ lakeScore }}</code
          >分
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'TotalScore',
    components: {},
    props: {
      riverScore: {
        type: [String, Number],
        default: null
      },
      lakeScore: {
        type: [String, Number],
        default: null
      }
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
  }
</script>
<style lang="less" scoped>
  .lakeFraction {
    position: absolute;
    z-index: 2000;
    left: 23vw;
    top: 100px;
    .lakeFraction-top {
      width: 222px;
      height: 88px;
      background: rgba(20, 31, 41, 0.3);
      display: flex;
      margin-bottom: 24px;
      box-sizing: border-box;
      .area-rl-score-img {
        height: 88px;
        width: 70px;
        img {
          width: 51px;
          height: 51px;
          display: block;
          margin: 20px 0 20px 16px;
        }
      }
      .area-rl-score {
        color: rgba(255, 255, 255, 0.7);
        font-family: PingFang SC;
        font-size: 16px;
        vertical-align: middle;
        margin-left: 12px;
        margin-right: 16px;
        width: 130px;
        p {
          margin: 12px 0 0 0;
        }
        code {
          color: rgba(60, 212, 149, 1);
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 40px;
          line-height: 40px;
          text-transform: lowercase;
        }
      }
    }
  }
</style>
