<template>
  <div id="boxRight">
    <div class="object-right-top">
      <div @click="getALLdesc(1)"><MyTitle title="生态健康" :score="B1Score" /></div>

      <div id="txChart1A">
        <dv-scroll-ranking-board class="dv-scr-rank" :config="evaluatingRankingB1" />
      </div>
    </div>
    <div class="object-right-center">
      <div @click="getALLdesc(2)">
        <MyTitle title="服务功能" :score="B2Score" />
      </div>
      <div class="public">
        <RadarChart :radar-data="radarData" />
      </div>
    </div>
    <div class="object-right-centerBar">
      <div @click="getALLdesc(3)">
        <MyTitle title="干扰程度" :score="B3Score" />
      </div>
      <div class="zxtjChartA">
        <BarCircleChart :interference-data="interferenceData" />
      </div>
    </div>
    <div class="object-right-bottom">
      <div @click="getALLdesc(4)">
        <MyTitle title="管理水平" :score="B4Score" />
      </div>
      <div class="zxtjChartA">
        <PieBingTuChart :funnel-data="funnelData" />
      </div>
    </div>

    <!-- 湖泊生态健康 -->
    <el-dialog
      append-to-body
      :visible.sync="lakeTargetFlag1"
      :fullscreen="true"
      custom-class="test-dialog"
      title="生态健康"
    >
      <PageEcology
        v-if="lakeTargetFlag1"
        :lake-ecology="lakeEcology"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
    <!-- 湖泊服务功能 -->
    <el-dialog
      append-to-body
      :visible.sync="lakeTargetFlag2"
      width="60vw"
      top="8vh"
      custom-class="test-dialog"
      title="服务功能"
    >
      <PageService
        v-if="lakeTargetFlag2"
        :lake-service="lakeService"
        :public-satisfaction="publicSatisfaction"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
    <!-- 湖泊干扰程度 -->
    <el-dialog
      append-to-body
      :visible.sync="lakeTargetFlag3"
      width="65vw"
      top="8vh"
      custom-class="test-dialog"
      title="干扰程度"
    >
      <PageInterfere
        v-if="lakeTargetFlag3"
        :lake-interfere="lakeInterfere"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
    <!-- 湖泊管理水平 -->
    <el-dialog
      append-to-body
      :visible.sync="lakeTargetFlag4"
      width="80vw"
      top="12vh"
      custom-class="test-dialog"
      title="管理水平"
    >
      <PageManagement
        v-if="lakeTargetFlag4"
        :lake-management="lakeManagement"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
    <!-- 河流生态健康 -->
    <el-dialog
      append-to-body
      :visible.sync="riverTargetFlag1"
      :fullscreen="true"
      custom-class="test-dialog"
      title="生态健康"
    >
      <PageRiverEcology
        v-if="riverTargetFlag1"
        :river-ecology="riverEcology"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
    <!-- 河流服务功能 -->
    <el-dialog
      append-to-body
      :visible.sync="riverTargetFlag2"
      width="60vw"
      top="8vh"
      custom-class="test-dialog"
      title="服务功能"
    >
      <PageRiverService
        v-if="riverTargetFlag2"
        :river-service="riverService"
        :public-satisfaction="publicSatisfaction"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
    <!-- 河流干扰程度 -->
    <el-dialog
      :visible.sync="riverTargetFlag3"
      width="65vw"
      top="8vh"
      custom-class="test-dialog"
      title="干扰程度"
      append-to-body
    >
      <PageRiverInterfere
        v-if="riverTargetFlag3"
        :river-interfere="riverInterfere"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
    <!-- 河流管理水平 -->
    <el-dialog
      append-to-body
      :visible.sync="riverTargetFlag4"
      width="80vw"
      top="12vh"
      custom-class="test-dialog"
      title="管理水平"
    >
      <PageRiverManagement
        v-if="riverTargetFlag4"
        :river-management="riverManagement"
        :water-pic="waterSystemMapUrl"
        :obj-info="objInfo"
      />
    </el-dialog>
  </div>
</template>
<script>
  import RadarChart from '../mapChart/radarChart.vue'
  import BarCircleChart from '../mapChart/barCircleChart.vue'
  import PieBingTuChart from '../mapChart/pieBingTuChart.vue'

  import PageEcology from '../objPage/pageEcology.vue'
  import PageService from '../objPage/pageService.vue'
  import PageInterfere from '../objPage/pageInterfere.vue'
  import PageManagement from '../objPage/pageManagement.vue'
  import PageRiverEcology from '../objPage/pageRiverEcology.vue'
  import PageRiverService from '../objPage/pageRiverService.vue'
  import PageRiverInterfere from '../objPage/pageRiverInterfere.vue'
  import PageRiverManagement from '../objPage/pageRiverManagement.vue'

  export default {
    name: 'RightInfo',
    components: {
      RadarChart,
      BarCircleChart,
      PieBingTuChart,

      PageEcology,
      PageService,
      PageInterfere,
      PageManagement,
      PageRiverEcology,
      PageRiverService,
      PageRiverInterfere,
      PageRiverManagement
    },
    props: {
      rlInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        objInfo: {},
        evaluatingRankingB1: {}, // 生态健康指标排行

        rlChartData: [], // 单一河湖对象图标数据
        B1Score: null, // B1 层级分数
        ciYunData: [], // 词云数据
        B2Score: null, // B2层级分数
        radarData: [], // 雷达图数据
        B3Score: null, // B3层级分数
        interferenceData: [], // 饼图数据
        B4Score: null, // B4层级分数
        funnelData: [], // 漏斗数据

        targetDInfoData: [],

        publicSatisfaction: [],

        lakeEcology: {}, // 湖泊对象生态健康指标内容数据填充
        lakeService: {}, // 湖泊对象服务功能指标内容数据填充
        lakeInterfere: {}, // 湖泊对象干扰程度指标内容数据填充
        lakeManagement: {}, // 湖泊对象管理水平指标内容数据填充
        riverEcology: {}, // 河流对象生态健康指标内容数据填充
        riverService: {}, // 河流对象服务功能指标内容数据填充
        riverInterfere: {}, // 河流对象干扰程度指标内容数据填充
        riverManagement: {}, // 河流对象管理水平指标内容数据填充
        lakeTargetFlag1: false, // 湖泊生态健康控制器
        lakeTargetFlag2: false, // 湖泊服务功能控制器
        lakeTargetFlag3: false, // 湖泊干扰程度控制器
        lakeTargetFlag4: false, // 湖泊管理水平控制器
        riverTargetFlag1: false, // 河流生态健康控制器
        riverTargetFlag2: false, // 河流服务功能控制器
        riverTargetFlag3: false, // 河流干扰程度控制器
        riverTargetFlag4: false, // 河流管理水平控制器

        waterSystemMapUrl: null // 对象水系图
      }
    },
    computed: {},
    watch: {
      rlInfo: {
        immediate: true,
        handler(val) {
          if (JSON.stringify(val) != '{}') {
            this.loadData(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData(data) {
        let choosenRlInfo = data
        // if (choosenRlInfo['waterSystemMapUrl']) {
        //   this.waterSystemMapUrl = choosenRlInfo.waterSystemMapUrl
        // }
        if (choosenRlInfo['target']) {
          let scheme = choosenRlInfo.target
          this.objInfo = choosenRlInfo
          if (choosenRlInfo.qusVo) {
            // 调查问卷部分页面绘制
            this.publicSatisfaction = [
              choosenRlInfo.qusVo.veryGood,
              choosenRlInfo.qusVo.good,
              choosenRlInfo.qusVo.middle,
              choosenRlInfo.qusVo.bad,
              choosenRlInfo.qusVo.disable
            ]
          }
          if (scheme.children.length > 0) {
            for (let i = 0; i < scheme.children.length; i++) {
              if (scheme.children[i].code == 'B1') {
                this.ciYunDataDo(scheme.children[i])
              }
              if (scheme.children[i].code == 'B2') {
                this.radarDataDo(scheme.children[i])
              }
              if (scheme.children[i].code == 'B3') {
                this.interferenceDataDo(scheme.children[i])
              }
              if (scheme.children[i].code == 'B4') {
                this.funnelDataDo(scheme.children[i])
              }
            }
          }
        }
      },
      /** 处理单个河湖对象词云图表数据 */
      ciYunDataDo(info) {
        this.B1Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.ciYunData = []
        this.childData(info)

        this.rlChartData.forEach((item) => {
          let param = {
            name: item.name,
            value: item.value
          }
          this.ciYunData.push(param)
        })
        // this.ciYunData = this.rlChartData;
        this.evaluatingRankingB1 = {
          data: this.ciYunData, // 河湖对象详细指标评分信息数据
          rowNum: 4
        }
      },
      /** 处理单个河湖对象雷达图表数据 */
      radarDataDo(info) {
        this.B2Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.radarData = []
        this.childData(info)
        this.radarData = this.rlChartData
      },
      /** 获取指标层信息数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              let param = {
                name: item.name,
                value: item.scoreVal.toFixed(1)
              }
              this.rlChartData.push(param)
              this.targetDInfoData.push(item)
            }
          })
        }
      },
      /** 处理单个河湖对象饼图图表数据 */
      interferenceDataDo(info) {
        this.B3Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.interferenceData = []
        this.childData(info)
        this.interferenceData = this.rlChartData
      },
      /** 处理单个河湖对象饼图图表数据 */
      funnelDataDo(info) {
        this.B4Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.funnelData = []
        this.childData(info)
        this.funnelData = this.rlChartData
      },

      /** 获取四大类详细指标 */
      getALLdesc(info) {
        console.log(this.objInfo)
        if (this.objInfo.rlType == 1) {
          if (info == 1) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B1') {
                this.riverEcology = this.objInfo.target.children[i]
              }
            }
            this.riverTargetFlag1 = true
          } else if (info == 2) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B2') {
                this.riverService = this.objInfo.target.children[i]
              }
            }
            this.riverTargetFlag2 = true
          } else if (info == 3) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B3') {
                this.riverInterfere = this.objInfo.target.children[i]
              }
            }
            this.riverTargetFlag3 = true
          } else if (info == 4) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B4') {
                this.riverManagement = this.objInfo.target.children[i]
              }
            }
            this.riverTargetFlag4 = true
          }
        } else {
          if (info == 1) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B1') {
                this.lakeEcology = this.objInfo.target.children[i]
              }
            }
            this.lakeTargetFlag1 = true
          } else if (info == 2) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B2') {
                this.lakeService = this.objInfo.target.children[i]
              }
            }
            this.lakeTargetFlag2 = true
          } else if (info == 3) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B3') {
                this.lakeInterfere = this.objInfo.target.children[i]
              }
            }
            this.lakeTargetFlag3 = true
          } else if (info == 4) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B4') {
                this.lakeManagement = this.objInfo.target.children[i]
              }
            }
            this.lakeTargetFlag4 = true
          }
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '../../../assets/css/elui.less';

  #boxRight {
    position: absolute;
    width: 20.8vw;
    right: 24px;
    top: 90px;
    box-sizing: border-box;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    transition-property: right;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    z-index: 100;
  }

  .object-right-top {
    width: 19.2vw;
    height: 17.8vh;

    margin: 1.5vh 0.8vw 1.5vh 0.8vw;
    position: relative;
    #txChart1A {
      width: 19.2vw;
      height: 90%;
      .dv-scr-rank {
        width: 100%;
        height: 80%;
        box-sizing: border-box;
        margin-top: 15px;
        /deep/ .row-item {
          height: auto;
        }
        /deep/ .ranking-info {
          height: 14px;
          .rank {
            display: none;
          }
          .info-name {
            font-size: 12px;
            margin-top: 5px;
            color: rgba(255, 255, 255, 0.9);
            font-family: PingFang SC;
          }
          .ranking-value {
            margin-top: 4px;
            color: rgba(60, 212, 149, 1);
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
          }
        }
        /deep/ .ranking-column {
          margin-top: 8px;
          background: rgba(60, 212, 149, 0.1);
          border-bottom: none;
          .inside-column {
            height: 10px;
            border: 1px solid rgba(60, 212, 149, 0.7);
            box-sizing: border-box;
            background-color: rgba(60, 212, 149, 0.2);
          }
        }
      }
    }
  }
  .object-right-center {
    width: 19.2vw;
    height: 27vh;
    margin: 0 0.8vw 0 0.8vw;
    position: relative;
    .public {
      width: 19.2vw;
      height: 90%;
      padding: 10px 0 0;
    }
  }
  .object-right-centerBar {
    width: 19.2vw;
    height: 21.2vh;
    margin: 0 0.8vw 0 0.8vw;
    position: relative;
    .zxtjChartA {
      width: 19.2vw;
      height: 88%;
    }
  }
  .object-right-bottom {
    width: 19.2vw;
    height: 18.7vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;

    .zxtjChartA {
      width: 19.2vw;
      height: 88%;
    }
  }

  /deep/.el-dialog__header {
    border: none;
  }
  /deep/.el-carousel__mask {
    height: 0%;
  }
</style>
