// 景观舒适度

<template>
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  export default {
    components: {
      Pie
    },
    props: {
      landscapeComfortInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      let dataArr = [
        {
          name: '亲水便利性',
          value: this.landscapeComfortInfo.qinShuiBianLiXing
        },
        {
          name: '观赏性',
          value: this.landscapeComfortInfo.guanShangXing
        },
        {
          name: '水文化建设',
          value: this.landscapeComfortInfo.shuiWenHuaJianShe
        }
      ]
      this.options = {
        color: ['rgb(60, 212, 149)', 'rgba(50, 84, 221, 1)', 'rgb(90, 169, 251)'],

        tooltip: {
          show: false
        },

        toolbox: {
          show: false
        },
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: false,
            radius: [50, 55],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'outside',
                  color: 'rgb(255, 255, 255)',
                  formatter: function (params) {
                    var percent = 0
                    var total = 0
                    for (var i = 0; i < dataArr.length; i++) {
                      total += dataArr[i].value
                    }
                    percent = ((params.value / total) * 100).toFixed(0)
                    if (params.name !== '') {
                      return params.name + '\n' + percent + '%'
                    } else {
                      return ''
                    }
                  },
                  fontSize: 14
                },
                labelLine: {
                  length: 20,
                  length2: 20,
                  show: false,
                  color: '#00ffff'
                }
              }
            },
            data: [
              {
                value: dataArr[0].value,
                name: dataArr[0].name,
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    shadowBlur: 20,
                    borderColor: 'rgb(60, 212, 149)',
                    shadowColor: 'rgb(60, 212, 149)'
                  }
                }
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0
                  }
                }
              },
              {
                value: dataArr[1].value,
                name: dataArr[1].name,
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    shadowBlur: 20,
                    borderColor: 'rgba(50, 84, 221, 1)',
                    shadowColor: 'rgba(50, 84, 221, 1)'
                  }
                }
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0
                  }
                }
              },
              {
                value: dataArr[2].value,
                name: dataArr[2].name,
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    shadowBlur: 20,
                    borderColor: 'rgb(90, 169, 251)',
                    shadowColor: 'rgb(90, 169, 251)'
                  }
                }
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0
                  }
                }
              }
            ]
          },
          {
            type: 'pie',
            radius: ['40', '43'],
            data: [100],
            color: ['#39D1FA5C'],
            label: {
              show: false
            }
          },
          {
            type: 'pie',
            radius: ['62', '63'],
            data: [100],
            color: '#000',
            label: {
              show: false
            }
          }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
  }
</style>
