<template>
  <div id="boxLeft">
    <!-- 地区整体信息左侧信息列表 -->
    <div>
      <div class="area-left-top">
        <MyTitle title="地区简介" />
        <div class="area-desc">
          <div class="area-desc-ani-1">
            <img class="text-leftImage" src="../../../assets/img/text-leftImage.jpg" align="left" width="224" alt />
            <p>
              吴江区位于苏州市南部，在北纬30°45′36″～31°13′41″，东经120°21′04″～120°53′59″之间，东西最大距离
              <span class="text-2">52.67</span>km，南北最大距离 <span class="text-2">52.07</span>km，全区总面积
              <span class="text-2">1237.4</span
              >km2。吴江区东接上海市青浦区，西南邻浙江省嘉兴市和湖州市，北与吴中区相接，西傍太湖。 吴江区北距苏州市中心
              <span class="text-2">16</span>km，南距嘉兴 <span class="text-2">20</span>km，东距上海
              <span class="text-2">70</span>km，东南离杭州 <span class="text-2">120</span>km，西南离湖州
              <span class="text-2">40</span>km。京杭大运河和苏嘉杭高速公路经市区东部穿过。
            </p>
            <img class="text-leftImage" src="../../../assets/img/text-leftImage.jpg" align="left" width="224" alt />
            <p>
              吴江区位于苏州市南部，在北纬30°45′36″～31°13′41″，东经120°21′04″～120°53′59″之间，东西最大距离
              <span class="text-2">52.67</span>km，南北最大距离 <span class="text-2">52.07</span>km，全区总面积
              <span class="text-2">1237.4</span
              >km2。吴江区东接上海市青浦区，西南邻浙江省嘉兴市和湖州市，北与吴中区相接，西傍太湖。 吴江区北距苏州市中心
              <span class="text-2">16</span>km，南距嘉兴 <span class="text-2">20</span>km，东距上海
              <span class="text-2">70</span>km，东南离杭州 <span class="text-2">120</span>km，西南离湖州
              <span class="text-2">40</span>km。京杭大运河和苏嘉杭高速公路经市区东部穿过。
            </p>
          </div>
        </div>
      </div>
      <!-- center  -->
      <div class="area-left-top">
        <MyTitle title="区域水系" />
        <div class="area-desc">
          <div class="area-desc-ani-2">
            <img class="text-leftImage" src="../../../assets/img/area-water.jpg" align="left" width="224" alt />
            <p>
              吴江地处太湖东畔，水资源丰富，是传统的江南水乡地区。吴江区境内湖荡星罗棋布，全区水域面积
              <span class="text-2">323.6</span>km2，其中太湖水面面积
              <span class="text-2">59.9</span
              >km2。境内水系以太浦河为界分为浦南、浦北2大片，浦南属杭嘉湖水网区、浦北属淀泖水网区。江南运河纵贯南北两片，起到了调节和承转境内水量的作用。
              浦北片主要来水为东太湖的出水，部分水流自苏州由运河、斜港和吴淞江南岸各口门注入，另有部分水流由太浦河经运河、窑港注入。东泄通道北有澄湖、白砚湖，南有八荡河、元荡进入淀山湖。浦南片来水以澜溪塘、頔塘为干流，澜溪塘南受浙江乌镇市河和横泾港来水，頔塘西受东西苕溪来水，两河之源同出天目山区，会于平望莺脰湖，一股由老运河南行至大坝港东泄，一股由雪湖（六里厍）入太浦河，一股由新运河北行至太浦河，浦南片主要东泄河道为太浦河。
            </p>
            <img class="text-leftImage" src="../../../assets/img/area-water.jpg" align="left" width="224" alt />
            <p>
              吴江地处太湖东畔，水资源丰富，是传统的江南水乡地区。吴江区境内湖荡星罗棋布，全区水域面积
              <span class="text-2">323.6</span>km2，其中太湖水面面积
              <span class="text-2">59.9</span
              >km2。境内水系以太浦河为界分为浦南、浦北2大片，浦南属杭嘉湖水网区、浦北属淀泖水网区。江南运河纵贯南北两片，起到了调节和承转境内水量的作用。
              浦北片主要来水为东太湖的出水，部分水流自苏州由运河、斜港和吴淞江南岸各口门注入，另有部分水流由太浦河经运河、窑港注入。东泄通道北有澄湖、白砚湖，南有八荡河、元荡进入淀山湖。浦南片来水以澜溪塘、頔塘为干流，澜溪塘南受浙江乌镇市河和横泾港来水，頔塘西受东西苕溪来水，两河之源同出天目山区，会于平望莺脰湖，一股由老运河南行至大坝港东泄，一股由雪湖（六里厍）入太浦河，一股由新运河北行至太浦河，浦南片主要东泄河道为太浦河。
            </p>
          </div>
        </div>
      </div>
      <div class="area-left-bottom">
        <MyTitle title="评估方法" />
        <div class="assess">
          <div class="assess-pic">
            <img src="../../../assets/icon/icon_assess-green.png" alt />
          </div>
          <div class="assess-text" @click="schemeDel(1)">
            <div class="assess-text-del">
              <div>评价标准</div>
              <div class="delImg">详情</div>
            </div>
            <div class="assess-area">
              <div>《河湖健康评价规范》</div>
              <div class="assess-area-rightBottom">
                <img src="../../../assets/icon/assess_right-green.png" alt />
              </div>
            </div>
          </div>
        </div>
        <div class="assess">
          <div class="assess-pic">
            <img src="../../../assets/icon/icon-assess-art-green.png" alt />
          </div>
          <div class="assess-text" @click="schemeDel(2)">
            <div class="assess-text-del">
              <div>技术路线</div>
              <div class="delImg">详情</div>
            </div>
            <div class="assess-area">
              <div>吴江河湖健康评估技术路线</div>
              <div class="assess-area-rightBottom">
                <img src="../../../assets/icon/assess_right-green.png" alt />
              </div>
            </div>
          </div>
        </div>
        <div class="assess">
          <div class="assess-pic">
            <img src="../../../assets/icon/icon-assess-test-green.png" alt />
          </div>
          <div class="assess-text" @click="schemeDel(3)">
            <div class="assess-text-del">
              <div>检测标准</div>
              <div class="delImg">详情</div>
            </div>
            <div class="assess-area">
              <div>苏州市检验标准</div>
              <div class="assess-area-rightBottom">
                <img src="../../../assets/icon/assess_right-green.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="70vw"
      :title="delTitle"
      top="10vh"
      append-to-body
    >
      <div class="agreement_picture" style="height: 70vh; overflow-y: auto">
        <!-- <div class="pdf"> -->
        <div v-if="!isFileList" style="width: 70vw; height: 100%">
          <iframe v-if="isFile" :src="delSrc" frameborder="0" style="width: 70vw; height: 100%"></iframe>
          <!-- </div> -->
          <div v-if="!isFile" style="width: 50%; margin: 0 auto">
            <img :src="delSrc" style="width: 100%; margin: 0 auto" />
          </div>
        </div>
        <div v-if="isFileList" style="width: 100%; height: 100%">
          <div class="target-all-info" style="display: flex">
            <div class="target-list" style="overflow-y: auto">
              <ul type="none">
                <li v-for="(item, index) in fileList" :key="index">
                  <el-button :id="'button111' + index" type="text" class="score-target" @click="getFile(item)">
                    <p class="target-name">{{ item.customName || item.uploadFile.name }}</p>
                  </el-button>
                </li>
              </ul>
            </div>
            <div class="target-content" style="width: 70%; height: 100%">
              <iframe :src="fileSrcGB" frameborder="0" style="width: 100%; height: 100%"></iframe>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { ReportApi } from '@/api/report/index'

  // const FileList = require('./fileList.json')

  export default {
    name: '',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dialogVisible: false,
        isFileList: false,
        fileSrcGB: null,
        isFile: false,
        delTitle: '', // 地区评估方法弹窗标题
        delSrc: '', // 地区评估方法地址 src
        roadFile: [],
        cityFile: [],
        industryFile: []
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.loadFile()
    },
    methods: {
      /**
       * @description   首页评估工作部分展示内容方法
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      schemeDel(info) {
        if (info === 1) {
          this.delTitle = '评价标准'
          this.delSrc = this.cityFile[0].completeUrl
          this.isFileList = false
          this.isFile = true
        } else if (info === 2) {
          this.delTitle = '技术路线'
          this.delSrc = this.roadFile[0].completeUrl
          this.isFileList = false
          this.isFile = false
        } else {
          this.delTitle = '检测标准'
          this.isFileList = true
          this.fileList = this.industryFile
          setTimeout(function () {
            document.getElementById('button1110').click()
            document.getElementById('button1110').focus()
          }, 10)
        }
        this.dialogVisible = true
      },
      /**
       * @description
       * @param {*}
       *
       */
      loadFile() {
        let param = {
          pageSize: 860601
        }
        ReportApi.report_list(param).then((res) => {
          console.log('<== res ==>', res.data.data.list)
          let fileList = res.data.data.list
          this.cityFile = fileList.filter((file) => file.busType === 'evaluation_city')
          this.roadFile = fileList.filter((file) => file.busType === 'line_road')
          this.industryFile = fileList.filter((file) => file.busType === 'industry_standard')
        })
      },
      getFile(item) {
        this.fileSrcGB = item.completeUrl
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '../../../assets/css/elui.less';

  #boxLeft {
    position: absolute;
    width: 20.8vw;
    left: 24px;
    top: 100px;
    box-sizing: border-box;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    transition-property: left;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    z-index: 100;
  }

  //   区域左侧顶部、中部介绍
  .area-left-top {
    width: 19.2vw;
    // height: 29.6vh;
    margin: 1.5vh 0.8vw 1.1vh 0.8vw;
    .area-desc {
      width: 19.2vw;
      height: 23.7vh;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1.5vh;
      line-height: 200%;
      margin: 1.5vh 0 0 0;
      text-indent: 3vh;
      overflow-y: auto;
      // overflow: hidden;
      text-align: justify;
      .area-desc-ani-1 {
        -webkit-animation: 10s rowup1 linear infinite normal;
        animation: 10s rowup1 linear infinite normal;
        &:hover {
          animation-play-state: paused;
          -webkit-animation-play-state: paused;
        }
      }
      .area-desc-ani-2 {
        -webkit-animation: 10s rowup2 linear infinite normal;
        animation: 10s rowup2 linear infinite normal;
        &:hover {
          animation-play-state: paused;
          -webkit-animation-play-state: paused;
        }
      }
      .text-leftImage {
        margin-right: 8px;
        margin-bottom: 3px;
      }
      .text-2 {
        color: rgba(60, 212, 149, 1);
        font-family: PingFang SC;
        margin-left: -3px;
      }
      .text-3 {
        color: rgba(60, 212, 149, 1);
        margin-left: -3px;
      }
    }
  }
  /** 动画实现 */
  @-webkit-keyframes rowup1 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(0, -355px, 0);
      transform: translate3d(0, -355px, 0);
      display: none;
    }
  }

  @keyframes rowup1 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(0, -355px, 0);
      transform: translate3d(0, -355px, 0);
      display: none;
    }
  }

  /** 动画实现 */
  @-webkit-keyframes rowup2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(0, -558px, 0);
      transform: translate3d(0, -558px, 0);
      display: none;
    }
  }

  @keyframes rowup2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(0, -558px, 0);
      transform: translate3d(0, -558px, 0);
      display: none;
    }
  }

  // 区域左侧bottom
  .area-left-bottom {
    width: 19.2vw;
    // height: 30.1vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;
    .assess {
      width: 100%;
      height: 4.8vh;
      margin-top: 2vh;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      .assess-pic {
        height: 4.8vh;
        img {
          height: 100%;
        }
      }
      .assess-text {
        width: 16vw;
        .assess-text-del {
          height: 1.8vh;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.3vh;
          color: rgba(255, 255, 255, 0.5);
          .delImg {
            width: 2.3vw;
            background-image: url('../../../assets/icon/rightIcon-bg.png');
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 38%;
          }
        }
        .assess-area {
          color: rgba(255, 255, 255, 0.9);
          font-size: 1.5vh;
          margin-top: 0.8vh;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: bottom;
          font-family: PingFang SC;
          .assess-area-rightBottom {
            height: 1vh;
            width: 5.1vw;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .target-all-info {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .target-list {
      width: 25%;
      height: 100%;
      background: rgba(60, 212, 149, 0.04);
      ul {
        margin-left: -15px;
      }
      .score-target {
        width: 252px;
        height: 50px;
        text-align: left;
        padding: 0 10px;
        .target-name {
          color: rgba(255, 255, 255, 0.7);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
        }
        .target-score {
          display: none;
          color: rgba(255, 255, 255, 0.7);
          font-family: PingFang SC;
          font-size: 16px;
          span {
            color: rgba(60, 212, 149, 1);
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 24px;
          }
        }
      }
      .score-target:focus {
        width: 252px;
        height: 84px;
        padding: 10px 0 10px 18px;
        text-align: left;
        background: linear-gradient(90.3deg, rgba(60, 212, 149, 0.1) 0.19%, rgba(60, 212, 149, 0) 97.57%);
        .target-name {
          color: rgba(60, 212, 149, 1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
        }
        .target-score {
          display: block;
          color: rgba(255, 255, 255, 0.7);
          font-family: PingFang SC;
          font-size: 16px;
          span {
            color: rgba(60, 212, 149, 1);
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 24px;
          }
        }
      }
      .score-target:hover {
        .target-name {
          color: rgba(60, 212, 149, 1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
        }
      }
    }
    .target-content {
      width: 73%;
      height: 100%;
      overflow-y: auto;
      color: rgba(255, 255, 255, 0.7);
      margin-left: -70px;
      // background: rgba(60, 212, 149, 0.04);
      .all-pic {
        position: absolute;
        right: 10px;
        top: 10px;
      }
      .target-title {
        background: linear-gradient(90deg, rgba(0, 232, 255, 0.1) 0%, rgba(0, 232, 255, 0) 91.85%);
        backdrop-filter: blur(33px);
        color: rgba(60, 212, 149, 1);
        font-family: Source Han Sans CN;
        font-size: 14px;
        padding: 10px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
  }
</style>
