import { render, staticRenderFns } from "./FloodControlStandardRate.vue?vue&type=template&id=15c20423&scoped=true&"
import script from "./FloodControlStandardRate.vue?vue&type=script&lang=js&"
export * from "./FloodControlStandardRate.vue?vue&type=script&lang=js&"
import style0 from "./FloodControlStandardRate.vue?vue&type=style&index=0&id=15c20423&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c20423",
  null
  
)

export default component.exports