import request from '@/utils/request'
const BASE_URL = '/wjhhjk'
const PROINCE_BASE_URL = '/jssxfhh'

/**
 * @description 项目类
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Scheme {
  /** 获取评估项目对象所有数据 */
  static getData = (id) => {
    return request.get(`${BASE_URL}/scheme/pro/mapAll/` + id)
  }
  /** 获取指标项数据 */
  static getTargetInfo = (id) => {
    return request.get(`${BASE_URL}/admin/wj/findTargetDetailById/` + id)
  }
}

/**
 * @description 江苏省指标项目类
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class ProvinceScheme {
  /** 获取评估项目对象所有数据 */
  static getData = (id, isFlag) => {
    return request.get(`${PROINCE_BASE_URL}/scheme/pro/mapAll/` + id + '/' + isFlag)
  }
  /** 获取指标项数据 */
  static getTargetInfo = (id) => {
    return request.get(`${PROINCE_BASE_URL}/admin/wj/findTargetDetailById/` + id)
  }
}
