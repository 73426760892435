<template>
  <div>
    <el-table
      :data="szData"
      style="width: 100%; font-size: 12px; text-align: center"
      header-cell-class-name="table-header-cls"
      :border="true"
    >
      <el-table-column :label="'水质优劣程度 ' + score + ' 分'" align="center" header-align="center">
        <el-table-column prop="samplingDate" label="日期" align="center" header-align="center">
          <template slot-scope="scope">
            <div class="table-tow-row-container">
              <div>
                <span v-if="scope.row.samplingDate">{{ scope.row.samplingDate.slice(0, 7) }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shuiWen" label="水温（℃）" align="center" header-align="center" width="100">
        </el-table-column>
        <el-table-column prop="rongJieYang" label="溶解氧(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div class="table-tow-row-container">
              <div>
                <span>{{ scope.row.rongJieYang }}</span>
              </div>
              <div>
                <span>{{ scope.row.DOGrade }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="gaoMengSuanYan" label="高锰酸盐指数(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.gaoMengSuanYan }}</span>
            </div>
            <div>
              <span>{{ scope.row.permanganateGrade }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="huaXueXuYangLiang" label="化学需氧量(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.huaXueXuYangLiang }}</span>
            </div>
            <div>
              <span>{{ scope.row.CODGrade }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="anDan" label="氨氮(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.anDan }}</span>
            </div>
            <div>
              <span>{{ scope.row.ANGrade }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="zongLin" label="总磷(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.zongLin }}</span>
            </div>
            <div>
              <span>{{ scope.row.totalPGrade }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ph" label="PH值" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.ph }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="synthesis"
          label="综合评价类型"
          align="center"
          header-align="center"
          fixed="right"
        ></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      targetData: {
        type: Array,
        default: () => []
      },
      score: {
        type: [Number, String],
        default: null
      }
    },
    data() {
      return {
        szData: []
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (val.length > 0) {
            this.loadSzData(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 水质数据处理
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadSzData(data) {
        this.szData = []
        data.forEach((item) => {
          const i = Object.keys(item).findIndex((i) => {
            return "'anDan', 'gaoMengSuanYan', 'huaXueXuYangLiang',  'rongJieYang', 'samplingDate', 'ph', 'zongLin'".includes(
              i
            )
          })
          if (i != -1) {
            this.szData.push(this.countLine(item))
          }
        })
      },
      /**
       * @description 表格数据内容依据GB3838 评定水质类别
       * @author hu_ty
       * @since
       * @param {object} data 所在行数据（单次采样化验数据）
       *
       */
      countLine(data) {
        if (data.anDan && data.anDan != 'NaN') {
          let an = parseFloat(data.anDan)
          let grade
          switch (true) {
            case an <= 0.15:
              grade = 'I'
              break
            case an <= 0.5:
              grade = 'Ⅱ'
              break
            case an <= 1.0:
              grade = 'Ⅲ'
              break
            case an <= 1.5:
              grade = 'Ⅳ'
              break
            case an <= 2.0:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
          data.ANGrade = grade
        }
        if (data.huaXueXuYangLiang && data.huaXueXuYangLiang != 'NaN') {
          let huaXueXuYangLiang = parseFloat(data.huaXueXuYangLiang)
          let grade
          switch (true) {
            case huaXueXuYangLiang <= 15:
              grade = 'Ⅱ'
              break
            case huaXueXuYangLiang <= 20:
              grade = 'Ⅲ'
              break
            case huaXueXuYangLiang <= 30:
              grade = 'Ⅳ'
              break
            case huaXueXuYangLiang <= 40:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
          data.CODGrade = grade
        }
        if (data.rongJieYang && data.rongJieYang != 'NaN') {
          let rongJieYang = parseFloat(data.rongJieYang)
          let grade
          switch (true) {
            case rongJieYang >= 7.5:
              grade = 'Ⅰ'
              break
            case rongJieYang >= 6:
              grade = 'Ⅱ'
              break
            case rongJieYang >= 5:
              grade = 'Ⅲ'
              break
            case rongJieYang >= 3:
              grade = 'Ⅳ'
              break
            case rongJieYang >= 2:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
          data.DOGrade = grade
        }
        if (data.gaoMengSuanYan && data.gaoMengSuanYan != 'NaN') {
          let gaoMengSuanYan = parseFloat(data.gaoMengSuanYan)
          let grade
          switch (true) {
            case gaoMengSuanYan <= 2:
              grade = 'Ⅰ'
              break
            case gaoMengSuanYan <= 4:
              grade = 'Ⅱ'
              break
            case gaoMengSuanYan <= 6:
              grade = 'Ⅲ'
              break
            case gaoMengSuanYan <= 10:
              grade = 'Ⅳ'
              break
            case gaoMengSuanYan <= 15:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
          data.permanganateGrade = grade
        }
        if (data.zongLin && data.zongLin != 'NaN') {
          let zongLin = parseFloat(data.zongLin)
          let grade
          switch (true) {
            case zongLin <= 0.02:
              grade = 'Ⅰ'
              break
            case zongLin <= 0.1:
              grade = 'Ⅱ'
              break
            case zongLin <= 0.2:
              grade = 'Ⅲ'
              break
            case zongLin <= 0.3:
              grade = 'Ⅳ'
              break
            case zongLin <= 0.4:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
          data.totalPGrade = grade
        }
        if (data.zongDan && data.zongDan != 'NaN') {
          let zongDan = parseFloat(data.zongDan)
          let grade
          switch (true) {
            case zongDan <= 0.15:
              grade = 'Ⅰ'
              break
            case zongDan <= 0.5:
              grade = 'Ⅱ'
              break
            case zongDan <= 1:
              grade = 'Ⅲ'
              break
            case zongDan <= 1.5:
              grade = 'Ⅳ'
              break
            case zongDan <= 2:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
          data.totalNGrade = grade
        }
        let gradelist = [
          data.ANGrade,
          data.CODGrade,
          data.DOGrade,
          data.permanganateGrade,
          data.totalPGrade,
          data.totalNGrade
        ]
        if (gradelist.includes('劣Ⅴ')) {
          data.synthesis = '劣Ⅴ'
        } else if (gradelist.includes('Ⅴ')) {
          data.synthesis = 'Ⅴ'
        } else if (gradelist.includes('Ⅳ')) {
          data.synthesis = 'Ⅳ'
        } else if (gradelist.includes('Ⅲ')) {
          data.synthesis = 'Ⅲ'
        } else if (gradelist.includes('Ⅱ')) {
          data.synthesis = 'Ⅱ'
        } else if (gradelist.includes('Ⅰ')) {
          data.synthesis = 'Ⅰ'
        } else {
          data.synthesis = null
        }

        return data
      }
    }
  }
</script>
<style lang="less" scoped></style>
