<template>
  <div>
    <LeftInfoPart v-if="show" />
    <LeftInfoPart v-if="show" />
    <LeftInfoSingle v-else :rl-info="choosenRlInfo" />

    <RightInfoPart v-if="show" ref="rightInfoPart" :obj-score="objScoreArr" :rl-data="rlComDataList" />
    <RightInfoSingle v-else :rl-info="choosenRlInfo" />

    <TotalScore v-if="show" :river-score="riverScore" :lake-score="lakeScore" />
    <SingleScore v-else :rl-info="choosenRlInfo" />

    <Legend v-if="show" />
    <ProSelector v-show="show" ref="proSelector" />

    <SingleTargetB v-if="showDetail" :data="targetB" />

    <MyMap :rl-data="rlComDataList" @changeShow="changeFlag" @setRlInfo="rlInfo" @mouseMoveChange="rlPointMove" />
  </div>
</template>
<script>
  import LeftInfoPart from './pagePart/leftInfoPart.vue'
  import RightInfoPart from './pagePart/rightInfoPart.vue'
  import LeftInfoSingle from './pagePart/leftInfoSingle.vue'
  import RightInfoSingle from './pagePart/rightInfoSingle.vue'
  import MyMap from './map/map.vue'
  import TotalScore from './pagePart/totalScore.vue'
  import SingleScore from './pagePart/singleScore.vue'
  import Legend from './pagePart/legend.vue'
  import ProSelector from './pagePart/proSelector.vue'
  import SingleTargetB from './pagePart/singleTargetB.vue'

  import { setToken } from '@/utils/auth'
  import { Scheme } from '@/api/page/index'

  export default {
    name: '',
    components: {
      LeftInfoPart,
      RightInfoPart,
      LeftInfoSingle,
      RightInfoSingle,
      MyMap,
      TotalScore,
      SingleScore,
      Legend,
      ProSelector,
      SingleTargetB
    },
    props: {},
    data() {
      return {
        // 展示全域信息和单一信息控制器
        show: true,
        showDetail: false,

        objScoreArr: [],
        // 对象信息列表(项目全部数据)
        rlComDataList: [],

        // 点击河湖对象信息
        choosenRlInfo: {},

        riverScore: 0, // 地区河评分
        lakeScore: 0, // 地区湖评分

        targetB: null, // 右下角对象分数信息内容

        isActive: false // 省级/市级指标切换按钮
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      // let token = '347825499f3428e742eb9694db0cf'
      // Project.getToken(token).then((res) => {
      //   setToken(res.data.data)
      //   this.loadData()
      // })
      this.loadData()
    },
    methods: {
      /** 请求获取图层信息 */
      /**
       * @description 请求项目数据（包含图层和评估得分数据）
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData() {
        let id = this.$refs.proSelector.proValue
        this.$refs.rightInfoPart.loadFile(id)

        if (id) {
          Scheme.getData(id).then((res) => {
            console.log('city', res.data.data)

            if (res.data.data) {
              this.rlComDataList = res.data.data
              this.setHealthstatusRanking()
              this.getAreaScore()
            }
          })
        }
      },
      /** 全域信息排名数据处理函数*/
      setHealthstatusRanking() {
        let arr = []
        this.rlComDataList.forEach((item) => {
          let param = {
            name: item.obj.name,
            score: item.target.scoreVal == 0 ? 0 : item.target.scoreVal.toFixed(1),
            evaluation: item.target.scoreDesc
          }
          arr.push(param)
        })
        this.objScoreArr = arr.sort(this.compare('score'))
      },
      /** 大小比较函数 */
      /**
       * @description 大小比较函数
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      compare(property) {
        return function (a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value2 - value1
        }
      },
      /**
       * @description  全域信息展示和单一对象信息展示标志位切换功能
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      changeFlag(val) {
        this.show = val
        if (!this.show) {
          this.showDetail = false
        }
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      rlInfo(data) {
        this.choosenRlInfo = data
      },
      /** 区域河湖分数计算 */
      getAreaScore() {
        let rlCount = 0
        let laCount = 0
        let rlTotle = 0
        let laTotle = 0

        this.rlComDataList.forEach((item) => {
          if (item.rlType == 1) {
            rlCount++
            if (item.target.scoreVal !== 0) {
              rlTotle += item.target.scoreVal
            }
          } else {
            laCount++
            if (item.target.scoreVal !== 0) {
              laTotle += item.target.scoreVal
            }
          }
        })
        if (rlCount != 0) {
          this.riverScore = (rlTotle / rlCount).toFixed(1)
        }
        if (laCount != 0) {
          this.lakeScore = (laTotle / laCount).toFixed(1)
        }
      },

      rlPointMove(data) {
        this.showDetail = true
        let chooseRl = data
        let i = this.rlComDataList.find((i) => {
          return i.id == chooseRl.id
        })
        if (i) {
          this.targetB = i
        }
      }
    }
  }
</script>
<style lang="less" scoped></style>
