<template>
  <div>
    <LeftInfoPart v-if="show" />
    <LeftInfoSingle v-else :rl-info="choosenRlInfo" />

    <RightInfoPart
      v-if="show"
      ref="rightInfoPart"
      :obj-score="objScoreArr"
      :rl-data="rlComDataList"
      :is-active="isActive"
    />
    <RightInfoSingle v-else :rl-info="choosenRlInfo" />

    <TotalScore
      v-if="show"
      :river-score="riverScore"
      :lake-score="lakeScore"
      :river-local-score="riverLocalScore"
      :lake-local-score="lakeLocalScore"
      :is-active="isActive"
    />
    <SingleScore v-else :rl-info="choosenRlInfo" :is-active="isActive" />

    <Legend v-if="show" />
    <ProSelector v-show="show" ref="proSelector" />

    <SingleTargetB v-if="showDetail" :data="targetB" :is-active="isActive" />

    <MyMap
      :rl-data="rlComDataList"
      :is-active="isActive"
      @changeShow="changeFlag"
      @setRlInfo="rlInfo"
      @mouseMoveChange="rlPointMove"
    />

    <!-- <div v-if="show" class="change-btn-box">
      <button class="change-btn" :class="{ 'change-btn-active': isActive }" @click="exchange(1)">省级指标</button>
      <button class="change-btn" :class="{ 'change-btn-active': !isActive }" @click="exchange(2)">市级指标</button>
    </div> -->
  </div>
</template>
<script>
  import LeftInfoPart from './pagePart/leftInfoPart.vue'
  import RightInfoPart from './pagePart/rightInfoPart.vue'
  import LeftInfoSingle from './pagePart/leftInfoSingle.vue'
  import RightInfoSingle from './pagePart/rightInfoSingle.vue'
  import MyMap from './map/map.vue'
  import TotalScore from './pagePart/totalScore.vue'
  import SingleScore from './pagePart/singleScore.vue'
  import Legend from './pagePart/legend.vue'
  import ProSelector from './pagePart/proSelector.vue'
  import SingleTargetB from './pagePart/singleTargetB.vue'

  import { setToken } from '@/utils/auth'
  import { ProvinceScheme } from '@/api/page/index'

  export default {
    name: '',
    components: {
      LeftInfoPart,
      RightInfoPart,
      LeftInfoSingle,
      RightInfoSingle,
      MyMap,
      TotalScore,
      SingleScore,
      Legend,
      ProSelector,
      SingleTargetB
    },
    props: {},
    data() {
      return {
        // 展示全域信息和单一信息控制器
        show: true,
        showDetail: false,

        objScoreArr: [],
        // 对象信息列表(项目全部数据)
        rlComDataList: [],

        // 点击河湖对象信息
        choosenRlInfo: {},

        riverScore: 0, // 地区河评分省级（基本项）
        lakeScore: 0, // 地区湖库评分省级（基本项）
        riverLocalScore: 0, // 地区河评分市级（附加项）
        lakeLocalScore: 0, // 地区湖评分市级（附加项）

        targetB: null, // 右下角对象分数信息内容

        isActive: true // 省级/市级指标切换按钮
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      // let token = '347825499f3428e742eb9694db0cf'
      // Project.getToken(token).then((res) => {
      //   setToken(res.data.data)
      //   this.loadData()
      // })
      this.loadData()
    },
    methods: {
      /** 请求获取图层信息 */
      /**
       * @description 请求项目数据（包含图层和评估得分数据）
       * @author hu_ty
       * @since
       * @param {*}
       * @param { boolean } flag true: 省标准指标数据和分数； false：江苏省标准指标数据和分数
       *
       */
      loadData() {
        let id = this.$refs.proSelector.proValue
        this.$refs.rightInfoPart.loadFile(id)

        if (id) {
          this.rlComDataList = []
          ProvinceScheme.getData(id, this.isActive).then((res) => {
            if (res.data.data) {
              this.rlComDataList = res.data.data
              this.setHealthstatusRanking()
              this.getAreaScore()
            }
          })
        }
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      exchange(type) {
        if (type === 1) {
          this.isActive = true
        } else {
          this.isActive = false
        }
        this.showDetail = false
        this.loadData()
      },
      /** 全域信息排名数据处理函数*/
      setHealthstatusRanking() {
        let arr = []
        this.rlComDataList.forEach((item) => {
          let param = {
            name: item.obj.name,
            score: item.target.scoreVal == 0 ? 0 : item.target.scoreVal.toFixed(1),
            evaluation: item.target.scoreDesc,
            baseScoreVal: item.target.baseScoreVal == 0 ? 0 : item.target.baseScoreVal.toFixed(1),
            localScoreVal: item.target.localScoreVal == 0 ? 0 : item.target.localScoreVal.toFixed(1),
            rlEvaluationDesc: item.rlEvaluationDesc
          }
          arr.push(param)
        })
        this.objScoreArr = arr.sort(this.compare('score'))
      },
      /** 大小比较函数 */
      /**
       * @description 大小比较函数
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      compare(property) {
        return function (a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value2 - value1
        }
      },
      /**
       * @description  全域信息展示和单一对象信息展示标志位切换功能
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      changeFlag(val) {
        this.show = val
        if (!this.show) {
          this.showDetail = false
        }
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      rlInfo(data) {
        this.choosenRlInfo = data
      },
      /** 区域河湖分数计算 */
      getAreaScore() {
        let rlCount = 0
        let laCount = 0
        let rlTotle = 0
        let laTotle = 0
        let rlLocalTotle = 0
        let laLocalTotle = 0
        if (!this.isActive) {
          this.rlComDataList.forEach((item) => {
            if (item.rlType.includes('HeDao') || item.rlType.includes('CunLuo') || item.rlType.includes('YeWai')) {
              rlCount++
              if (item.target.localScoreVal !== 0) {
                rlLocalTotle += item.target.localScoreVal
              }
              if (item.target.baseScoreVal !== 0) {
                rlTotle += item.target.baseScoreVal
              }
            } else {
              laCount++
              if (item.target.localScoreVal !== 0) {
                laLocalTotle += item.target.localScoreVal
              }
              if (item.target.baseScoreVal !== 0) {
                laTotle += item.target.baseScoreVal
              }
            }
          })
          if (rlCount != 0) {
            this.riverScore = (rlTotle / rlCount).toFixed(1)
            this.riverLocalScore = (rlLocalTotle / rlCount).toFixed(1)
          }
          if (laCount != 0) {
            this.lakeScore = (laTotle / laCount).toFixed(1)
            this.lakeLocalScore = (laLocalTotle / rlCount).toFixed(1)
          }
        } else {
          this.rlComDataList.forEach((item) => {
            if (item.rlType.includes('HeDao') || item.rlType.includes('CunLuo') || item.rlType.includes('YeWai')) {
              rlCount++
              if (item.target.baseScoreVal !== 0) {
                rlTotle += item.target.baseScoreVal
              }
            } else {
              laCount++
              if (item.target.baseScoreVal !== 0) {
                laTotle += item.target.baseScoreVal
              }
            }
          })
          if (rlCount != 0) {
            this.riverScore = (rlTotle / rlCount).toFixed(1)
          }
          if (laCount != 0) {
            this.lakeScore = (laTotle / laCount).toFixed(1)
          }
        }
      },

      rlPointMove(data) {
        this.showDetail = true
        let chooseRl = data
        let i = this.rlComDataList.find((i) => {
          return i.id == chooseRl.id
        })
        if (i) {
          this.targetB = i
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .change-btn-box {
    position: absolute;
    top: 104px;
    left: 50%;
    transform: translateX(-50%);

    .change-btn {
      // border: none;
      background-color: unset;
      border-radius: 4px;
      color: rgb(255, 255, 255);
      font-family: 'PingFang SC';
      font-size: 16px;
      width: 102px;
      height: 36px;
      border: 1px solid rgba(0, 220, 255, 0.4);
    }
    .change-btn-active {
      // border: none;
      background-color: unset;
      border-radius: 4px;
      border-color: 1px solid rgba(60, 212, 149, 0.5);
      color: rgb(60, 212, 149);
      background: radial-gradient(86.65% 26.8% at 50% 50%, rgba(60, 212, 149, 0) 0%, rgba(60, 212, 149, 0.24) 100%);
    }
  }
</style>
