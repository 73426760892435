// 管理(保护)范围划定率
<template>
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  export default {
    components: {
      Pie
    },
    props: {
      manageProtectionInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      let a, e
      if (this.manageProtectionInfo.totalNumber == undefined) {
        a = 0
        e = 0
      } else {
        a = this.manageProtectionInfo.totalNumber
        e = this.manageProtectionInfo.protectNumber
      }
      this.options = {
        title: {
          text: a ? (e / a).toFixed(2) * 100 + '%' : '--',
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: '25',
            color: '#FFFFFF',
            fontFamily: 'DINAlternate-Bold, DINAlternate',
            foontWeight: '600'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['85%', '95%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: 'rgb(60, 212, 149)' },
                      { offset: 1, color: 'rgba(60, 212, 149,0.3)' }
                    ])
                  ]
                ]
              }
            },
            data: [
              {
                value: e,
                name: '',
                itemStyle: {
                  normal: {
                    color: 'rgb(60, 212, 149)'
                  }
                },
                label: {
                  show: false
                }
              },
              {
                value: a - e,
                name: '',
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: 'rgba(60, 212, 149,0.1)'
                  }
                }
              }
            ]
          },

          {
            name: '',
            type: 'gauge',
            radius: '122%',
            center: ['50%', '50%'],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 50,
            hoverAnimation: true,
            axisTick: {
              show: false
            },
            splitLine: {
              length: 8,
              lineStyle: {
                width: 2,
                color: 'rgb(20, 31, 41)'
              }
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            detail: {
              show: false
            }
          }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
  }
</style>
