<template>
  <div id="centerLeft1">
    <BarCircle :options="options"></BarCircle>
  </div>
</template>

<script>
  import BarCircle from '@/components/echart/barCircle.vue'
  export default {
    name: 'Brid',
    components: {
      BarCircle
    },
    props: {
      interferenceData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        options: {}
      }
    },
    watch: {
      interferenceData: {
        immediate: true,
        handler(val) {
          console.log(val)
          this.loadData(val)
        },
        deep: true
      }
    },
    // created() {
    //   this.options = {
    //     tooltip: {
    //       trigger: 'item',
    //       formatter: '{b} : {c} ({d}%)'
    //     },
    //     series: [
    //       {
    //         type: 'pie',
    //         radius: [20, 50],
    //         center: ['50%', '50%'],
    //         roseType: 'area',
    //         itemStyle: {
    //           borderRadius: 1
    //         },
    //         label: {
    //           formatter: '{b|{b}}\n {per|{d}%}  ',
    //           rich: {
    //             b: {
    //               color: 'rgba(255, 255, 255, 0.7)',
    //               fontSize: 12,
    //               fontWeight: 'bold',
    //               lineHeight: 33
    //             },
    //             per: {
    //               color: '#fff',
    //               padding: [3, 4],
    //               borderRadius: 4
    //             }
    //           }
    //         },
    //         data: this.interferenceData
    //       }
    //     ]
    //   }
    // },
    methods: {
      loadData(val) {
        this.options = {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)'
          },
          series: [
            {
              type: 'pie',
              radius: [20, 70],
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 1
              },
              label: {
                formatter: '{b|{b}}\n {per|{d}%}  ',
                rich: {
                  b: {
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: 12,
                    fontWeight: 'bold',
                    lineHeight: 33
                  },
                  per: {
                    color: '#fff',
                    padding: [3, 4],
                    borderRadius: 4
                  }
                }
              },
              data: val
            }
          ]
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
    //   .dv-dec-1 {
    //     position: relative;
    //     width: 100px;
    //     height: 20px;
    //     top: -3px;
    //   }
    //   .chart-box {
    //     margin-top: 16px;
    //     width: 170px;
    //     height: 170px;
    //     .active-ring-name {
    //       padding-top: 10px;
    //     }
    //   }
  }
</style>
