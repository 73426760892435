// 苏州标准--湖泊对象管理水平维度指标数据展示页面
<template>
  <div class="page-body">
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            文化展示程度
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ whzscd.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(whzscd) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="whzscd.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            遗存保存程度
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ ycbccd.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(ycbccd) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="ycbccd.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            文化宣教情况
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ whxjqk.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(whxjqk) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="whxjqk.data" :my-height="'150'" />
          </div>
        </div>

        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="map-container">
      <div
        class="items-1"
        style="
          width: 100%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          overflow-y: auto;
          position: relative;
        "
      >
        <Map :rl-data="objInfo.obj" />
        <!-- <div class="pic-container">
          <el-carousel :interval="2000" type="card" height="250px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="420px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
      </div>
    </div>
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            特色主题公园
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ tsztgy.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(tsztgy) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="tsztgy.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            水文化旅游开发
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ swhlykf.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(swhlykf) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="swhlykf.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            景观舒适便利
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ jgssbl.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(jgssbl) !== '{}'" style="text-align: center">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="jgssbl.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Map from '../map/objMap.vue'

  export default {
    components: {
      Map
    },
    props: {
      riverRcwh: {
        type: Object,
        default: () => {}
      },
      objInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        targetDInfoData: [],

        whzscd: {}, // 文化展示程度
        ycbccd: {}, // 遗存保存程度

        whxjqk: {}, // 文化宣教情况
        jgssbl: {}, // 景观舒适便利
        swhlykf: {}, // 水文化旅游开发
        tsztgy: {} // 特色主题公园
      }
    },
    created() {
      this.childData(this.riverRcwh)
      this.targetDInfoData.forEach((item) => {
        if (item.code == 'C26') {
          // 文化展示程度
          this.whzscd.score = item.scoreVal.toFixed(1)
          this.whzscd.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.whzscd.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C27') {
          // 遗存保存程度
          this.ycbccd.score = item.scoreVal.toFixed(1)
          this.ycbccd.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.ycbccd.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C28') {
          //   文化宣教情况
          this.whxjqk.score = item.scoreVal.toFixed(1)
          this.whxjqk.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.whxjqk.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C29') {
          // 景观舒适便利
          this.jgssbl.score = item.scoreVal.toFixed(1)
          this.jgssbl.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.jgssbl.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C30') {
          // 特色主题公园
          this.tsztgy.score = item.scoreVal.toFixed(1)
          this.tsztgy.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.tsztgy.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C31') {
          //   水文化旅游开发
          this.swhlykf.score = item.scoreVal.toFixed(1)
          this.swhlykf.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.swhlykf.data.push(param)
                })
              }
            })
          }
        }
      })
    },
    methods: {
      /** 遍历D层指标数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              this.targetDInfoData.push(item)
            }
          })
        } else {
          this.targetDInfoData.push(info)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .page-body {
    height: 60vh;
    //   max-height: 90vh;
    overflow: hidden;
    display: flex;
    .target-chart {
      width: 24%;
      display: grid;
      grid-template-rows: repeat(3, 33.3%);
    }
    .map-container {
      width: 52%;
      display: grid;
      //   grid-template-columns: repeat(2, 50%);
      //   grid-template-rows: repeat(3, 33.3%);
      //   .items-1 {
      //     grid-column-start: 1;
      //     grid-column-end: 4;
      //     grid-row-start: 1;
      //     grid-row-end: 3;
      //   }
    }
  }
  .target-title {
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.9);
    .target-title-icon {
      width: 10px;
      height: 10px;
      background-color: rgba(60, 212, 149, 0.9);
      border-radius: 50%;
      // <!--设置圆点与其后面的内容水平显示-->
      display: inline-block;
      margin-right: 5px;
    }
    .target-title-name {
      font-size: 1.7vh;
      margin-left: 20px;
    }
    .target-title-score {
      vertical-align: middle;
      margin-right: 20px;
      span {
        color: rgb(60, 212, 149);
        font-size: 20px;
      }
    }
  }
  .target-chart-lable {
    .target-chart-lable-p1 {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.4vh;
      span {
        color: rgb(60, 212, 149);
        font-size: 2.8vh;
      }
    }
    .target-chart-lable-p1 {
      color: rgb(255, 255, 255);
      font-size: 1.5vh;
    }
  }

  .hyaq {
    width: 80px;
    height: 90px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background: linear-gradient(90.3deg, rgba(60, 212, 149, 0.1) 0.19%, rgba(60, 212, 149, 0) 97.57%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
</style>
