// 苏州标准--湖泊对象管理水平维度指标数据展示页面
<template>
  <div class="page-body">
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            防洪工程达标
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ fhdbl.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(fhdbl) !== '{}'" style="display: flex">
          <div style="height: 100px; width: 150px; margin-left: 10px; padding-top: 20px">
            <ShorelineDevelopment :shoreline-development-info="fhdbl.data" />
          </div>
          <div style="height: 100px">
            <div style="margin-top: 50px">
              <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                <span style="color: rgb(60, 212, 149); font-size: 1.8vh">{{ fhdbl.data.occupyLen }}</span
                >&nbsp;m
              </p>
              <p style="color: rgb(255, 255, 255); font-size: 1.5vh">达标堤防长度</p>
            </div>
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            原水水质达标
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ ysszdb.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(ysszdb) !== '{}'" style="display: flex">
          <div style="height: 100px; width: 150px; margin-left: 10px; padding-top: 20px">
            <WaterQualityStandard :water-quality-standard-info="ysszdb.data" />
          </div>
          <div style="height: 100px">
            <div style="margin-top: 30px">
              <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                <span style="color: rgb(60, 212, 149); font-size: 2vh">{{ ysszdb.data.standardMeasurement }}</span
                >万m<sup>3</sup>
              </p>
              <p style="color: rgb(255, 255, 255); font-size: 1.5vh">达标取水量</p>
            </div>
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            岸坡稳定牢固
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ apwdlg.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(apwdlg) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="apwdlg.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="map-container">
      <div
        class="items-1"
        style="
          width: 100%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          overflow-y: auto;
          position: relative;
        "
      >
        <Map :rl-data="objInfo.obj" />
        <!-- <div class="pic-container">
          <el-carousel :interval="2000" type="card" height="250px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="420px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
      </div>
      <div>
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            配套工程运行
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ ptgcyx.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(ptgcyx) !== '{}'" style="display: flex; justify-content: space-around">
          <div
            style="margin-top: 30px"
            class="hyaq"
            :class="{ isGood: ptgcyx.standardFlag === '不达标' ? true : false }"
          >
            <div style="color: rgb(255, 255, 255); font-size: 14px">水工建筑设计</div>

            <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              <span style="color: rgb(60, 212, 149); font-size: 20px">{{ ptgcyx.standardFlag }}</span>
            </div>
          </div>
          <div
            style="margin-top: 30px"
            class="hyaq"
            :class="{ isGood: ptgcyx.runStatusFlag === '不良' ? true : false }"
          >
            <div style="color: rgb(255, 255, 255); font-size: 14px">配套工程运行</div>

            <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              <span style="color: rgb(60, 212, 149); font-size: 20px">{{ ptgcyx.runStatusFlag }}</span>
            </div>
          </div>
          <div
            style="margin-top: 30px"
            class="hyaq"
            :class="{ isGood: ptgcyx.completeTasksFlag === '未完成' ? true : false }"
          >
            <div style="color: rgb(255, 255, 255); font-size: 14px">调度任务</div>

            <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              <span style="color: rgb(60, 212, 149); font-size: 20px">{{ ptgcyx.completeTasksFlag }}</span>
            </div>
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            调蓄能力状况
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ txnlzk.score }}</span>
          </div>
        </div>
        <div
          v-if="JSON.stringify(txnlzk) !== '{}'"
          style="height: 60%; display: flex; flex-direction: column; align-items: center; justify-content: center"
        >
          <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
            设计调蓄水能力
            <span style="color: rgb(60, 212, 149); font-size: 24px">{{ txnlzk.designPower }}</span
            >m<sup>3</sup>
          </div>
          <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
            现状调蓄水能力
            <span style="color: rgb(60, 212, 149); font-size: 24px">{{ txnlzk.realPower }}</span
            >m<sup>3</sup>
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            管护通道畅通
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ ghtdct.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(ghtdct) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="ghtdct.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            警示标识完备
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ jbbswb.score }}</span>
          </div>
        </div>

        <div v-if="JSON.stringify(jbbswb) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="jbbswb.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ShorelineDevelopment from './pageChart/shorelineDevelopment.vue'
  import WaterQualityStandard from './pageChart/WaterQualityStandard.vue'

  import Map from '../map/objMap.vue'

  export default {
    components: {
      Map,

      ShorelineDevelopment,
      WaterQualityStandard
    },
    props: {
      lakeHahy: {
        type: Object,
        default: () => {}
      },
      objInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        targetDInfoData: [],

        fhdbl: {}, // 防洪工程达标
        ysszdb: {}, // 原水水质达标
        txnlzk: {}, // 调蓄能力状况
        ghtdct: {}, // 管护通道畅通
        jbbswb: {}, // 警示标识完备
        apwdlg: {}, // 岸坡稳定牢固
        ptgcyx: {} // 配套工程运行
      }
    },
    created() {
      this.childData(this.lakeHahy)
      console.log(this.targetDInfoData)
      this.targetDInfoData.forEach((item) => {
        // 防洪工程达标
        if (item.code == 'C1') {
          let param = {
            score: item.scoreVal.toFixed(1),
            data: {
              occupyLen: item.targetEntity.standardLength, // 达标长度
              total: item.targetEntity.totalLength // 总长度
            }
          }
          this.fhdbl = param
        } else if (item.code == 'C2') {
          // 原水水质达标
          let param = {
            score: item.scoreVal.toFixed(1),
            data: {
              total: item.targetEntity.totalWater,
              standardMeasurement: item.targetEntity.standardWater
            }
          }
          this.ysszdb = param
        } else if (item.code == 'C3') {
          // 岸坡稳定牢固
          this.apwdlg.score = item.scoreVal.toFixed(1)
          this.apwdlg.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.chongShuaPngs && del.chongShuaPngs != '') {
                let arr = del.chongShuaPngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar
                    // desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.apwdlg.data.push(param)
                })
              }
              if (del.shenLouPngs && del.shenLouPngs != '') {
                let arr = del.shenLouPngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar
                    // desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.apwdlg.data.push(param)
                })
              }
              if (del.tanTaPngs && del.tanTaPngs != '') {
                let arr = del.tanTaPngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar
                    // desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.apwdlg.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C5') {
          // 配套工程运行
          let param = {
            score: item.scoreVal.toFixed(1),
            completeTasksFlag: item.targetEntity.completeTasksFlag ? '完成' : '未完成',
            runStatusFlag: item.targetEntity.runStatusFlag ? '良好' : '不良',
            standardFlag: item.targetEntity.standardFlag ? '达标' : '不达标'
          }
          this.ptgcyx = param
        } else if (item.code == 'C4') {
          //  调蓄能力状况
          //   todo
          this.txnlzk.score = item.scoreVal.toFixed(1)
          this.txnlzk.realPower = item.targetEntity.realPower
          this.txnlzk.designPower = item.targetEntity.designPower
        } else if (item.code == 'C6') {
          //管护通道畅通
          let arrList = []
          this.ghtdct.score = item.scoreVal.toFixed(1)
          this.ghtdct.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.ghtdct.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C7') {
          //警示标识完备
          let arrList = []
          this.jbbswb.score = item.scoreVal.toFixed(1)
          this.jbbswb.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.jbbswb.data.push(param)
                })
              }
            })
          }
        }
      })
    },
    methods: {
      /** 遍历D层指标数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              this.targetDInfoData.push(item)
            }
          })
        } else {
          this.targetDInfoData.push(info)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .page-body {
    height: 60vh;
    //   max-height: 90vh;
    overflow: hidden;
    display: flex;
    .target-chart {
      width: 24%;
      display: grid;
      grid-template-rows: repeat(3, 33.3%);
    }
    .map-container {
      padding: 0 8px;
      width: 52%;
      display: grid;
      grid-template-columns: repeat(1, 100%);
      grid-template-rows: repeat(3, 33.3%);
      .items-1 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }
  .target-title {
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.9);
    .target-title-icon {
      width: 10px;
      height: 10px;
      background-color: rgba(60, 212, 149, 0.9);
      border-radius: 50%;
      // <!--设置圆点与其后面的内容水平显示-->
      display: inline-block;
      margin-right: 5px;
    }
    .target-title-name {
      font-size: 1.7vh;
      margin-left: 20px;
    }
    .target-title-score {
      vertical-align: middle;
      margin-right: 20px;
      span {
        color: rgb(60, 212, 149);
        font-size: 20px;
      }
    }
  }
  .target-chart-lable {
    .target-chart-lable-p1 {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.4vh;
      span {
        color: rgb(60, 212, 149);
        font-size: 2.8vh;
      }
    }
    .target-chart-lable-p1 {
      color: rgb(255, 255, 255);
      font-size: 1.5vh;
    }
  }

  .hyaq {
    width: 120px;
    height: 90px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background: linear-gradient(90.3deg, rgba(60, 212, 149, 0.1) 0.19%, rgba(60, 212, 149, 0) 97.57%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
  .isGood {
    background: linear-gradient(90.3deg, rgba(245, 63, 63, 0.1) 0.19%, rgba(245, 63, 63, 0) 97.57%);
    span {
      color: rgba(245, 63, 63, 1) !important;
    }
  }
</style>
