<template>
  <div id="boxRight">
    <!-- 地区整体信息右侧信息列表 -->
    <div class="area-right-top">
      <MyTitle title="健康状态统计" />
      <div class="health-status">
        <AreaLeftCenter :health-statistics="healthStatistics" />
      </div>
    </div>
    <div class="area-right-center">
      <MyTitle title="健康状况排名" />
      <div class="ranking">
        <dv-scroll-board class="dv-scr-board" :config="paimingshuju" />
      </div>
    </div>
    <div class="area-right-bottom">
      <MyTitle title="评估成果" />
      <el-input v-model="searchFileName" class="search-input"></el-input>
      <div class="file-box">
        <template v-for="(item, index) in reportFileList">
          <div v-if="item.name.includes(searchFileName)" :key="index" class="assess" @click="reportView(item.name)">
            <div class="assess-pic">
              <img src="../../../assets/icon/icon_word-green.png" alt />
              {{ item.name }}
            </div>
            <div class="assess-text-del">
              <div class="delImg">详情</div>
            </div>
          </div>
        </template>
        <!-- <div class="assess" @click="reportView(3)">
          <div class="assess-pic">
            <img src="../../../assets/icon/icon_word-green.png" alt />
            2021年度吴江区河湖健康技术评价报告
          </div>
          <div class="assess-text-del">
            <div class="delImg">详情</div>
          </div>
        </div>
        <div class="assess" @click="reportView(2)">
          <div class="assess-pic">
            <img src="../../../assets/icon/icon_word-green.png" alt />
            2021年度吴江区河湖健康评价中期报告
          </div>
          <div class="assess-text-del">
            <div class="delImg">详情</div>
          </div>
        </div>
        <div class="assess" @click="reportView(1)">
          <div class="assess-pic">
            <img src="../../../assets/icon/icon_word-green.png" alt />
            2020吴江区河流健康技术报告
          </div>
          <div class="assess-text-del">
            <div class="delImg">详情</div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 地区信息右侧信息列表 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="70vw"
      :title="delTitle"
      top="10vh"
      append-to-body
    >
      <div class="agreement_picture" style="height: 70vh; overflow-y: auto">
        <!-- <div class="pdf"> -->
        <div v-if="!isFileList" style="width: 70vw; height: 100%">
          <iframe v-if="isFile" :src="delSrc" frameborder="0" style="width: 70vw; height: 100%"></iframe>
          <!-- </div> -->
          <div v-if="!isFile" style="width: 50%; margin: 0 auto">
            <img :src="delSrc" style="width: 100%; margin: 0 auto" />
          </div>
        </div>
        <div v-if="isFileList" style="width: 100%; height: 100%">
          <div class="target-all-info" style="display: flex">
            <div class="target-list" style="overflow-y: auto">
              <ul type="none">
                <li v-for="(item, index) in fileList" :key="index">
                  <el-button :id="'button111' + index" type="text" class="score-target" @click="getFile(item)">
                    <p class="target-name">{{ item.name }}</p>
                  </el-button>
                </li>
              </ul>
            </div>
            <div class="target-content" style="width: 70%; height: 100%">
              <iframe :src="fileSrcGB" frameborder="0" style="width: 100%; height: 100%"></iframe>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import AreaLeftCenter from '../mapChart/areaLeftCenter.vue'
  import { ReportApi } from '@/api/report/index'

  export default {
    name: '',
    components: {
      AreaLeftCenter
    },
    props: {
      objScore: {
        type: Array,
        default: () => []
      },
      rlData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        healthStatistics: [
          {
            type: '非常健康',
            num: 0
          },
          {
            type: '健康',
            num: 0
          },
          {
            type: '亚健康',
            num: 0
          },

          {
            type: '不健康',
            num: 0
          },
          {
            type: '病态',
            num: 0
          }
        ],
        paimingshuju: {},
        dialogVisible: false, // 地区评估方法弹窗控制器
        isFileList: false,
        isFile: false,
        delTitle: '', // 地区评估方法弹窗标题
        delSrc: '', // 地区评估方法地址 src
        reportFileList: [],
        searchFileName: ''
      }
    },
    computed: {},
    watch: {
      objScore: {
        immediate: true,
        handler(val) {
          if (val.length > 0) {
            this.loadscrollBoard(val)
          }
        }
      },
      rlData: {
        immediate: true,
        handler(val) {
          if (val.length > 0) {
            this.loadAreaScore(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 项目分数排序列表数据处理
       * @author hu_ty
       * @since
       * @param {array}  val 项目分数数据列表
       *
       */
      loadscrollBoard(val) {
        // let objNum3 = ''
        let data = []

        val.forEach((item) => {
          let singleObject = []
          let score = ''
          singleObject.push(item.name)
          if (item.score >= 90) {
            score = "<span  class='colorOver90'>" + item.score + '</span>'
            // objNum3 += item.name + '、'
          } else if (item.score >= 80) {
            score = "<span  class='colorOver80'>" + item.score + '</span>'
          } else if (item.score >= 60) {
            score = "<span  class='colorOver60'>" + item.score + '</span>'
          } else if (item.score >= 40) {
            score = "<span  class='colorOver40'>" + item.score + '</span>'
          } else {
            score = "<span  class='colorOther'>" + item.score + '</span>'
          }
          singleObject.push(score)
          singleObject.push(item.evaluation)
          data.push(singleObject)
        })
        this.paimingshuju = {
          header: ['名称', '健康分值', '健康状态'],
          data: data,
          rowNum: 8, //表格行数
          headerHeight: 35,
          headerBGC: 'rgba(51, 194, 255, 0.1)', //表头
          oddRowBGC: 'rgba(28, 31, 34, 0.1)', //奇数行
          evenRowBGC: 'rgba(28, 31, 34, 0.1)', //偶数行
          index: true,
          columnWidth: [60],
          // align: ["center"],
          indexHeader: '排名'
          // carousel: 'page'
        }
      },
      /**
       * @description 区域河湖分数计算
       * @author hu_ty
       * @since
       * @param {array}  val 项目分数数据列表
       *
       */
      loadAreaScore(val) {
        let a = 0,
          b = 0,
          c = 0,
          d = 0,
          e = 0
        val.forEach((item) => {
          if (item.target.scoreDesc == '非常健康') {
            a++
          } else if (item.target.scoreDesc == '健康') {
            b++
          } else if (item.target.scoreDesc == '亚健康') {
            c++
          } else if (item.target.scoreDesc == '不健康') {
            d++
          } else {
            e++
          }
        })
        this.healthStatistics = [
          {
            type: '非常健康',
            num: a
          },
          {
            type: '健康',
            num: b
          },
          {
            type: '亚健康',
            num: c
          },

          {
            type: '不健康',
            num: d
          },
          {
            type: '病态',
            num: e
          }
        ]
      },

      /**
       * @description
       * @param {*}
       *
       */
      async loadFile(id) {
        if (typeof id === 'string') {
          let promise = []
          this.reportFileList = []
          // id 为字符串代表着访问的是全部数据（市级领导）
          let ids = id.split(',')
          ids.forEach((id) => {
            let param = {
              proId: id
            }
            let a = ReportApi.report_list(param).then((res) => {
              if (res.data.data) {
                // console.log('<== id ==>', id, res.data.data, res.data.data.list.reverse())
                res.data.data.list.reverse().forEach((file) => {
                  let fileInfo = {
                    name: file.customName || file.uploadFile.name,
                    url: file.completeUrl
                  }
                  this.reportFileList.push(fileInfo)
                })
              }
            })
            promise.push(a)
          })

          await Promise.all(promise)
        }
        if (typeof id === 'number') {
          let param = {
            proId: id
          }
          ReportApi.report_list(param).then((res) => {
            if (res.data.data) {
              // console.log('<== id ==>', id, res.data.data)
              this.reportFileList = []
              res.data.data.list.reverse().forEach((file) => {
                let fileInfo = {
                  name: file.customName || file.uploadFile.name,
                  url: file.completeUrl
                }
                this.reportFileList.push(fileInfo)
              })
            }
          })
        }
      },
      /**
       * @description 报告查看
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      reportView(info) {
        this.isFileList = false
        this.delTitle = '评估报告'
        let report = this.reportFileList.find((i) => {
          return i.name === info
        })
        this.delSrc = report.url
        this.isFile = true
        this.dialogVisible = true
        // this.isFileList = false
        // if (info === 1) {
        //   this.delTitle = '评估报告'
        //   this.delSrc =
        //     'http://hhhealthy.xiejikeji.com/2020%E5%90%B4%E6%B1%9F%E5%8C%BA%E6%B2%B3%E6%B5%81%E5%81%A5%E5%BA%B7%E6%8A%80%E6%9C%AF%E6%8A%A5%E5%91%8A.pdf'
        //   this.isFile = true
        // } else if (info === 2) {
        //   this.delTitle = '评估报告'
        //   this.delSrc =
        //     'http://hhhealthy.xiejikeji.com/2021%E5%B9%B4%E5%BA%A6%E5%90%B4%E6%B1%9F%E5%8C%BA%E6%B2%B3%E6%B9%96%E5%81%A5%E5%BA%B7%E8%AF%84%E4%BB%B7%E4%B8%AD%E6%9C%9F%E6%8A%A5%E5%91%8A(1).pdf'
        //   this.isFile = true
        // } else if (info === 3) {
        //   this.delTitle = '评估报告'
        //   this.isFile = true

        //   this.delSrc =
        //     'http://hhhealthy.xiejikeji.com/2021%E5%B9%B4%E5%BA%A6%E8%8B%8F%E5%B7%9E%E5%B8%82%E5%90%B4%E6%B1%9F%E5%8C%BA%E6%B2%B3%E6%B9%96%E5%81%A5%E5%BA%B7%E6%8A%80%E6%9C%AF%E8%AF%84%E4%BB%B7%E6%8A%A5%E5%91%8A.pdf'
        // }
        // this.dialogVisible = true
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '../../../assets/css/elui.less';
  #boxRight {
    position: absolute;
    width: 20.8vw;
    right: 24px;
    top: 100px;
    box-sizing: border-box;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    transition-property: right;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    z-index: 100;
  }
  // 区域右侧top健康状况统计
  .area-right-top {
    width: 19.2vw;
    // height: 22.1vh;
    margin: 1.5vh 0.8vw 1.1vh 0.8vw;
    position: relative;
    // text-align: right;
    .health-status {
      width: 19.2vw;
      height: 14.1vh;
      //   position: absolute;
      //   top: 6vh;
    }
  }
  .area-right-center {
    width: 19.2vw;
    // height: 39vh;
    margin: 0 0.8vw 1.1vh 0.8vw;
    position: relative;
    .ranking {
      position: relative;
      margin: 10px 0;
      font-size: 1.5vh;
      color: rgba(60, 212, 149, 1);
      .dv-scr-board {
        width: 100%;
        height: 34vh;
      }
      /deep/ .header {
        height: 44px !important;
        line-height: 44px !important;
        color: rgba(60, 212, 149, 1);
        font-family: 'PingFang SC';
        font-size: 1.5vh;
        font-weight: 500;
        .header-item {
          height: 44px !important;
          line-height: 44px !important;
          text-align: center;
        }
      }
    }
  }
  .area-right-bottom {
    width: 19.2vw;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;
    .file-box {
      height: 22vh;
      width: 100%;
      overflow-y: auto;
    }
    .assess {
      width: 100%;
      overflow: auto;
      height: 3.7vh;
      line-height: 3.7vh;
      margin-top: 1.1vh;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      .assess-pic {
        height: 3.7vh;
        line-height: 3.7vh;
        font-size: 1.5vh;
        color: rgba(216, 251, 255, 0.9);

        img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          height: 2.5vh;
        }
      }
      .assess-text-del {
        height: 3.7vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3vh;
        color: rgba(255, 255, 255, 0.5);
        .delImg {
          width: 2.3vw;
          background-image: url(../../../assets/icon/rightIcon-bg.png);
          background-position: right center;
          background-repeat: no-repeat;
          background-size: 38%;
        }
      }
    }
  }

  /deep/ .dv-scroll-board {
    text-align: center;
    color: rgba(255, 255, 255, 0.9);

    font-size: 1.5vh;
  }
  /deep/ .dv-scroll-board .rows .ceil {
    font-size: 1.5vh;
    text-align: center !important;
    height: 44px !important;
    line-height: 44px !important;
  }
  /deep/ .dv-scroll-board .rows .row-item {
    height: 44px !important;
  }
  /deep/ .colorOver90 {
    color: rgba(26, 177, 214, 1);
    text-align: center;

    font-weight: 500;
    font-size: 1.7vh;
  }
  /deep/ .colorOver80 {
    color: rgba(124, 234, 189, 1);
    text-align: center;

    font-weight: 500;
    font-size: 1.7vh;
  }
  /deep/ .colorOver60 {
    color: rgba(251, 252, 84, 1);
    text-align: center;

    font-weight: 500;
    font-size: 1.7vh;
  }
  /deep/ .colorOver40 {
    color: rgba(239, 138, 52, 1);
    text-align: center;

    font-weight: 500;
    font-size: 1.7vh;
  }
  /deep/ .colorOther {
    color: rgba(213, 56, 38, 1);
    text-align: center;

    font-weight: 500;
    font-size: 1.7vh;
  }
  .search-input {
    width: 100px;
    position: absolute;
    top: 0;
    right: 50px;
  }
  /deep/ .el-input__inner {
    border: 1px solid rgba(109, 165, 184, 0.1);
    box-sizing: border-box;
    background: inherit;
    height: 36px;
    line-height: 36px;
    color: #eee;
  }
</style>
