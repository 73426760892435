// 苏州标准--河流对象管理水平维度指标数据展示页面
<template>
  <div class="page-body">
    <div class="target-chart">
      <div style="width: 100%" :class="{ isActive: showTarget == 'D18' ? true : false }">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            管理(保护)范围划定率
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ manageProtection.score }}</span>
          </div>
        </div>
        <div style="text-align: center">
          <div style="height: 150px; width: 150px; margin: 20px auto 0">
            <ManagementProtectionScope :manage-protection-info="manageProtection.data" />
          </div>
          <div class="target-chart-lable">
            <p class="target-chart-lable-p1">
              <span>{{ manageProtection.data.protectNumber }} </span>m
            </p>
            <p style="color: rgb(255, 255, 255); font-size: 1.5vh">已划定长度</p>
          </div>
        </div>
      </div>
      <div style="width: 100%" :class="{ isActive: showTarget == 'D19' ? true : false }">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            岸坡环境整治程度
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ bankSlopeScore }}</span>
          </div>
        </div>
        <div style="text-align: center">
          <div style="height: 200px; width: 300px; margin: 10px auto 0">
            <el-carousel :interval="4000" indicator-position="outside" arrow="always" height="200px">
              <el-carousel-item v-for="(item, index) in bankSlopeUrlList" :key="index">
                <!-- <img :src="item" alt width="80%" height="auto" class="img-screen" /> -->
                <el-image :src="item" :preview-src-list="bankSlopeUrlList" width="80%" height="auto"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 52%; display: table-cell; text-align: center; vertical-align: middle; position: relative">
      <Map :rl-data="objInfo.obj" />
      <div class="pic-container">
        <!-- <el-carousel :interval="2000" type="card" height="180px" @change="changeTarget">
          <el-carousel-item v-for="(item, index) in picList" :key="index">
            <img :src="item.url" alt="" width="300px" style="aspect-ratio: 16/9" />
          </el-carousel-item>
        </el-carousel> -->
        <MySwiper :url-list="picList" />
      </div>
    </div>
    <div class="target-chart">
      <div style="width: 100%" :class="{ isActive: showTarget == 'D21' ? true : false }">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            生态岸坡比例
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ lakeEcologicalBankSlope.score }}</span>
          </div>
        </div>
        <div style="text-align: center">
          <div style="height: 200px; width: 300px; margin: 10px auto 0">
            <EcologicalBankSlope :lake-ecological-bank-slope-info="lakeEcologicalBankSlope.data" />
          </div>
          <div style="margin-top: -55px">
            <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ lakeEcologicalBankSlope.data.len }}</span
              >m
            </p>
            <p style="color: rgb(255, 255, 255); font-size: 1.5vh">生态岸线长度</p>
          </div>
        </div>
      </div>
      <div style="width: 100%" :class="{ isActive: showTarget == 'D20' ? true : false }">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            水体整洁程度
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ waterBodyScore }}</span>
          </div>
        </div>
        <div>
          <div style="height: 200px; width: 300px; margin: 10px auto 0">
            <el-carousel :interval="4000" indicator-position="outside" arrow="always" height="200px">
              <el-carousel-item v-for="(item, index) in waterBodyUrlList" :key="index">
                <!-- <img :src="item" alt width="80%" height="auto" class="img-screen" /> -->
                <el-image :src="item" :preview-src-list="waterBodyUrlList" width="80%" height="auto"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ManagementProtectionScope from './pageChart/managementProtectionScope.vue'
  import EcologicalBankSlope from './pageChart/ecologicalBankSlope.vue'
  import Map from '../map/objMap.vue'

  export default {
    components: {
      ManagementProtectionScope,
      EcologicalBankSlope,
      Map
    },
    props: {
      riverManagement: {
        type: Object,
        default: () => {}
      },
      waterPic: {
        type: String,
        default: null
      },
      objInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        targetDInfoData: [],

        lakeEcologicalBankSlope: {}, // 生态岸坡比
        manageProtection: {}, //管理保护范围
        bankSlopeUrlList: [], // 图片列表
        waterBodyUrlList: [], //水体整洁程度图片列表
        bankSlopeScore: null,
        waterBodyScore: null,
        waterSystemUrl: this.waterPic,
        showTarget: '',

        picList: []
      }
    },
    created() {
      this.childData(this.riverManagement)
      console.log(this.targetDInfoData)
      this.picList = []
      this.targetDInfoData.forEach((item) => {
        if (item.targetEntity.queryExt) {
          for (let key in item.targetEntity.queryExt) {
            if (key.length == 32) {
              this.picList.push(item.targetEntity.queryExt[key])
            }
          }
        }
        if (item.code == 'D18') {
          let param = {
            score: item.scoreVal.toFixed(1),
            data: {
              protectNumber: item.targetEntity?.protectNumber,
              totalNumber: item.targetEntity?.totalNumber
            }
          }
          this.manageProtection = param
        } else if (item.code == 'D19') {
          this.bankSlopeScore = item.scoreVal.toFixed(1)
          this.bankSlopeUrlList = item.targetEntity?.queryExt?.pngUrl
        } else if (item.code == 'D20') {
          this.waterBodyScore = item.scoreVal.toFixed(1)
          this.waterBodyUrlList = item.targetEntity?.queryExt?.pngUrl
        } else if (item.code == 'D21') {
          let param = {
            score: item.scoreVal.toFixed(1),
            data: {
              totalLength: item.targetEntity.totalLength,
              len: (item.targetEntity.totalLength * item.targetEntity.funVal) / 100
            }
          }
          this.lakeEcologicalBankSlope = param
        }
      })
    },
    methods: {
      /** 遍历D层指标数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              this.targetDInfoData.push(item)
            }
          })
        } else {
          this.targetDInfoData.push(info)
        }
      },
      changeTarget(val) {
        this.showTarget = this.picList[val].code
      }
    }
  }
</script>
<style lang="less" scoped>
  .page-body {
    height: 60vh;
    //   max-height: 90vh;
    overflow: hidden;
    display: flex;
    .target-chart {
      width: 24%;
      display: grid;
    }
  }
  .target-title {
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.9);
    .target-title-icon {
      width: 10px;
      height: 10px;
      background-color: rgba(60, 212, 149, 0.9);
      border-radius: 50%;
      // <!--设置圆点与其后面的内容水平显示-->
      display: inline-block;
      margin-right: 5px;
    }
    .target-title-name {
      font-size: 1.7vh;
      margin-left: 20px;
    }
    .target-title-score {
      vertical-align: middle;
      margin-right: 20px;
      span {
        color: rgb(60, 212, 149);
        font-size: 20px;
      }
    }
  }
  .target-chart-lable {
    .target-chart-lable-p1 {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.4vh;
      span {
        color: rgb(60, 212, 149);
        font-size: 2.8vh;
      }
    }
    .target-chart-lable-p1 {
      color: rgb(255, 255, 255);
      font-size: 1.5vh;
    }
  }
  /*滚动条bai整体*/
  ::-webkit-scrollbar {
    width: 1px; /*滚动条宽度*/
  }
  .img-screen {
    margin: auto 0;
    transform: translate(-50%, -50%);
  }
  .isActive {
    background-color: rgba(60, 212, 149, 0.1);
    margin: 2px 0;
  }

  .pic-container {
    position: absolute;
    bottom: 2px;
    left: 5%;
    width: 90%;
    margin: 0 auto;
  }
</style>
