<template>
  <div id="boxRight">
    <div class="object-right-top">
      <div @click="getALLdesc(1)">
        <MyTitle title="鱼翔浅底" :score="B1Score" />
      </div>

      <div id="txChart1A">
        <dv-scroll-ranking-board class="dv-scr-rank" :config="evaluatingRankingB1" />
      </div>
    </div>
    <div class="object-right-centerBar">
      <div @click="getALLdesc(2)">
        <MyTitle title="文昌人和" :score="B4Score" />
      </div>
      <div class="zxtjChartA">
        <BarCircleChart :interference-data="interferenceData" />
      </div>
    </div>
    <div class="object-right-bottom">
      <div @click="getALLdesc(3)">
        <MyTitle title="长治久安" :score="B5Score" />
      </div>
      <div class="zxtjChartA">
        <PieBingTuChart :funnel-data="funnelData" />
      </div>
    </div>

    <div class="object-left-center">
      <MyTitle title="公众满意度统计" :score="B6Score" />
      <div class="public">
        <div class="totalScore">总分：{{ publicSatisfactionScore }}分</div>
        <BarChart :public-satisfaction="publicSatisfaction" />
      </div>
    </div>

    <!-- 河流 -->
    <el-dialog
      append-to-body
      :visible.sync="riverYXQDFlag"
      custom-class="test-dialog"
      title="鱼翔浅底"
      width="60%"
      top="100px"
    >
      <page-riveryxqd v-if="riverYXQDFlag" :river-yxqd="riverYXQD" :obj-info="objInfo" />
    </el-dialog>

    <el-dialog
      append-to-body
      :visible.sync="riverRCWHFlag"
      custom-class="test-dialog"
      title="人昌文和"
      width="60%"
      top="100px"
    >
      <page-riverrcwh v-if="riverRCWHFlag" :river-rcwh="riverRCWH" :obj-info="objInfo" />
    </el-dialog>

    <el-dialog
      append-to-body
      :visible.sync="riverCZJAFlag"
      custom-class="test-dialog"
      title="长治久安"
      width="60%"
      top="100px"
    >
      <page-riverczja v-if="riverCZJAFlag" :river-czja="riverCZJA" :obj-info="objInfo" />
    </el-dialog>

    <!-- 湖库 -->
    <el-dialog
      append-to-body
      :visible.sync="lakeYXQDFlag"
      custom-class="test-dialog"
      title="鱼翔浅底"
      width="60%"
      top="100px"
    >
      <page-lakeyxqd v-if="lakeYXQDFlag" :lake-yxqd="lakeYXQD" :obj-info="objInfo" />
    </el-dialog>

    <el-dialog
      append-to-body
      :visible.sync="lakeRCWHFlag"
      custom-class="test-dialog"
      title="人昌文和"
      width="60%"
      top="100px"
    >
      <page-lakercwh v-if="lakeRCWHFlag" :lake-rcwh="lakeRCWH" :obj-info="objInfo" />
    </el-dialog>

    <el-dialog
      append-to-body
      :visible.sync="lakeCZJAFlag"
      custom-class="test-dialog"
      title="长治久安"
      width="60%"
      top="100px"
    >
      <page-lakeczja v-if="lakeCZJAFlag" :lake-czja="lakeCZJA" :obj-info="objInfo" />
    </el-dialog>
  </div>
</template>
<script>
  import BarChart from '../mapChart/barChart.vue'
  import BarCircleChart from '../mapChart/barCircleChart.vue'
  import PieBingTuChart from '../mapChart/pieBingTuChart.vue'

  import PageRiveryxqd from '../objPage/yxqdRiver.vue'
  import PageRiverrcwh from '../objPage/rcwhRiver.vue'
  import PageRiverczja from '../objPage/czjaRiver.vue'
  import PageLakeyxqd from '../objPage/yxqdLake.vue'
  import PageLakercwh from '../objPage/rcwhLake.vue'
  import PageLakeczja from '../objPage/czjaLake.vue'

  export default {
    name: 'RightInfo',
    components: {
      BarChart,
      BarCircleChart,
      PieBingTuChart,

      PageRiveryxqd,
      PageRiverrcwh,
      PageRiverczja,
      PageLakeyxqd,
      PageLakercwh,
      PageLakeczja
    },
    props: {
      rlInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        objInfo: {},
        evaluatingRankingB1: {}, // 鱼翔浅底 指标排行

        rlChartData: [], // 单一河湖对象图标数据

        B1Score: null, // 鱼翔浅底 层级分数
        ciYunData: [], // 鱼翔浅底数据

        interferenceData: [], // 文昌人和 数据
        B4Score: null, // 文昌人和 B4层级分数

        B5Score: null, // 长治久安 分数
        funnelData: [], // 长治久安数据

        targetDInfoData: [],

        B6Score: null,
        publicSatisfaction: [],
        publicSatisfactionScore: null,

        riverYXQDFlag: false, // 鱼翔浅底展示控制器
        riverYXQD: {}, // 鱼翔浅底数据

        riverRCWHFlag: false, // 人昌文和展示控制器
        riverRCWH: {}, // 人昌文和数据

        riverCZJAFlag: false, // 长治久安展示控制器
        riverCZJA: {}, // 长治久安数据

        lakeYXQDFlag: false, // 鱼翔浅底展示控制器
        lakeYXQD: {}, // 鱼翔浅底数据

        lakeRCWHFlag: false, // 人昌文和展示控制器
        lakeRCWH: {}, // 人昌文和数据

        lakeCZJAFlag: false, // 长治久安展示控制器
        lakeCZJA: {} // 长治久安数据
      }
    },
    computed: {},
    watch: {
      rlInfo: {
        immediate: true,
        handler(val) {
          if (JSON.stringify(val) != '{}') {
            this.loadData(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData(data) {
        let choosenRlInfo = data
        // if (choosenRlInfo['waterSystemMapUrl']) {
        //   this.waterSystemMapUrl = choosenRlInfo.waterSystemMapUrl
        // }
        if (choosenRlInfo['target']) {
          let scheme = choosenRlInfo.target
          this.objInfo = choosenRlInfo
          if (choosenRlInfo['qusVo']) {
            // 调查问卷部分页面绘制
            this.qusPer =
              100 -
              choosenRlInfo.qusVo.disable /
                (choosenRlInfo.qusVo.bad +
                  choosenRlInfo.qusVo.disable +
                  choosenRlInfo.qusVo.middle +
                  choosenRlInfo.qusVo.good +
                  choosenRlInfo.qusVo.veryGood)
            this.publicSatisfactionScore = choosenRlInfo.qusVo.score
            this.publicSatisfaction = [
              choosenRlInfo.qusVo.veryGood,
              choosenRlInfo.qusVo.good,
              choosenRlInfo.qusVo.middle,
              choosenRlInfo.qusVo.bad,
              choosenRlInfo.qusVo.disable
            ]
          }
          if (scheme.children.length > 0) {
            console.log(scheme)
            for (let i = 0; i < scheme.children.length; i++) {
              if (scheme.children[i].code == 'B3') {
                this.ciYunDataDo(scheme.children[i])
              }
              if (scheme.children[i].code == 'B4') {
                this.interferenceDataDo(scheme.children[i])
              }
              if (scheme.children[i].code == 'B5') {
                this.funnelDataDo(scheme.children[i])
              }
              if (scheme.children[i].code == 'B6') {
                this.B6Score = scheme.children[i].scoreVal.toFixed(1)
              }
            }
          }
        }
      },
      /** 处理 鱼翔浅底 数据 */
      ciYunDataDo(info) {
        this.B1Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.ciYunData = []
        this.childData(info)

        this.rlChartData.forEach((item) => {
          let param = {
            name: item.name,
            value: item.value
          }
          this.ciYunData.push(param)
        })
        // this.ciYunData = this.rlChartData;
        this.evaluatingRankingB1 = {
          data: this.ciYunData, // 河湖对象详细指标评分信息数据
          rowNum: 4
        }
      },
      /** 获取指标层信息数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              let param = {
                name: item.name,
                value: item.scoreVal.toFixed(1)
              }
              this.rlChartData.push(param)
              this.targetDInfoData.push(item)
            }
          })
        }
      },
      /** 处理单个河湖对象饼图图表数据 */
      interferenceDataDo(info) {
        this.B4Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.interferenceData = []
        this.childData(info)
        this.interferenceData = this.rlChartData
      },
      /** 处理单个河湖对象饼图图表数据 */
      funnelDataDo(info) {
        this.B5Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.funnelData = []
        this.childData(info)
        this.funnelData = this.rlChartData
        console.log(this.funnelData)
      },

      /** 获取四大类详细指标 */
      getALLdesc(info) {
        console.log(this.objInfo)
        if (
          this.rlInfo.rlType.includes('HeDao') ||
          this.rlInfo.rlType.includes('CunLuo') ||
          this.rlInfo.rlType.includes('YeWai')
        ) {
          if (info == 1) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B3') {
                this.riverYXQD = this.objInfo.target.children[i]
              }
            }
            this.riverYXQDFlag = true
          } else if (info == 2) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B4') {
                this.riverRCWH = this.objInfo.target.children[i]
              }
            }
            this.riverRCWHFlag = true
          } else if (info == 3) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B5') {
                this.riverCZJA = this.objInfo.target.children[i]
              }
            }
            this.riverCZJAFlag = true
          }
        } else {
          if (info == 1) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B3') {
                this.lakeYXQD = this.objInfo.target.children[i]
              }
            }
            this.lakeYXQDFlag = true
          } else if (info == 2) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B4') {
                this.lakeRCWH = this.objInfo.target.children[i]
              }
            }
            this.lakeRCWHFlag = true
          } else if (info == 3) {
            for (let i = 0; i < this.objInfo.target.children.length; i++) {
              if (this.objInfo.target.children[i].code == 'B5') {
                this.lakeCZJA = this.objInfo.target.children[i]
              }
            }
            this.lakeCZJAFlag = true
          }
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '../../../assets/css/elui.less';

  #boxRight {
    position: absolute;
    width: 20.8vw;
    right: 24px;
    top: 90px;
    box-sizing: border-box;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    transition-property: right;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    z-index: 100;
  }

  .object-right-top {
    width: 19.2vw;
    height: 17.8vh;

    margin: 1.5vh 0.8vw 1.5vh 0.8vw;
    position: relative;
    #txChart1A {
      width: 19.2vw;
      height: 90%;
      .dv-scr-rank {
        width: 100%;
        height: 80%;
        box-sizing: border-box;
        margin-top: 15px;
        /deep/ .row-item {
          height: auto;
        }
        /deep/ .ranking-info {
          height: 14px;
          .rank {
            display: none;
          }
          .info-name {
            font-size: 12px;
            margin-top: 5px;
            color: rgba(255, 255, 255, 0.9);
            font-family: PingFang SC;
          }
          .ranking-value {
            margin-top: 4px;
            color: rgba(60, 212, 149, 1);
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
          }
        }
        /deep/ .ranking-column {
          margin-top: 8px;
          background: rgba(60, 212, 149, 0.1);
          border-bottom: none;
          .inside-column {
            height: 10px;
            border: 1px solid rgba(60, 212, 149, 0.7);
            box-sizing: border-box;
            background-color: rgba(60, 212, 149, 0.2);
          }
        }
      }
    }
  }
  .object-right-center {
    width: 19.2vw;
    height: 27vh;
    margin: 0 0.8vw 0 0.8vw;
    position: relative;
    .public {
      width: 19.2vw;
      height: 90%;
      padding: 10px 0 0;
    }
  }
  .object-right-centerBar {
    width: 19.2vw;
    height: 21.2vh;
    margin: 0 0.8vw 0 0.8vw;
    position: relative;
    .zxtjChartA {
      width: 19.2vw;
      height: 88%;
    }
  }
  .object-right-bottom {
    width: 19.2vw;
    height: 18.7vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;

    .zxtjChartA {
      width: 19.2vw;
      height: 88%;
    }
  }

  .object-left-center {
    width: 19.2vw;
    height: 25.1vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;
    .public {
      width: 19.2vw;
      height: 95%;
      .totalScore {
        position: absolute;
        left: 0;
        top: 5.9vh;
        margin-left: 7vw;
        color: rgba(60, 212, 149, 1);
        font-family: PingFang SC;
        font-size: 1.7vh;
      }
    }
  }
</style>
