<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="boxLeft">
    <!-- 单一对象信息左侧信息列表 -->
    <div class="object-left-top">
      <MyTitle :title="objName + '简介'" />
      <div class="obj-desc-uni">
        <div class="obj-desc" v-html="objDesc"></div>
      </div>
    </div>
    <div class="object-left-center">
      <MyTitle title="公众满意度统计" />
      <!-- <div class="title">
          <img class="title-bg" src="../assets/area-title.png" alt />
          <div class="water">
            <div>公众满意度统计</div>
            <div>
              <span class="font-blueLeft">
                <span class="font-blueLeft-out">有效率：</span>
                {{ qusPer }}%
              </span>
            </div>
          </div>
        </div> -->
      <div class="public">
        <div class="totalScore">总分：{{ publicSatisfactionScore }}分</div>
        <BarChart :public-satisfaction="publicSatisfaction" />
      </div>
    </div>
    <div class="object-left-bottom">
      <MyTitle title="问题与建议" />
      <div class="swiperText">
        <el-carousel :interval="5000" arrow="always" height="22.1vh">
          <el-carousel-item v-for="item in items" :key="item.value">
            <div class="carousel-explain">
              <p class="text-first">{{ item.txtFirst }}</p>
              <p class="text-second" v-html="item.textSecond"></p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
  import BarChart from '../mapChart/barChart'

  export default {
    name: '',
    components: {
      BarChart
    },
    props: {
      rlInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        publicSatisfactionScore: null,
        publicSatisfaction: [],
        qusPer: null,

        objName: null,
        objDesc: null,

        items: [
          // 问题列表items轮播

          {
            txtFirst: '水质和富营养化',
            textSecond:
              '针对富营养化等受污染水体，可采取设置人工曝气增氧、生态植物浮床、生物接触氧化、活水循环、自然生物处理、除藻等治理措施，强化水体的净化功能，改善水质。确定城市河湖汇水分区，对汇水分区内的雨、污水管网进行排查，明确雨污分流改造情况，针对雨污混接问题点，应逐一建档，并制定整改方案和计划。根据管网排查，摸清管网缺口，加快空白区管网建设，确保污水接管。加强农业面源污染治理。推进化肥农药减量使用，推广测土配方施肥技术。规模化养殖场应配备粪污处理设施，并推进畜禽粪污资源化利用。'
          }
        ]
      }
    },
    computed: {},
    watch: {
      rlInfo: {
        immediate: true,
        handler(val) {
          console.log('<== rlinfo ==>', val)
          if (JSON.stringify(val) != '{}') {
            this.loadData(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData(data) {
        let choosenRlInfo = data
        let scheme = choosenRlInfo
        if (scheme['qusVo']) {
          // 调查问卷部分页面绘制
          this.qusPer =
            100 -
            scheme.qusVo.disable /
              (scheme.qusVo.bad +
                scheme.qusVo.disable +
                scheme.qusVo.middle +
                scheme.qusVo.good +
                scheme.qusVo.veryGood)
          this.publicSatisfactionScore = scheme.qusVo.score
          this.publicSatisfaction = [
            scheme.qusVo.veryGood,
            scheme.qusVo.good,
            scheme.qusVo.middle,
            scheme.qusVo.bad,
            scheme.qusVo.disable
          ]
        }
        this.objName = choosenRlInfo.obj.name
        this.objDesc = `${choosenRlInfo.obj.comments}`
      }
    }
  }
</script>
<style lang="less" scoped>
  #boxLeft {
    position: absolute;
    width: 20.8vw;
    left: 24px;
    top: 100px;
    box-sizing: border-box;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    transition-property: left;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    z-index: 100;
  }
  .object-left-top {
    width: 19.2vw;
    height: 24.1vh;
    overflow: auto;
    margin: 1.5vh 0.8vw 1.5vh 0.8vw;
    position: relative;
    .obj-desc-uni {
      text-indent: 2rem;
      color: rgba(255, 255, 255, 0.9);
      height: 24vh;
      font-size: 1.5vh;
      line-height: 200%;
      letter-spacing: 0.9px;
      margin: 1.5vh 0 0.7vh 0;
      overflow-y: auto;
      text-align: justify;
      .obj-desc {
        -webkit-animation: 10s rowup linear infinite normal;
        animation: 10s rowup linear infinite normal;
        span {
          font-family: PingFang SC;
          color: rgba(60, 212, 149, 1);
        }
      }
    }
  }
  .object-left-center {
    width: 19.2vw;
    height: 28.1vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;
    .public {
      width: 19.2vw;
      height: 95%;
      .totalScore {
        position: absolute;
        left: 0;
        top: 5.9vh;
        margin-left: 7vw;
        color: rgba(60, 212, 149, 1);
        font-family: PingFang SC;
        font-size: 1.7vh;
      }
    }
  }
  .object-left-bottom {
    width: 19.2vw;
    height: 28.1vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;
    .swiperText {
      width: 19.2vw;
      height: 21.7vh;
      position: absolute;
      left: 0;
      top: 5.9vh;
      .el-carousel__item .carousel-explain {
        width: 17.5vw;
        height: 18vh;
        margin: 1.5vh auto;
        color: rgba(255, 255, 255, 0.9);
        font-size: 1.5vh;
        line-height: 200%;
        overflow-y: auto;
        .text-first {
          width: 15.4vw;
          height: auto;
          margin: 0 0.4vw 1.1vh 1.7vw;
          position: relative;
        }
        .text-first::before {
          content: '';
          position: absolute;
          left: -1.2vw;
          top: 0.78vh;
          width: 1.5vh;
          height: 1.5vh;
          display: block;
          background: url('../../../assets/icon/icon_1.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        .text-second {
          width: 15.4vw;
          height: auto;
          margin: 0 0.4vw 1.1vh 1.7vw;
          position: relative;
        }
        .text-second::before {
          content: '';
          position: absolute;
          left: -1.2vw;
          top: 0.78vh;
          width: 1.5vh;
          height: 1.5vh;
          display: block;
          background: url('../../../assets/icon/icon_2.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }

      .el-carousel__item:nth-child(2n) {
        height: 21.7vh;
        background: rgba(0, 232, 255, 0.04);
      }

      .el-carousel__item:nth-child(2n + 1) {
        height: 21.7vh;
        background: rgba(0, 232, 255, 0.04);
      }
      /deep/ .el-carousel__arrow--left {
        width: 24px;
        height: 24px;
        border-radius: 0 !important;
        position: absolute;
        left: 0px;
        color: rgba(216, 251, 255, 0.2);
        background-image: url('../../../assets/icon/swiper-left.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      /deep/ .el-carousel__arrow--left:hover {
        background-image: url('../../../assets/icon/swiper-left-hover.png');
      }
      /deep/ .el-carousel__arrow--right {
        width: 24px;
        height: 24px;
        border-radius: 0 !important;
        position: absolute;
        right: 0px;
        color: rgba(216, 251, 255, 0.2);
        background-image: url('../../../assets/icon/swiper-right.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      /deep/ .el-carousel__arrow--right:hover {
        background-image: url('../../../assets/icon/swiper-right-hover.png');
      }
      /deep/ .el-icon-arrow-left {
        display: none;
      }
      /deep/ .el-icon-arrow-right {
        display: none;
      }
      /deep/ .el-carousel__button {
        width: 0.5vh;
        height: 0.5vh;
        border-radius: 50%;
      }
    }
  }
</style>
