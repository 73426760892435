<template>
  <div>
    <online-city v-if="!isActive"></online-city>
    <online-province v-else></online-province>
    <div class="change-btn-box">
      <button class="change-btn" :class="{ 'change-btn-active': isActive }" @click="exchange(1)">省级指标</button>
      <button class="change-btn" :class="{ 'change-btn-active': !isActive }" @click="exchange(2)">市级指标</button>
    </div>
  </div>
</template>
<script>
  import OnlineCity from './online/index.vue'
  import OnlineProvince from './provinceOnline/index.vue'
  export default {
    name: '',
    components: {
      OnlineCity,
      OnlineProvince
    },
    props: {},
    data() {
      return {
        isActive: false // 省级/市级指标切换按钮
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      exchange(type) {
        if (type === 1) {
          this.isActive = true
        } else {
          this.isActive = false
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .change-btn-box {
    position: absolute;
    top: 104px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;

    .change-btn {
      // border: none;
      background-color: unset;
      border-radius: 4px;
      color: rgb(255, 255, 255);
      font-family: 'PingFang SC';
      font-size: 16px;
      width: 102px;
      height: 36px;
      border: 1px solid rgba(0, 220, 255, 0.4);
    }
    .change-btn-active {
      // border: none;
      background-color: unset;
      border-radius: 4px;
      border-color: 1px solid rgba(60, 212, 149, 0.5);
      color: rgb(60, 212, 149);
      background: radial-gradient(86.65% 26.8% at 50% 50%, rgba(60, 212, 149, 0) 0%, rgba(60, 212, 149, 0.24) 100%);
    }
  }
</style>
