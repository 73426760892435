<template>
  <div>
    <div id="cmap" style="height: 100vh; width: 100vw"></div>
    <div class="mask-div" style></div>
  </div>
</template>
<script>
  import 'ol/ol.css'
  import { Map, View, Feature } from 'ol'
  import { defaults as defaultControls } from 'ol/control'
  import { defaults } from 'ol/interaction'
  import TileLayer from 'ol/layer/Tile'
  import VectorLayer from 'ol/layer/Vector'
  import OSM from 'ol/source/OSM'
  import Vector from 'ol/source/Vector'
  import { Style, Circle, Fill, Stroke, Text, Icon } from 'ol/style'
  import GeoJSON from 'ol/format/GeoJSON'
  import { fromLonLat, transform } from 'ol/proj'
  import WKT from 'ol/format/WKT'
  import Select from 'ol/interaction/Select'
  import { pointerMove, singleClick } from 'ol/events/condition'
  import XYZ from 'ol/source/XYZ'
  import Static from 'ol/source/ImageStatic'
  import ImageLayer from 'ol/layer/Image'
  import Projection from 'ol/proj/Projection'
  import { getCenter } from 'ol/extent'
  import Overlay from 'ol/Overlay'
  import WMTSTileGrid from 'ol/tilegrid/WMTS'
  import WMTS from 'ol/source/WMTS'
  import { getTopLeft, getWidth } from 'ol/extent'
  import { get as getProjection } from 'ol/proj'

  const extent = [120.3569667238902, 30.757833911398727, 120.89901672389161, 31.219523911397924]
  const pointJson = require('./point.json')
  const areaGeom = require('./area.json')

  export default {
    name: '',
    components: {},
    props: {
      rlData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        map: null, // 地图对象
        mapTdt: null, // 天地图图层
        osmTile: null, // 图片图层
        areaLayer: null, // 行政区图层
        areaFeature: null, // 行政区对象
        pointLayer: null, // 首页点位标记图层
        infoLlayer: null, // 信息标准线图层
        imageAnnotation: null, // 影像注记图层
        centerPointList: [], // 河湖对象中心点列表
        featureList: [], //河湖对象图层列表
        infoLineFeatureList: [], // 信息线图层列表
        overlay: null, // 河湖对象名称得分显示框
        rlComReachLayer: null, // 方案河段湖区信息图层
        schemeSiteLayer: null // 方案站点信息图层
      }
    },
    computed: {},
    watch: {
      rlData: {
        immediate: true,
        handler(val) {
          if (val.length > 0) {
            this.layerDataHandle(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {
      let layerList = []
      /** 地图底图 */
      this.osmTile = new TileLayer({
        source: new XYZ({
          name: '中国',
          url:
            'http://' +
            this.MY_MAP_LEVEL_NUM +
            '.tianditu.com/DataServer?T=img_c&x={x}&y={y}&l={z}&tk=26ee4341dc4c0c4269d2222be779e168',
          projection: 'EPSG:4326',
          tileLoadFunction: function (imageTitle, src) {
            const img = new Image()
            img.crossOrigin = ''
            img.onload = () => {
              const canvas = document.createElement('canvas')
              const w = img.width
              const h = img.height
              canvas.width = w
              canvas.height = h
              const context = canvas.getContext('2d')
              context.drawImage(img, 0, 0, w, h, 0, 0, w, h)
              const imgData = context.getImageData(0, 0, w, h)
              context.putImageData(imgData, 0, 0)
              imageTitle.getImage().src = canvas.toDataURL('image/png')
            }
            img.src = src
          }
        })
      })
      /** 影像注记--图层 */
      let resolutions = []
      for (let z = 2; z < 19; ++z) {
        resolutions[z] = getWidth(getProjection('EPSG:4326').getExtent()) / 256 / Math.pow(2, z)
      }
      this.imageAnnotation = new TileLayer({
        source: new WMTS({
          name: '中国矢量注记1-4级',
          url: 'http://' + this.MY_MAP_LEVEL_NUM + '.tianditu.gov.cn/cia_c/wmts?tk=26ee4341dc4c0c4269d2222be779e168',
          layer: 'cia',
          style: 'default',
          matrixSet: 'c',
          format: 'tiles',
          wrapX: true,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(getProjection('EPSG:4326').getExtent()),
            resolutions: resolutions,
            matrixIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
          })
        })
      })
      let formatArea = new WKT()
      this.areaFeature = formatArea.readFeature(areaGeom.geom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:4326'
      })
      this.areaFeature.setProperties({
        type: 9,
        name: areaGeom.name
      })
      let areaStyle = new Style({
        stroke: new Stroke({
          color: 'rgba(255, 193, 0, 1)',
          width: 3,
          lineDash: [10, 10, 10, 10]
        }),
        fill: new Fill({
          color: 'rgba(3,25,28,0.5)'
        })
      })
      this.areaFeature.setStyle(areaStyle)
      /** 行政区对象--图层 */
      this.areaLayer = new VectorLayer({
        source: new Vector({
          features: [this.areaFeature]
        })
      })
      layerList.push(this.osmTile, this.areaLayer)
      console.log(layerList)
      this.map = new Map({
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false
        }),
        target: 'cmap',
        layers: layerList,
        // 地图视图信息（中心店，坐标系，图层级别）
        view: new View({
          // extent: [120.1069667238902, 30.707833911398727, 121.12601672389161, 31.519523911397924],
          projection: 'EPSG:4326',
          center: getCenter(extent),
          zoom: 11.4
        })
        // interactions: defaults({
        //   doubleClickZoom: false, // 取消双击放大功能交互
        //   mouseWheelZoom: false, // 取消滚动鼠标中间的滑轮交互
        //   shiftDragZoom: false // 取消shift+wheel左键拖动交互
        // })
      })
    },
    methods: {
      /** 地图加载渲染 */
      drawMap() {
        if (this.map.getLayers().array_[2]) {
          this.map.removeLayer(this.vector)
        }
        /** 区域水系对象--图层 */
        this.vector = new VectorLayer({
          source: new Vector({
            features: [...this.featureList]
          })
        })
        this.map.addLayer(this.vector)

        /** 对象信息展示div */
        this.pointDo()
        this.getFeaterInfo()
        this.rlPointMove()
      },
      /** 图层资源数据处理方法 */
      layerDataHandle(info) {
        let geoData = info
        let center = null
        this.featureList = []
        this.infoLineFeatureList = []
        pointJson.features = []
        this.centerPointList = []

        geoData.forEach((item) => {
          let wkt = item.obj.geom
          let infoLineFeature = null
          let format = new WKT()
          let feature = format.readFeature(wkt, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326'
          })
          let rlScore = null
          if (item.target.scoreVal != 0) {
            rlScore = item.target.scoreVal
          }
          feature.setProperties({
            id: item.id,
            name: item.obj.name,
            score: rlScore
          })
          if (rlScore != null) {
            let colorStroke = null
            let colorFill = null
            if (rlScore >= 90) {
              ;(colorStroke = 'rgba(26, 177, 214, 1)'), (colorFill = 'rgba(26, 177, 214, 0.1)')
            } else if (rlScore >= 80) {
              ;(colorStroke = 'rgba(124, 234, 189, 1)'), (colorFill = 'rgba(124, 234, 189, 0.1)')
            } else if (rlScore >= 60) {
              ;(colorStroke = 'rgba(251, 252, 84, 1)'), (colorFill = 'rgba(251, 252, 84, 0.1)')
            } else if (rlScore >= 40) {
              ;(colorStroke = 'rgba(239, 138, 52, 1)'), (colorFill = 'rgba(239, 138, 52, 0.1)')
            } else {
              ;(colorStroke = 'rgba(213, 56, 38, 1)'), (colorFill = 'rgba(213, 56, 38, 0.1)')
            }
            let styleHh = new Style({
              stroke: new Stroke({
                color: colorStroke,
                width: 2
              }),
              fill: new Fill({
                color: colorFill
              })
            })
            let infoLineStyle = new Style({
              text: new Text({
                text: item.obj.name + '\t' + rlScore.toFixed(1), // 添加文字描述
                font: '16px font-size', // 设置字体大小
                fill: new Fill({
                  // 设置字体颜色
                  color: colorStroke
                }),
                offsetX: 50 // 设置文字偏移量
              })
            })
            feature.setStyle(styleHh)
            infoLineFeature = format.readFeature(item.obj.geom, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:4326'
            })
            let param = {
              point: getCenter(infoLineFeature.getGeometry().getExtent()),
              name: item.obj.name,
              score: rlScore,
              id: item.id
            }
            this.centerPointList.push(param)
          } else {
            let styleNull = new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.9)',
                width: 1
              }),
              fill: new Fill({
                color: 'rgba(255, 255, 255, 0.1)'
              })
            })
            feature.setStyle(styleNull)
            infoLineFeature = format.readFeature(item.obj.geom, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:4326'
            })
            let param = {
              point: getCenter(infoLineFeature.getGeometry().getExtent()),
              name: item.obj.name,
              score: rlScore,
              id: item.id
            }
            this.centerPointList.push(param)
          }
          this.featureList.push(feature)
        })

        this.drawMap()
      },
      /** 对象信息展示div */
      pointDo() {
        this.map.getOverlays().clear()
        this.centerPointList.forEach((item) => {
          let colorStroke = null
          let className = ''
          if (item.score) {
            if (item.score >= 90) {
              colorStroke = 'rgba(26, 177, 214, 1)'
              className = 'rl-veryHealthy'
            } else if (item.score >= 80) {
              colorStroke = 'rgba(124, 234, 189, 1)'
              className = 'rl-healthy'
            } else if (item.score >= 60) {
              colorStroke = 'rgba(251, 252, 84, 1)'
              className = 'rl-subhealthy'
            } else if (item.score >= 40) {
              colorStroke = 'rgba(239, 138, 52, 1)'
              className = 'rl-unhealthy'
            } else {
              colorStroke = 'rgba(213, 56, 38, 1)'
              className = 'rl-morbid'
            }
            /** popup 功能实现 */
            let container = document.createElement('div')
            let that = this
            container.onclick = function () {
              that.map
                .getLayers()
                .array_[2].getSource()
                .getFeatures()
                .forEach((fea) => {
                  if (fea.values_.id == item.id) {
                    that.map.getView().fit(fea.getGeometry(), {
                      padding: [150, 500, 30, 500],
                      duration: 1500
                    })
                    that.isShow = false
                    that.showDelFirst = false
                    that.getObjData(fea)
                  }
                })
            }
            container.setAttribute('class', className + ' rl-main')
            this.overlay = new Overlay({
              element: container,
              autoPan: true,
              autoPanAnimation: {
                duration: 250
              }
            })
            this.map.addOverlay(this.overlay)
            container.innerHTML =
              "<span class='rl-name'>" +
              item.name +
              "</span><span class='rl-score'>" +
              item.score.toFixed(1) +
              '</span>'
            this.overlay.setPosition(item.point)
          } else {
            /** popup 功能实现 */
            let container = document.createElement('div')
            let that = this
            container.onclick = function () {
              that.map
                .getLayers()
                .array_[2].getSource()
                .getFeatures()
                .forEach((fea) => {
                  if (fea.values_.id == item.id) {
                    that.map.getView().fit(fea.getGeometry(), {
                      padding: [150, 500, 30, 500],
                      duration: 1500
                    })
                    that.fitObj = fea.getGeometry()
                    that.isShow = false
                    that.showDelFirst = false
                    that.getObjData(fea)
                  }
                })
            }
            container.setAttribute('class', 'rl-on-assessment  rl-main')
            this.overlay = new Overlay({
              element: container,
              autoPan: true,
              autoPanAnimation: {
                duration: 250
              }
            })
            this.map.addOverlay(this.overlay)
            container.innerHTML = "<span class='rl-name'> " + item.name + '</span>'
            this.overlay.setPosition(item.point)
          }
        })
      },
      /** 点击地图如果有对象，展示对象信息 */
      getFeaterInfo() {
        const _that = this
        this.map.on('click', function (event) {
          _that.vector.changed()
          const coordinate = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')
          // 判定点击是否有图层，有的话返回图层信息，没有打点
          var feature = _that.map.forEachFeatureAtPixel(
            event.pixel,
            function (feature) {
              if (feature.get('type') == 9) {
                _that.map.getView().fit(feature.getGeometry(), {
                  padding: [100, 400, 30, 400],
                  duration: 1500
                })
                _that.map.setLayers([_that.osmTile, _that.areaLayer, _that.vector])
                _that.$emit('changeShow', true)
                _that.showDelFirst = true
                return false
              }
              if (feature.values_.rlCom) {
                return false
              }
              return feature
            }
            // {
            //   hitTolerance: 50 // 触发事件的热区
            // }
          )
          // 点击有图层对象
          if (feature) {
            if (_that.$parent.show == true) {
              _that.map.getView().fit(feature.getGeometry(), {
                padding: [150, 500, 30, 500],
                duration: 1500
              })
              // _that.$emit('changeShow', false)
              _that.showDelFirst = false
              _that.getObjData(feature)
            }
          } else {
            // _that.map.getView().setCenter(getCenter(extent));
            // _that.map.getView().setZoom(11.4);
            // _that.map.setLayers([_that.osmTile, _that.areaLayer, _that.vector]);
            // _that.isShow = true;
            // _that.showDelFirst = true;
          }
        })
      },
      /** 获取鼠标点击对象信息 */
      getObjData(info) {
        this.$emit('changeShow', false)

        let choosenRlInfo = {}
        this.objInfo = {}
        this.waterSystemMapUrl = null
        this.objScore = 0
        this.rlData.forEach((item) => {
          if (item.id == info.values_.id) {
            choosenRlInfo = item
          }
        })
        this.$emit('setRlInfo', choosenRlInfo)
      },

      /** 首页展示分数详情鼠标移动事件 */
      rlPointMove() {
        var _that = this
        var selectS = new Select({
          condition: pointerMove, // 唯一的不同之处，设置鼠标移到feature上就选取 pointerMove
          layers: [this.map.getLayers().array_[2]],
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(255, 193, 0, 1)',
              width: 3
            }),
            fill: new Fill({
              color: 'rgba(255, 193, 0, 0.1)'
            })
          })
        })
        this.map.addInteraction(selectS)
        selectS.on('select', function (e) {
          if (e.target.getFeatures().array_.length !== 0) {
            if (_that.$parent.show) {
              let chooseRl = e.target.getFeatures().array_[0].values_
              _that.$emit('mouseMoveChange', chooseRl)
            }
            // _that.rlComDataList.forEach((item) => {
            //   if (item.id == chooseRl.id) {
            //     _that.objName = chooseRl.name
            //     if (item['assessScheme']) {
            //       _that.score = item.assessScheme.gtVo.score[0].score.toFixed(1)
            //       _that.delScoreList = item.assessScheme.gtVo.children
            //     } else {
            //       _that.score = 0
            //       _that.delScoreList = [
            //         {
            //           name: '生态健康',
            //           score: [
            //             {
            //               score: 0
            //             }
            //           ]
            //         },
            //         {
            //           name: '服务功能',
            //           score: [
            //             {
            //               score: 0
            //             }
            //           ]
            //         },
            //         {
            //           name: '干扰程度',
            //           score: [
            //             {
            //               score: 0
            //             }
            //           ]
            //         },
            //         {
            //           name: '管理水平',
            //           score: [
            //             {
            //               score: 0
            //             }
            //           ]
            //         }
            //       ]
            //     }
            //   }
            // })
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .rl-main {
    // margin-left: -70px;
    // margin-top: -121px;
    padding: 4px 5px;
    font-size: 18px;
    font-family: 'title-font';
    border: 1px solid rgba(60, 212, 149, 0.3);
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    .rl-name {
      text-align: center;
      color: rgba(255, 255, 255, 0.9);
      font-family: PingFang SC;
      font-size: 16px;
    }
    .rl-score {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 18px;
      line-height: 14px;
      margin-left: 8px;
    }
  }
  /deep/ .rl-veryHealthy {
    // border: 1px solid rgba(26, 177, 214, 1);
    color: rgba(26, 177, 214, 1);
  }
  /deep/ .rl-healthy {
    // border: 1px solid rgba(124, 234, 189, 1);
    color: rgba(124, 234, 189, 1);
  }
  /deep/ .rl-subhealthy {
    // border: 1px solid rgba(251, 252, 84, 1);
    color: rgba(251, 252, 84, 1);
  }
  /deep/ .rl-unhealthy {
    // border: 1px solid rgba(239, 138, 52, 1);
    color: rgba(239, 138, 52, 1);
  }
  /deep/ .rl-morbid {
    // border: 1px solid rgba(213, 56, 38, 1);
    color: rgba(213, 56, 38, 1);
  }
  /deep/ .rl-on-assessment {
    // border: 1px solid rgb(160, 213, 38);
    color: rgb(146, 213, 38);
    z-index: 999;
  }
  .mask-div {
    pointer-events: none;
    position: absolute;
    background: radial-gradient(
      49.46% 63.74% at 50% 48.1%,
      rgba(1, 8, 17, 0) 0%,
      rgba(1, 8, 17, 0.08) 41.67%,
      rgba(1, 8, 17, 0.62) 84.38%
    );
    width: 100vw;
    height: 100vh;
    left: 0;
    bottom: 0;
    // opacity: 0.8;
  }
</style>
