<template>
  <!-- 湖水交换率 -->
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  export default {
    components: {
      Pie
    },
    props: {
      // eslint-disable-next-line vue/require-default-prop
      lakeWaterExchangeInfo: {
        type: Object,
        default: () => {}
      },
      // eslint-disable-next-line vue/require-default-prop
      riverFluidityInfo: {
        type: [Number || String],
        default: null
      },
      // eslint-disable-next-line vue/require-default-prop
      waterSupplyGuaranteeInfo: {
        type: [Number || String],
        default: null
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      if (this.lakeWaterExchangeInfo) {
        var a = this.lakeWaterExchangeInfo.V
        var e = this.lakeWaterExchangeInfo.Q
        this.options = {
          series: [
            {
              name: '刻度',
              type: 'gauge',
              center: ['50%', '70%'],
              radius: '80%',
              min: 0, //最小刻度
              max: 100, //最大刻度
              splitNumber: 5, //刻度数量
              startAngle: 180,
              endAngle: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: [[1, 'rgba(0,0,0,0)']]
                }
              }, //仪表盘轴线
              axisLabel: {
                show: true,
                color: 'rgb(255, 255, 255)',
                fontSize: 12,
                distance: -21,
                formatter: function (v) {
                  return v
                }
              }, //刻度标签。
              //   axisTick: {
              //     show: false,
              //     splitNumber: 5,
              //     lineStyle: {
              //       color: "#263b35",
              //       width: 1
              //       // length:10
              //     },
              //     length: -200
              //   }, //刻度样式
              splitLine: {
                show: true,
                length: -20,
                lineStyle: {
                  color: '#4aca96',
                  width: 2
                }
              } //分隔线样式
            },
            {
              type: 'gauge',
              radius: '100%',
              center: ['50%', '70%'],
              splitNumber: 0, //刻度数量
              startAngle: 180,
              endAngle: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 10,
                  color: [
                    [
                      1,
                      new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                          offset: 0,
                          color: 'rgb(46, 255, 255)'
                        },
                        {
                          offset: 1,
                          color: 'rgb(60, 212, 149)'
                        }
                      ])
                    ]
                  ]
                }
              },
              //分隔线样式。
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              pointer: {
                show: true,
                length: '100%',
                radius: '50%',
                width: 4, //指针粗细
                color: 'rgb(255,255,255)'
              },
              //仪表盘详情，用于显示数据。
              detail: {
                show: false
              },
              data: [
                {
                  value: (e / a) * 100
                }
              ]
            }
          ]
        }
      } else if (this.riverFluidityInfo) {
        let a = 0.5
        let e = this.riverFluidityInfo
        this.options = {
          series: [
            {
              name: '刻度',
              type: 'gauge',
              center: ['50%', '70%'],
              radius: '80%',
              min: 0, //最小刻度
              max: 1, //最大刻度
              splitNumber: 5, //刻度数量
              startAngle: 180,
              endAngle: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: [[1, 'rgba(0,0,0,0)']]
                }
              }, //仪表盘轴线
              axisLabel: {
                show: true,
                color: 'rgb(255, 255, 255)',
                fontSize: 12,
                distance: -21,
                formatter: function (v) {
                  return v
                }
              }, //刻度标签。
              //   axisTick: {
              //     show: false,
              //     splitNumber: 5,
              //     lineStyle: {
              //       color: "#263b35",
              //       width: 1
              //       // length:10
              //     },
              //     length: -200
              //   }, //刻度样式
              splitLine: {
                show: true,
                length: -20,
                lineStyle: {
                  color: '#4aca96',
                  width: 2
                }
              } //分隔线样式
            },
            {
              type: 'gauge',
              radius: '100%',
              center: ['50%', '70%'],
              splitNumber: 0, //刻度数量
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 1,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 10,
                  color: [
                    [
                      1,
                      new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                          offset: 0,
                          color: 'rgb(46, 255, 255)'
                        },
                        {
                          offset: 1,
                          color: 'rgb(60, 212, 149)'
                        }
                      ])
                    ]
                  ]
                }
              },
              //分隔线样式。
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              pointer: {
                show: true,
                length: '100%',
                radius: '50%',
                width: 4, //指针粗细
                color: 'rgb(255,255,255)'
              },
              //仪表盘详情，用于显示数据。
              detail: {
                show: false
              },
              data: [
                {
                  value: e
                }
              ]
            }
          ]
        }
      } else if (this.waterSupplyGuaranteeInfo) {
        let a = this.waterSupplyGuaranteeInfo > 365 ? this.waterSupplyGuaranteeInfo : 365
        let e = this.waterSupplyGuaranteeInfo
        this.options = {
          series: [
            {
              name: '刻度',
              type: 'gauge',
              center: ['50%', '60%'],
              radius: '90%',
              min: 0, //最小刻度
              max: 100, //最大刻度
              splitNumber: 5, //刻度数量
              startAngle: 180,
              endAngle: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: [[1, 'rgba(0,0,0,0)']]
                }
              }, //仪表盘轴线
              axisLabel: {
                show: true,
                color: 'rgb(255, 255, 255)',
                fontSize: 12,
                distance: -21,
                formatter: function (v) {
                  return v
                }
              }, //刻度标签。
              //   axisTick: {
              //     show: false,
              //     splitNumber: 5,
              //     lineStyle: {
              //       color: "#263b35",
              //       width: 1
              //       // length:10
              //     },
              //     length: -200
              //   }, //刻度样式
              splitLine: {
                show: true,
                length: -20,
                lineStyle: {
                  color: '#4aca96',
                  width: 2
                }
              } //分隔线样式
            },
            {
              type: 'gauge',
              radius: '100%',
              center: ['50%', '60%'],
              splitNumber: 0, //刻度数量
              startAngle: 180,
              endAngle: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 10,
                  color: [
                    [
                      1,
                      new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                        {
                          offset: 0,
                          color: 'rgb(46, 255, 255)'
                        },
                        {
                          offset: 1,
                          color: 'rgb(60, 212, 149)'
                        }
                      ])
                    ]
                  ]
                }
              },
              //分隔线样式。
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              pointer: {
                show: true,
                length: '100%',
                radius: '50%',
                width: 4, //指针粗细
                color: 'rgb(255,255,255)'
              },
              //仪表盘详情，用于显示数据。
              detail: {
                show: false
              },
              data: [
                {
                  value: (e / a) * 100
                }
              ]
            }
          ]
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
    //   .dv-dec-1 {
    //     position: relative;
    //     width: 100px;
    //     height: 20px;
    //     top: -3px;
    //   }
    //   .chart-box {
    //     margin-top: 16px;
    //     width: 170px;
    //     height: 170px;
    //     .active-ring-name {
    //       padding-top: 10px;
    //     }
    //   }
  }
</style>
