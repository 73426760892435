<template>
  <div id="rlBox" class="rl-score-info-box">
    <div
      class="title-text"
      :class="
        score > 90
          ? 'colorOver90 score borderLeftBlue'
          : score > 80
          ? 'colorOver80 score borderLeftGreen'
          : 'score colorOther borderLeftRed'
      "
    >
      <span class="name">{{ objName }}</span>
      <span v-if="isActive" class="score">{{ baseScore }}</span>
      <span v-else class="score"
        >{{ baseScore }} <span class="local-score">+{{ localScore }}</span>
      </span>

      <span class="point">分</span>
    </div>

    <div class="text-score">
      <div class="popLine">
        <div class="lin">-</div>
        <div class="tran"></div>
        <div class="lin">-</div>
      </div>
      <div class="score-box">
        <div v-for="(item, index) in delScoreList" :key="index" class="norm-index">
          <p class="norm-healthy">{{ item.name }}</p>
          <div v-if="isActive" class="norm-grade">
            {{ item.baseScoreVal && item.baseScoreVal.toFixed(1) }}
            <span>分</span>
          </div>
          <div v-else class="norm-grade">
            {{ item.baseScoreVal.toFixed(1) }}
            <span class="local-score">+{{ item.localScoreVal && item.localScoreVal.toFixed(1) }}</span>
            <span>&nbsp;分</span>
          </div>
        </div>
      </div>

      <div class="popLine">
        <div class="lin">-</div>
        <div class="tran"></div>
        <div class="lin">-</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      data: {
        type: Object,
        default: () => {}
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        delScoreList: [],
        score: null,
        baseScore: null, // 省级标准分数
        localScore: null, // 市级标准分数
        objName: null
      }
    },
    computed: {},
    watch: {
      data: {
        immediate: true,
        handler(val) {
          if (val && JSON.stringify(val) != '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description  数据初始化
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData(data) {
        console.log('==6大类指标项分数', data)
        this.objName = data.obj.name
        if (data.target.scoreVal != 0) {
          this.score = data.target.scoreVal.toFixed(1)
          this.baseScore = data.target.baseScoreVal.toFixed(1)
          this.localScore = data.target.localScoreVal.toFixed(1)
          this.delScoreList = data.target.children
        } else {
          this.score = 0
          this.delScoreList = [
            {
              name: '生态健康',
              score: [
                {
                  score: 0
                }
              ]
            },
            {
              name: '服务功能',
              score: [
                {
                  score: 0
                }
              ]
            },
            {
              name: '干扰程度',
              score: [
                {
                  score: 0
                }
              ]
            },
            {
              name: '管理水平',
              score: [
                {
                  score: 0
                }
              ]
            }
          ]
        }

        console.log('== 处理之后的6大类数据==', this.delScoreList)
      }
    }
  }
</script>
<style lang="less" scoped>
  .rl-score-info-box {
    position: absolute;
    z-index: 2021;
    right: 23vw;
    bottom: 2.2vh;
    max-width: 363px;
    height: 280px;
    background: rgba(20, 31, 41, 0.5);
    backdrop-filter: blur(20px);

    .title-text {
      // width: 231px;
      height: 56px;
      background: rgba(60, 212, 149, 0.04);
      margin: 24px 24px 0;
      align-items: center;
      display: flex;
      .name {
        font-family: PingFang SC;
        font-size: 20px;
        margin: 0 16px;
      }
      .score {
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 35px;
        line-height: 40px;
        text-transform: lowercase;
      }
      .point {
        color: rgba(255, 255, 255, 0.5);
        font-family: PingFang SC;
        font-size: 14px;
        text-transform: lowercase;
        margin-top: 10px;
        margin-left: 4px;
      }
    }
    .text-score {
      .popLine {
        margin: 0 24px;
        width: auto;
        height: 1px;
        display: flex;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 0.8vh;
        .lin {
          width: 0.8vw;
          color: rgba(255, 255, 255, 0.7);
        }
        .tran {
          width: 15.8vw;
          height: 1px;
          border-top: 1px solid rgba(255, 255, 255, 0.4);
          margin-top: 1.1vh;
          margin-right: 0.5vh;
        }
      }
      .score-box {
        margin: 24px auto 0;
        width: 17vw;
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(3, 33.3%);
        .norm-healthy {
          color: rgba(255, 255, 255, 0.7);
          font-family: PingFang SC;
          font-size: 14px;
          line-height: 24px;
        }
        .norm-grade {
          color: rgba(255, 255, 255, 0.9);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 24px;
          text-transform: lowercase;
          .local-score {
            color: rgba(255, 255, 255, 0.9);
          }
          span {
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
          }
        }
      }
    }
  }

  /deep/ .colorOver90 {
    color: rgb(51, 204, 241);
    text-align: center;
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 1.7vh;
  }
  /deep/ .colorOver80 {
    color: rgb(124, 234, 189);
    text-align: center;
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 1.7vh;
  }
  /deep/ .colorOther {
    color: rgb(213, 56, 38);
    text-align: center;
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 1.7vh;
  }
  /deep/ .borderLeftBlue {
    border-left: 1px solid rgba(26, 177, 214, 1);
  }
  /deep/ .borderLeftGreen {
    border-left: 1px solid rgba(124, 234, 189, 1);
  }
  /deep/ .borderLeftYellow {
    border-left: 1px solid rgba(251, 252, 84, 1);
  }
  /deep/ .borderLeftOrange {
    border-left: 1px solid rgba(239, 138, 52, 1);
  }
  /deep/ .borderLeftRed {
    border-left: 1px solid rgba(213, 56, 38, 1);
  }

  .local-score {
    display: inline-block;
    font-size: 20px;
  }
</style>
