<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="boxLeft">
    <!-- 单一对象信息左侧信息列表 -->
    <div class="object-left-top">
      <MyTitle :title="objName + '简介'" />
      <div class="obj-desc-uni">
        <div class="obj-desc" v-html="objDesc"></div>
      </div>
    </div>
    <div class="object-left-center">
      <div @click="getALLdesc(1)">
        <MyTitle title="河安湖晏" :score="B1Score" />
      </div>
      <div class="public">
        <RadarChart :radar-data="radarData" />
      </div>
    </div>
    <div class="object-left-bottom">
      <div @click="getALLdesc(2)">
        <MyTitle title="水清岸绿" :score="B2Score" />
      </div>
      <div class="zxtjChartA">
        <BingTuChart :funnel-data="funnelData" />
      </div>
    </div>
    <!-- 河流 -->
    <el-dialog
      append-to-body
      :visible.sync="riverHAHYFlag"
      custom-class="test-dialog"
      title="河安湖晏"
      width="60%"
      top="100px"
    >
      <page-riverhahy v-if="riverHAHYFlag" :river-hahy="riverHAHY" :obj-info="objInfo" />
    </el-dialog>

    <el-dialog
      append-to-body
      :visible.sync="riverSQALFlag"
      custom-class="test-dialog"
      title="水清岸绿"
      width="60%"
      top="100px"
    >
      <page-riversqal v-if="riverSQALFlag" :river-sqal="riverSQAL" :obj-info="objInfo" />
    </el-dialog>

    <!-- 湖库 -->
    <el-dialog
      append-to-body
      :visible.sync="lakeHAHYFlag"
      custom-class="test-dialog"
      title="河安湖晏"
      width="60%"
      top="100px"
    >
      <page-lakehahy v-if="lakeHAHYFlag" :lake-hahy="lakeHAHY" :obj-info="objInfo" />
    </el-dialog>

    <el-dialog
      append-to-body
      :visible.sync="lakeSQALFlag"
      custom-class="test-dialog"
      title="水清岸绿"
      width="60%"
      top="100px"
    >
      <page-lakesqal v-if="lakeSQALFlag" :lake-sqal="lakeSQAL" :obj-info="objInfo" />
    </el-dialog>
  </div>
</template>
<script>
  import RadarChart from '../mapChart/radarChart.vue'
  import BingTuChart from '../mapChart/bingTuChart.vue'

  import PageRiverhahy from '../objPage/hahyRiver.vue'
  import PageLakehahy from '../objPage/hahyLake.vue'
  import PageRiversqal from '../objPage/sqalRiver.vue'
  import PageLakesqal from '../objPage/sqalLake.vue'

  export default {
    name: '',
    components: {
      RadarChart,
      BingTuChart,
      PageRiverhahy,
      PageRiversqal,
      PageLakehahy,
      PageLakesqal
    },
    props: {
      rlInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        publicSatisfactionScore: null,
        publicSatisfaction: [],
        qusPer: null,

        objName: null,
        objDesc: null,

        rlChartData: [], // 单一河湖对象图标数据
        B1Score: null, // B1 层级分数 河安湖晏
        radarData: [], // 雷达图数据 河安湖晏

        funnelData: [], // 水清岸绿 数据
        B2Score: null, // 水清岸绿 分数

        targetDInfoData: [],

        riverHAHY: null, // 河安湖晏数据
        riverHAHYFlag: false, // 河安湖晏展示控制器
        riverSQAL: null, // 水清岸绿数据
        riverSQALFlag: false, // 水清岸绿展示控制器
        lakeHAHY: null, // 河安湖晏数据(湖库)
        lakeHAHYFlag: false, // 河安湖晏展示控制器(湖库)
        lakeSQAL: null, // 水清岸绿数据(湖库)
        lakeSQALFlag: false // 水清岸绿展示控制器(湖库)
      }
    },
    computed: {},
    watch: {
      rlInfo: {
        immediate: true,
        handler(val) {
          if (JSON.stringify(val) != '{}') {
            this.loadData(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData(data) {
        let choosenRlInfo = data
        let scheme = choosenRlInfo
        if (choosenRlInfo['target']) {
          let scheme = choosenRlInfo.target
          this.objInfo = choosenRlInfo

          if (scheme.children.length > 0) {
            for (let i = 0; i < scheme.children.length; i++) {
              if (scheme.children[i].code == 'B1') {
                this.radarDataDo(scheme.children[i])
              }
              if (scheme.children[i].code == 'B2') {
                this.shuiQingDataDo(scheme.children[i])
              }
            }
          }
        }
        this.objName = choosenRlInfo.obj.name
        this.objDesc = `${choosenRlInfo.obj.comments}`
      },

      /** 处理单个河湖对象雷达图表数据 */
      radarDataDo(info) {
        this.B1Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.radarData = []
        this.childData(info)
        this.radarData = this.rlChartData
      },
      /**
       * @description 水清岸绿指标数据处理
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      shuiQingDataDo(info) {
        this.B2Score = info.scoreVal.toFixed(1)
        this.rlChartData = []
        this.funnelData = []
        this.childData(info)
        this.funnelData = this.rlChartData
      },
      /** 获取指标层信息数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              let param = {
                name: item.name,
                value: item.scoreVal.toFixed(1)
              }
              this.rlChartData.push(param)
              this.targetDInfoData.push(item)
            }
          })
        }
      },
      /** 获取四大类详细指标 */
      getALLdesc(info) {
        console.log(this.rlInfo)
        if (
          this.rlInfo.rlType.includes('HeDao') ||
          this.rlInfo.rlType.includes('CunLuo') ||
          this.rlInfo.rlType.includes('YeWai')
        ) {
          if (info == 1) {
            for (let i = 0; i < this.rlInfo.target.children.length; i++) {
              if (this.rlInfo.target.children[i].code == 'B1') {
                this.riverHAHY = this.rlInfo.target.children[i]
              }
            }
            this.riverHAHYFlag = true
          } else if (info == 2) {
            for (let i = 0; i < this.rlInfo.target.children.length; i++) {
              if (this.rlInfo.target.children[i].code == 'B2') {
                console.log(this.rlInfo.target.children[i])
                this.riverSQAL = this.rlInfo.target.children[i]
              }
            }
            this.riverSQALFlag = true
          }
        } else {
          if (info == 1) {
            for (let i = 0; i < this.rlInfo.target.children.length; i++) {
              if (this.rlInfo.target.children[i].code == 'B1') {
                this.lakeHAHY = this.rlInfo.target.children[i]
              }
            }
            this.lakeHAHYFlag = true
          } else if (info == 2) {
            for (let i = 0; i < this.rlInfo.target.children.length; i++) {
              if (this.rlInfo.target.children[i].code == 'B2') {
                this.lakeSQAL = this.rlInfo.target.children[i]
              }
            }
            this.lakeSQALFlag = true
          }
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '../../../assets/css/elui.less';

  #boxLeft {
    position: absolute;
    width: 20.8vw;
    left: 24px;
    top: 100px;
    box-sizing: border-box;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    transition-property: left;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    z-index: 100;
  }
  .object-left-top {
    width: 19.2vw;
    height: 24.1vh;
    overflow: auto;
    margin: 1.5vh 0.8vw 1.5vh 0.8vw;
    position: relative;
    .obj-desc-uni {
      text-indent: 2rem;
      color: rgba(255, 255, 255, 0.9);
      height: 24vh;
      font-size: 1.5vh;
      line-height: 200%;
      letter-spacing: 0.9px;
      margin: 1.5vh 0 0.7vh 0;
      overflow-y: auto;
      text-align: justify;
      .obj-desc {
        -webkit-animation: 10s rowup linear infinite normal;
        animation: 10s rowup linear infinite normal;
        span {
          font-family: PingFang SC;
          color: rgba(60, 212, 149, 1);
        }
      }
    }
  }
  .object-left-center {
    width: 19.2vw;
    height: 28.1vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;
    .public {
      width: 19.2vw;
      height: 95%;
      .totalScore {
        position: absolute;
        left: 0;
        top: 5.9vh;
        margin-left: 7vw;
        color: rgba(60, 212, 149, 1);
        font-family: PingFang SC;
        font-size: 1.7vh;
      }
    }
  }
  .object-left-bottom {
    width: 19.2vw;
    height: 28.1vh;
    margin: 0 0.8vw 1.5vh 0.8vw;
    position: relative;
    .zxtjChartA {
      width: 19.2vw;
      height: 88%;
    }
  }
</style>
