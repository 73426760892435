<template>
  <div id="centerLeft1">
    <PieBingTu :options="options"></PieBingTu>
  </div>
</template>

<script>
  import PieBingTu from '@/components/echart/pieBingTu.vue'
  export default {
    components: {
      PieBingTu
    },
    props: {
      funnelData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        options: {}
      }
    },
    watch: {
      funnelData: {
        handler: function (val, oldVal) {
          //   this.createOptions(val)
          console.log(this.funnelData)
        },
        deep: true
      }
    },
    created() {
      let dataArr = []
      this.options = {
        title: {
          text: '水清岸绿',
          left: 'center',
          top: '35%',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)',
            fontSize: 18,
            fontWeight: 600
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          textStyle: {
            fontSize: 12,
            color: 'rgba(255, 255, 255, 0.9)'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '50%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              borderColor: '#000',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '40',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false
            },
            data: this.funnelData
          }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
  }
</style>
