// 苏州标准--湖泊对象管理水平维度指标数据展示页面
<template>
  <div class="page-body">
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            管理机制
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ gljz.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(gljz) !== '{}'" style="display: flex">
          <div style="height: 250px; width: 100%; overflow: hidden">
            <pic-show :pic-info="gljz.data" :my-height="'250'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            管护组织能力
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ ghzznl.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(ghzznl) !== '{}'" style="display: flex">
          <div style="height: 250px; width: 100%; overflow: hidden">
            <pic-show :pic-info="ghzznl.data" :my-height="'250'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="map-container">
      <div
        class="items-1"
        style="
          width: 100%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          overflow-y: auto;
          position: relative;
        "
      >
        <Map :rl-data="objInfo.obj" />
        <!-- <div class="pic-container">
          <el-carousel :interval="2000" type="card" height="250px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="420px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
      </div>
    </div>
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            监测预警能力
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ jcyjnl.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(jcyjnl) !== '{}'" style="display: flex">
          <div style="height: 250px; width: 100%; overflow: hidden">
            <pic-show :pic-info="jcyjnl.data" :my-height="'250'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            管理智慧化水平
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ glzhhsp.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(glzhhsp) !== '{}'" style="display: flex">
          <div style="height: 250px; width: 100%; overflow: hidden">
            <pic-show :pic-info="glzhhsp.data" :my-height="'250'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Map from '../map/objMap.vue'

  export default {
    components: {
      Map
    },
    props: {
      riverCzja: {
        type: Object,
        default: () => {}
      },
      objInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        targetDInfoData: [],

        gljz: {}, // 管理机制
        ghzznl: {}, // 管护组织能力

        jcyjnl: {}, // 监测预警能力
        glzhhsp: {} // 管理智慧化水平
      }
    },
    created() {
      this.childData(this.riverCzja)
      console.log(this.targetDInfoData)
      this.targetDInfoData.forEach((item) => {
        if (item.code == 'C32') {
          // 管理机制
          this.gljz.score = item.scoreVal.toFixed(1)
          this.gljz.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.gljz.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C33') {
          // 管护组织能力
          this.ghzznl.score = item.scoreVal.toFixed(1)
          this.ghzznl.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.ghzznl.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C34') {
          //   监测预警能力
          this.jcyjnl.score = item.scoreVal.toFixed(1)
          this.jcyjnl.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.jcyjnl.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C35') {
          // 管理智慧化水平
          this.glzhhsp.score = item.scoreVal.toFixed(1)
          this.glzhhsp.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.glzhhsp.data.push(param)
                })
              }
            })
          }
        }
      })
    },
    methods: {
      /** 遍历D层指标数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              this.targetDInfoData.push(item)
            }
          })
        } else {
          this.targetDInfoData.push(info)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .page-body {
    // height: 50vh;
    //   max-height: 90vh;
    overflow: hidden;
    display: flex;
    .target-chart {
      width: 24%;
      display: grid;
      grid-template-rows: repeat(2, 50%);
    }
    .map-container {
      width: 52%;
      display: grid;
      //   grid-template-columns: repeat(2, 50%);
      //   grid-template-rows: repeat(3, 33.3%);
      //   .items-1 {
      //     grid-column-start: 1;
      //     grid-column-end: 4;
      //     grid-row-start: 1;
      //     grid-row-end: 3;
      //   }
    }
  }
  .target-title {
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.9);
    .target-title-icon {
      width: 10px;
      height: 10px;
      background-color: rgba(60, 212, 149, 0.9);
      border-radius: 50%;
      // <!--设置圆点与其后面的内容水平显示-->
      display: inline-block;
      margin-right: 5px;
    }
    .target-title-name {
      font-size: 1.7vh;
      margin-left: 20px;
    }
    .target-title-score {
      vertical-align: middle;
      margin-right: 20px;
      span {
        color: rgb(60, 212, 149);
        font-size: 20px;
      }
    }
  }
  .target-chart-lable {
    .target-chart-lable-p1 {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.4vh;
      span {
        color: rgb(60, 212, 149);
        font-size: 2.8vh;
      }
    }
    .target-chart-lable-p1 {
      color: rgb(255, 255, 255);
      font-size: 1.5vh;
    }
  }

  .hyaq {
    width: 80px;
    height: 90px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background: linear-gradient(90.3deg, rgba(60, 212, 149, 0.1) 0.19%, rgba(60, 212, 149, 0) 97.57%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
</style>
