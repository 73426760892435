<template>
  <!-- 湖滨带 -->
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  export default {
    components: {
      Pie
    },
    props: {
      lakesideVegetationInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      var a = this.lakesideVegetationInfo.total
      var e = this.lakesideVegetationInfo.haveVegetationArea
      this.options = {
        title: [
          {
            text: ((e / a) * 100).toFixed(2) + '%',
            x: 'center',
            top: 'center',
            textStyle: {
              fontSize: '20',
              color: '#1DF7FF',
              fontFamily: 'Source Han Sans CN',
              foontWeight: '500'
            }
          }
        ],
        polar: {
          radius: ['95%', '100%'],
          center: ['50%', '50%']
        },
        angleAxis: {
          max: 100,
          show: false
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            // 渐变圈
            name: '',
            type: 'bar',
            roundCap: false,
            barWidth: 10,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(60, 212, 255,0.1)'
            },
            data: [(e / a) * 100],
            coordinateSystem: 'polar',

            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: 'rgb(60, 212, 149)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(3, 37, 23)'
                  }
                ])
              }
            }
          },
          {
            // 亮线
            name: '',
            z: 5,
            type: 'pie',
            cursor: 'default',
            radius: ['70%', '70%'],
            hoverAnimation: false,
            legendHoverLink: false,
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: 1,
                itemStyle: {
                  normal: {
                    borderWidth: 2,
                    borderColor: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgb(60, 212, 149)'
                        },
                        {
                          offset: 0.6,
                          color: 'rgba(2,144,214,0)'
                        }
                      ],
                      globalCoord: false
                    }
                  }
                }
              }
            ]
          },
          {
            //内圆
            type: 'pie',
            radius: '84%',
            center: ['50%', '50%'],
            z: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  0.8,
                  [
                    {
                      offset: 0,
                      color: 'rgb(3, 31, 19)'
                    },
                    {
                      offset: 1,
                      color: 'rgb(60, 212, 149)'
                    }
                  ],
                  false
                ),
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            label: {
              show: false
            },
            tooltip: {
              show: false
            },
            data: [e]
          }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
    //   .dv-dec-1 {
    //     position: relative;
    //     width: 100px;
    //     height: 20px;
    //     top: -3px;
    //   }
    //   .chart-box {
    //     margin-top: 16px;
    //     width: 170px;
    //     height: 170px;
    //     .active-ring-name {
    //       padding-top: 10px;
    //     }
    //   }
  }
</style>
