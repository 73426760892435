// 苏州标准--河流对象生态健康维度指标数据展示页面
<template>
  <div class="page-body">
    <div style="height: 55%; display: flex">
      <div style="width: 24%; display: grid; height: 100%">
        <div style="width: 100%" :class="{ isActive: showTarget == 'D1' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              岸坡稳定性
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ BankSlopeStabilityScore }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 200px; width: 100%; padding-top: 20px">
              <el-carousel :interval="4000" indicator-position="outside" arrow="always" height="200px">
                <el-carousel-item v-for="(item, index) in BankSlopeStabilityUrlList" :key="index">
                  <!-- <img :src="item" alt width="80%" height="auto" class="img-screen" /> -->
                  <el-image :src="item" :preview-src-list="BankSlopeStabilityUrlList" width="80%"
                    height="auto"></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D2' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              岸坡植被覆盖度
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ lakesideVegetation.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 100px; width: 150px; margin-left: 50px; padding-top: 20px">
              <LakesideVegetation :lakeside-vegetation-info="lakesideVegetation.data" />
            </div>
            <div style="height: 100px">
              <div style="margin-top: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{
                    lakesideVegetation.data.haveVegetationArea
                  }}</span>m<sup>2</sup>
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">植被覆盖面积</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="
          width: 52%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          overflow-y: auto;
          position: relative;
        ">
        <Map :rl-data="objInfo.obj" />
        <div class="pic-container">
          <!-- <el-carousel :interval="2000" type="card" height="180px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="320px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel> -->
          <MySwiper :url-list="picList" />
        </div>
      </div>
      <div style="width: 24%; display: grid">
        <div style="width: 100%" :class="{ isActive: showTarget == 'D3' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              河流流动性
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ riverFluidity.score }}</span>
            </div>
          </div>
          <div style="text-align: center">
            <div style="height: 150px; width: 250px; padding-top: 20px; margin: 0 auto">
              <LakeWaterExchange :river-fluidity-info="riverFluidity.data" />
            </div>
            <div style="height: 50px">
              <div style="margin-top: -25px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ riverFluidity.data }}</span>m/s
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">平均流速</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D4' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              生态水位满足率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ ecologicalWaterLevelSatisfaction.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 100px; width: 150px; margin-left: 50px; padding-top: 20px">
              <EcologicalWaterLevelSatisfaction
                :ecological-water-level-satisfaction-info="ecologicalWaterLevelSatisfaction.data" />
            </div>
            <div style="height: 100px">
              <div style="margin-top: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{
                    ecologicalWaterLevelSatisfaction.data
                  }}</span>天
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">满足目标水位</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 45%; width: 100%; overflow: auto">
      <Szhi :target-data="waterQuality" :score="szScore" />
      <Dqsw :target-data="benthos" :score="dxScore" />
      <Fyou :target-data="fyData" :score="fyScore" />
      <Zszl :target-data="zszlData" :score="zsScore" />
    </div>
  </div>
</template>
<script>
import LakesideVegetation from './pageChart/LakesideVegetation.vue'
import LakeWaterExchange from './pageChart/LakeWaterExchange.vue'
import EcologicalWaterLevelSatisfaction from './pageChart/EcologicalWaterLevelSatisfaction.vue'

import Dqsw from './targetTable/dqsw.vue'
import Szhi from './targetTable/shuizhi.vue'
import Fyou from './targetTable/fuyou.vue'
import Zszl from './targetTable/zszl.vue'

import Map from '../map/objMap.vue'

const log = console.log
// const extent = [120.3569667238902, 30.757833911398727, 120.89901672389161, 31.219523911397924]

export default {
  components: {
    LakesideVegetation,
    LakeWaterExchange,
    EcologicalWaterLevelSatisfaction,
    Szhi,
    Dqsw,
    Fyou,
    Zszl,
    Map
  },
  props: {
    riverEcology: {
      type: Object,
      default: () => { }
    },
    fitObj: {
      type: Object,
      default: () => { }
    },
    waterPic: {
      type: String,
      default: ''
    },
    objInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      data1: [],
      targetDInfoData: [],
      BankSlopeStabilityUrlList: [], // 岸坡稳定性图片列表
      BankSlopeStabilityScore: null,
      lakesideVegetation: {}, // 岸坡植被覆盖度
      riverFluidity: {}, // 河流流动性
      ecologicalWaterLevelSatisfaction: {}, // 生态水位满足率
      waterQuality: [], //水质
      benthos: {}, // 底栖生物

      // map:null,
      waterSystemUrl: this.waterPic,
      zsScore: null,
      szScore: null,
      dnScore: null,
      dxScore: null,
      fyScore: null,

      fyData: [],
      zsData: [],
      szData: [],
      zszlData: {},

      showTarget: '',

      picList: []
    }
  },
  created() {
    this.childData(this.riverEcology)
    console.log(this.targetDInfoData)
    this.picList = []
    this.targetDInfoData.forEach((item) => {
      if (item.targetEntity.queryExt) {
        for (let key in item.targetEntity.queryExt) {
          if (key.length == 32) {
            this.picList.push(item.targetEntity.queryExt[key])
          }
        }
      }
      if (item.code == 'D1') {
        this.BankSlopeStabilityScore = item.scoreVal.toFixed(1)
        this.BankSlopeStabilityUrlList = item.targetEntity?.queryExt?.pngUrl
      } else if (item.code == 'D2') {
        let totalArea = 0
        let lakesideVegetationArea = 0
        item.targetEntity?.detailList.forEach((par) => {
          totalArea += parseInt((par.length || 0) * (par.width || 0))
          lakesideVegetationArea += parseInt(((par.length || 0) * (par.width || 0) * (par.percentage || 0)) / 100)
        })
        let param = {
          score: item.scoreVal.toFixed(1),
          data: {
            total: totalArea,
            haveVegetationArea: lakesideVegetationArea
          }
        }
        this.lakesideVegetation = param
      } else if (item.code == 'D3') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: item.targetEntity.speed
        }
        this.riverFluidity = param
      } else if (item.code == 'D4') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: item.targetEntity.waterLevelOkDays
        }
        this.ecologicalWaterLevelSatisfaction = param
      } else if (item.code == 'D5') {
        this.szScore = item.scoreVal.toFixed(1)
        this.waterQuality = item.targetEntity?.detailList
      } else if (item.code == 'D6') {
        // todo 底泥污染状况页面内容展示
        this.dnScore = item.scoreVal.toFixed(1)
      } else if (item.code == 'D7') {
        this.dxScore = item.scoreVal.toFixed(1)
        this.benthos = item.targetEntity
      } else if (item.code == 'D8') {
        // todo 浮游植物生物指数页面展示
        this.fyScore = item.scoreVal.toFixed(1)
        this.fyData = item.targetEntity?.detailList
      } else if (item.code == 'D9') {
        this.zsScore = item.scoreVal.toFixed(1)
        this.zsData = item.targetEntity?.detailList
        console.log(item, 'zs')
      }
    })
  },
  mounted() { },
  methods: {
    childData(info) {
      if (info['children']) {
        info.children.forEach((item) => {
          if (item['children']) {
            this.childData(item)
          } else {
            this.targetDInfoData.push(item)
          }
        })
      } else {
        this.targetDInfoData.push(info)
      }
    },
    tableData(info) {
      let arr = []
      for (let i = 1; i < info[0].length; i++) {
        let name = info[0][i].val
        let value
        if (info[1][i].val.indexOf('.') != -1) {
          value = info[1][i].val.substr(0, info[1][i].val.indexOf('.') + 3)
        } else {
          value = info[1][i].val
        }

        arr.push({ name, value })
      }
      return arr
    },
    changeTarget(val) {
      this.showTarget = this.picList[val].code
    }
  }
}
</script>
<style lang="less" scoped>
.page-body {
  height: 89vh;
  max-height: 90vh;
  overflow: hidden;
  background: rgba(20, 31, 41, 0.3);
}

.target-title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.9);

  .target-title-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(60, 212, 149, 0.9);
    border-radius: 50%;
    // <!--设置圆点与其后面的内容水平显示-->
    display: inline-block;
    margin-right: 5px;
  }

  .target-title-name {
    font-size: 1.7vh;
    margin-left: 20px;
  }

  .target-title-score {
    vertical-align: middle;
    margin-right: 20px;

    span {
      color: rgb(60, 212, 149);
      font-size: 20px;
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
  /*滚动条宽度*/
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  /*滑动条表面*/
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  /*滑动条边框*/
  border-radius: 2px;
  /*滑动条圆角宽度*/
}

.img-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/deep/ .el-table,
.el-table__expanded-cell {
  background-color: transparent;
}

/deep/ .el-table th {
  background-color: transparent !important;
  font-size: 20px;
  color: #fefefe;
}

/deep/ .el-table tr {
  background-color: transparent !important;
  color: #fefefe;
}

/deep/ .el-table tr.hover {
  background-color: transparent !important;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
/deep/.el-table .cell {
  background-color: transparent;
}

/deep/ .el-table .el-table__body tr.hover-row>td {
  background-color: transparent !important;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: transparent !important;
}

/deep/ .el-table--border th.el-table__cell,
.el-table__fixed-right-patch {
  border: none;
}

/deep/ .el-table--border .el-table__cell,
.el-table td.el-table__cell {
  border: none;
}

.isActive {
  background-color: rgba(60, 212, 149, 0.1);
  margin: 2px 0;
}

.pic-container {
  position: absolute;
  bottom: 2px;
  left: 5%;
  width: 90%;
  margin: 0 auto;
}</style>
