<template>
  <div class="benthic-page">
    <el-table
      :data="totalData"
      style="width: 100%; font-size: 12px; text-align: center"
      :border="true"
      header-cell-class-name="table-header-cls"
    >
      <el-table-column :label="'底栖生物指数  ' + score + ' 分'" align="center" header-align="center">
        <el-table-column prop="takeDate" label="时间" align="center" header-align="center"> </el-table-column>
        <el-table-column prop="xianChongNum" label="线虫动物(个/m³)" align="center" header-align="center">
        </el-table-column>
        <el-table-column prop="haunJieNum" label="环节动物门(个/m³)" align="center" header-align="center">
        </el-table-column>
        <el-table-column prop="ruanTiNum" label="软体动物门(个/m³)" align="center" header-align="center">
        </el-table-column>
        <el-table-column prop="jieZhiNum" label="节支动物门(个/m³)" align="center" header-align="center">
        </el-table-column>
        <el-table-column prop="biomassNum" label="生物量(个/m³)" align="center" header-align="center">
        </el-table-column>
        <el-table-column prop="biomassWeight" label="生物量(g/m³)" align="center" header-align="center">
        </el-table-column>
        <el-table-column prop="swValue" label="SW" align="center" header-align="center"> </el-table-column>
        <el-table-column prop="oiValue" label="OI" align="center" header-align="center"> </el-table-column>
        <el-table-column prop="benthic" label="Rz" align="center" header-align="center"> </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { Site } from '@/api/scheme/index.js'
  export default {
    // 底栖生物指数
    name: 'Benthic',
    components: {},
    props: {
      targetData: {
        type: Object,
        default: () => {}
      },
      score: {
        type: [Number, String],
        default: null
      }
    },
    data() {
      return {
        flag: true,
        activeName: 'first',

        // 计算过程单次采样数据（总）
        data: [],
        // 统计结果数据
        totalData: [],
        // 年度平均得分
        finalScore: 0,

        // 站点表格数据
        siteList: [],

        id: null,
        queryExt: {},
        attachs: '',

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}' && val != undefined) {
            this.getSiteList(val, val.schemeId)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        data.detailList.forEach((detail) => {
          this.siteList.forEach((site) => {
            const i = detail.detailList.findIndex((i) => {
              return i.siteId == site.siteId
            })
            if (i == -1) {
              detail.detailList.push(site)
            }
          })
        })
        this.data = data.detailList

        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
        this.alculateResults()
      },
      /**
       * @description  获取对象站点信息
       * @author hu_ty
       * @since
       * @param {number} val 对象id
       *
       */
      getSiteList(val, i) {
        let id
        if (i && i != 'undefined') {
          id = i
        }
        let param = {
          schemeId: id,
          pageSize: 100
        }
        Site.getList(param).then((res) => {
          let siteInfo = res.data.data.list
          let arr = siteInfo.filter((site) => site.siteType === 'Normal')
          sessionStorage.setItem('siteInfo', JSON.stringify(arr))
          this.createData()
          this.loadData(val)
        })
      },
      /**
       * @description  创建新增的表单数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      createData() {
        this.siteInfo = JSON.parse(sessionStorage.siteInfo)
        this.siteList = []
        this.siteInfo.forEach((item) => {
          let param = {
            site: item.name,
            siteId: item.id,
            xianChongNum: null,
            haunJieNum: null,
            ruanTiNum: null,
            jieZhiNum: null,
            biomassNum: null,
            biomassWeight: null
          }
          this.siteList.push(param)
        })
      },

      /**
       * @description 站点信息格式化
       * @author hu_ty
       * @since
       * @param {number} id 站点id
       *
       */
      formmatter(id) {
        const site = this.siteList.find((i) => {
          return i.siteId == id
        })
        return site?.site
      },
      /**
       * @description  统计结果计算
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      alculateResults() {
        this.totalData = []
        this.data.forEach((item) => {
          let xianChongNum = 0
          let haunJieNum = 0
          let ruanTiNum = 0
          let jieZhiNum = 0
          let biomassNum = 0
          let biomassWeight = 0
          let benthic = (0.7 * (item.swValue ? item.swValue : 0) + 0.3 * (item.oiValue ? item.oiValue : 0)).toFixed(1)
          let length = item.detailList.length
          item.detailList.forEach((site) => {
            xianChongNum += parseInt(site.xianChongNum ? site.xianChongNum : 0)
            haunJieNum += parseInt(site.haunJieNum ? site.haunJieNum : 0)
            ruanTiNum += parseInt(site.ruanTiNum ? site.ruanTiNum : 0)
            jieZhiNum += parseInt(site.jieZhiNum ? site.jieZhiNum : 0)
            biomassNum += parseInt(site.biomassNum ? site.biomassNum : 0)
            biomassWeight += parseInt(site.biomassWeight ? site.biomassWeight : 0)
          })
          let param = {
            xianChongNum: Math.round(xianChongNum / length),
            haunJieNum: Math.round(haunJieNum / length),
            ruanTiNum: Math.round(ruanTiNum / length),
            jieZhiNum: Math.round(jieZhiNum / length),
            biomassNum: Math.round(biomassNum / length),
            biomassWeight: Math.round(biomassWeight / length),
            takeDate: item.takeDate,
            swValue: item.swValue,
            oiValue: item.oiValue,
            benthic: benthic
          }

          const i = this.totalData.findIndex((i) => {
            return i.takeDate === item.takeDate
          })
          if (i > -1) {
            this.totalData.splice(i, 1)
          }
          this.totalData.push(param)
        })
        this.alculateScore()
      },
      /**
       * @description 计算全年得分
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      alculateScore() {
        let score = 0
        let length = this.totalData.length
        this.totalData.forEach((item) => {
          let swValue = item.swValue
          let oliochaeteTotal = item.oiValue
          let varietyScore
          let oliochaeteScore
          switch (true) {
            case swValue >= 4:
              varietyScore = 100
              break
            case swValue >= 3:
              varietyScore = 80 + ((swValue - 3) / 1) * 10
              break
            case swValue >= 2:
              varietyScore = 60 + ((swValue - 2) / 1) * 20
              break
            case swValue >= 1:
              varietyScore = 40 + ((swValue - 1) / 1) * 20
              break
            case swValue >= 0:
              varietyScore = (swValue / 1) * 40
              break
            default:
          }
          switch (true) {
            case oliochaeteTotal < 0.4:
              oliochaeteScore = 100 - (oliochaeteTotal / 0.4) * 10
              break
            case oliochaeteTotal < 0.6:
              oliochaeteScore = 90 - ((oliochaeteTotal - 0.4) / 0.2) * 10
              break
            case oliochaeteTotal < 0.7:
              oliochaeteScore = 80 - ((oliochaeteTotal - 0.6) / 0.1) * 20
              break
            case oliochaeteTotal < 0.8:
              oliochaeteScore = 60 - ((oliochaeteTotal - 0.7) / 0.1) * 20
              break
            case oliochaeteTotal <= 1:
              oliochaeteScore = 40 - ((oliochaeteTotal - 0.8) / 0.2) * 40
              break
            case oliochaeteTotal > 1:
              oliochaeteScore = 0
              break
            default:
          }
          score += varietyScore * 0.7 + oliochaeteScore * 0.3
        })
        this.finalScore = score / length

        // let varietyTotal = 0
        // let oliochaeteTotal = 0
        // let swValue = 0
        // let oiValue = 0
        // let length = this.totalData.length
        // this.totalData.forEach((item) => {
        //   varietyTotal += parseInt(item.swValue ? item.swValue : 0)
        //   oliochaeteTotal += parseInt(item.oiValue ? item.oiValue : 0)
        // })
        // swValue = Math.round(varietyTotal / length)
        // oliochaeteTotal = Math.round(oliochaeteTotal / length)
        // console.log('<==  ==>', swValue, oliochaeteTotal)
        // let varietyScore
        // let oliochaeteScore
        // switch (true) {
        //   case swValue >= 4:
        //     varietyScore = 100
        //     break
        //   case swValue >= 3:
        //     varietyScore = 80 + ((swValue - 3) / 1) * 10
        //     break
        //   case swValue >= 2:
        //     varietyScore = 60 + ((swValue - 2) / 1) * 20
        //     break
        //   case swValue >= 1:
        //     varietyScore = 40 + ((swValue - 1) / 1) * 20
        //     break
        //   case swValue >= 0:
        //     varietyScore = (swValue / 1) * 40
        //     break
        //   default:
        // }
        // switch (true) {
        //   case oliochaeteTotal < 0.4:
        //     oliochaeteScore = 100 - (oliochaeteTotal / 0.4) * 10
        //     break
        //   case oliochaeteTotal < 0.6:
        //     oliochaeteScore = 90 - ((oliochaeteTotal - 0.4) / 0.2) * 10
        //     break
        //   case oliochaeteTotal < 0.7:
        //     oliochaeteScore = 80 - ((oliochaeteTotal - 0.6) / 0.1) * 20
        //     break
        //   case oliochaeteTotal < 0.8:
        //     oliochaeteScore = 60 - ((oliochaeteTotal - 0.7) / 0.1) * 20
        //     break
        //   case oliochaeteTotal <= 1:
        //     oliochaeteScore = 40 - ((oliochaeteTotal - 0.8) / 0.2) * 40
        //     break
        //   case oliochaeteTotal > 1:
        //     oliochaeteScore = 0
        //     break
        //   default:
        // }
        // console.log('<==  ==>', varietyScore, oliochaeteScore)
        // this.finalScore = varietyScore * 0.7 + oliochaeteScore * 0.3
      }
    }
  }
</script>
<style lang="less" scoped>
  .benthic-page {
    .el-row {
      margin-bottom: 16px;
    }
  }
  .data-container {
    max-height: 600px;
    overflow: auto;
  }

  /deep/ .el-table__row input {
    border: 0;
  }
</style>
