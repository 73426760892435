// 苏州标准--河流对象干扰程度维度指标数据展示页面
<template>
  <div class="page-body">
    <div style="height: 100%; display: flex">
      <div style="width: 30%; display: grid; height: 100%">
        <div style="width: 100%" :class="{ isActive: showTarget == 'D16' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              岸线开发利用率
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ shorelineDevelopment.score }}</span>
            </div>
          </div>
          <div style="display: flex">
            <div style="height: 200px; width: 150px; margin-left: 50px; padding-top: 20px">
              <ShorelineDevelopment :shoreline-development-info="shorelineDevelopment.data" />
            </div>
            <div style="display: flex; flex-direction: column; justify-content: space-around">
              <div style="margin-left: 30px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ shorelineDevelopment.data.occupyLen }}
                  </span>
                  m
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">岸线长度</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" :class="{ isActive: showTarget == 'D17' ? true : false }">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              入河排污口规范建设
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ sewageOutlet.score }}</span>
            </div>
          </div>
          <div style="text-align: center">
            <div style="height: 200px; width: 300px; margin: 0 auto">
              <LakeInletAndOutlet :sewage-outlet-info="sewageOutlet.data" />
            </div>
            <div>
              <div style="margin-top: -55px">
                <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                  <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ sewageOutlet.data }}</span>处
                </p>
                <p style="color: rgb(255, 255, 255); font-size: 1.5vh">排污口规范化建设数量</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 70%; display: table-cell; text-align: center; vertical-align: middle; position: relative">
        <Map :rl-data="objInfo.obj" />
        <div class="pic-container">
          <!-- <el-carousel :interval="2000" type="card" height="250px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="420px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel> -->
          <MySwiper :url-list="picList" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LakeInletAndOutlet from './pageChart/LakeInletAndOutlet.vue'
import ShorelineDevelopment from './pageChart/shorelineDevelopment.vue'
import Map from '../map/objMap.vue'

export default {
  components: {
    LakeInletAndOutlet,
    ShorelineDevelopment,
    Map
  },
  props: {
    riverInterfere: {
      type: Object,
      default: () => { }
    },
    waterPic: {
      type: String,
      default: () => { }
    },
    objInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      riverInterfere1: this.riverInterfere,
      targetDInfoData: [],
      shorelineDevelopment: {}, // 岸线开发利用率
      sewageOutlet: {}, // 入湖排污口
      waterSystemUrl: this.waterPic,
      showTarget: '',

      picList: []
    }
  },
  created() {
    this.childData(this.riverInterfere)

    this.picList = []

    this.targetDInfoData.forEach((item) => {
      if (item.targetEntity.queryExt) {
        for (let key in item.targetEntity.queryExt) {
          if (key.length == 32) {
            this.picList.push(item.targetEntity.queryExt[key])
          }
        }
      }
      if (item.code == 'D16') {
        let totalLen = 0
        let occupyLen = 0
        item.targetEntity.detailList.forEach((par) => {
          totalLen += parseInt(par.length ? par.length : 0)
          occupyLen += parseInt(par.usedLength ? par.usedLength : 0)
        })
        let param = {
          score: item.scoreVal.toFixed(1),
          data: {
            total: item.targetEntity?.totalLength,
            occupyLen: item.targetEntity?.usedLength
          }
        }
        this.shorelineDevelopment = param
      } else if (item.code == 'D17') {
        let param = {
          score: item.scoreVal.toFixed(1),
          data: item.targetEntity.detailList.length
        }
        this.sewageOutlet = param
      }
    })
  },
  methods: {
    childData(info) {
      if (info['children']) {
        info.children.forEach((item) => {
          if (item['children']) {
            this.childData(item)
          } else {
            this.targetDInfoData.push(item)
          }
        })
      } else {
        this.targetDInfoData.push(info)
      }
    },
    changeTarget(val) {
      this.showTarget = this.picList[val].code
    }
  }
}
</script>
<style lang="less" scoped>
.page-body {
  height: 60vh;
  overflow: hidden;
}

.target-title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.9);

  .target-title-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(60, 212, 149, 0.9);
    border-radius: 50%;
    // <!--设置圆点与其后面的内容水平显示-->
    display: inline-block;
    margin-right: 5px;
  }

  .target-title-name {
    font-size: 1.7vh;
    margin-left: 20px;
  }

  .target-title-score {
    vertical-align: middle;
    margin-right: 20px;

    span {
      color: rgb(60, 212, 149);
      font-size: 20px;
    }
  }
}

.isActive {
  background-color: rgba(60, 212, 149, 0.1);
  margin: 2px 0;
}

.pic-container {
  position: absolute;
  bottom: 2px;
  left: 5%;
  width: 90%;
  margin: 0 auto;
}
</style>
