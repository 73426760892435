// 苏州标准--湖泊对象管理水平维度指标数据展示页面
<template>
  <div class="page-body">
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            水面清澈整洁
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ smqczj.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(smqczj) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="smqczj.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            岸坡干净整洁
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ apgjzj.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(apgjzj) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="apgjzj.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            岸带植被覆盖
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ adzbfg.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(adzbfg) !== '{}'" style="display: flex">
          <div style="height: 100px; width: 150px; padding-top: 20px">
            <LakesideVegetation :lakeside-vegetation-info="adzbfg.data" />
          </div>
          <div style="height: 100px">
            <div style="margin-top: 40px">
              <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
                <span style="color: rgb(60, 212, 149); font-size: 2.8vh">{{ adzbfg.data.haveVegetationArea }}</span
                >m<sup>2</sup>
              </p>
              <p style="color: rgb(255, 255, 255); font-size: 1.5vh">植被覆盖面积</p>
            </div>
          </div>
        </div>

        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="map-container">
      <div
        class="items-1"
        style="
          width: 100%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          overflow-y: auto;
          position: relative;
        "
      >
        <Map :rl-data="objInfo.obj" />
        <!-- <div class="pic-container">
          <el-carousel :interval="2000" type="card" height="250px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="420px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
      </div>

      <div class="items-2">
        <div style="margin-right: 8px">
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              湿地保护到位
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ sdbhdw.score }}</span>
            </div>
          </div>
          <div v-if="JSON.stringify(sdbhdw) !== '{}'" style="display: flex">
            <div style="height: 140px; width: 100%; overflow: hidden">
              <pic-show :pic-info="sdbhdw.data" :my-height="'150'" />
            </div>
          </div>
          <div v-else style="width: 100%">
            <none-target></none-target>
          </div>
        </div>
        <div>
          <div class="target-title">
            <div class="target-title-name">
              <div class="target-title-icon"></div>
              取排依法依规
            </div>
            <div class="target-title-score">
              得分:
              <span>{{ qpyfyg.score }}</span>
            </div>
          </div>
          <div v-if="JSON.stringify(qpyfyg) !== '{}'" style="padding: 30px 0 0 50px">
            <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              取水未许可/不规范：
              <span style="color: rgb(60, 212, 149); font-size: 24px">{{ qpyfyg.takeWater }}</span
              >处
            </div>
            <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              排水未许可/不规范：
              <span style="color: rgb(60, 212, 149); font-size: 24px">{{ qpyfyg.drainWater }}</span
              >处
            </div>
            <div style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              排水水质不达标：
              <span style="color: rgb(60, 212, 149); font-size: 24px">{{ qpyfyg.drainWaterQuality }}</span
              >处
            </div>
          </div>
          <div v-else style="width: 100%">
            <none-target></none-target>
          </div>
        </div>
      </div>
      <div class="items-3">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            水质优劣程度
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ szylcd.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(adzbfg) !== '{}'" style="display: flex; width: 100%">
          <Szhi :target-data="szylcd.waterQuality" />
        </div>

        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            入湖水质达标
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ rhwrkz.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(rhwrkz) !== '{}'" style="display: flex; align-items: center; justify-content: center">
          <div style="height: 100px; width: 150px; padding-top: 20px">
            <WaterQualityStandard :water-quality-standard-info="rhwrkz.data" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            空间管控有序
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ kjgkyx.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(kjgkyx) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="kjgkyx.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            岸线生态自然
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ axzrst.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(axzrst) !== '{}'" style="text-align: center">
          <div style="height: 100px; width: 300px; margin: 10px auto 0">
            <EcologicalBankSlope :lake-ecological-bank-slope-info="axzrst.data" />
          </div>
          <div style="margin-top: -25px">
            <p style="color: rgba(255, 255, 255, 0.5); font-size: 1.4vh">
              <span style="color: rgb(60, 212, 149); font-size: 2vh">{{ axzrst.data.len }}</span
              >m
            </p>
            <p style="color: rgb(255, 255, 255); font-size: 1.5vh">生态岸线长度</p>
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LakesideVegetation from './pageChart/LakesideVegetation.vue'
  import EcologicalBankSlope from './pageChart/ecologicalBankSlope.vue'
  import Map from '../map/objMap.vue'
  import WaterQualityStandard from './pageChart/WaterQualityStandard.vue'
  import Szhi from './targetTable/shuizhi.vue'

  export default {
    components: {
      LakesideVegetation,
      EcologicalBankSlope,
      WaterQualityStandard,
      Map,
      Szhi
    },
    props: {
      lakeSqal: {
        type: Object,
        default: () => {}
      },
      objInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        targetDInfoData: [],

        smqczj: {}, // 水面清澈整洁
        apgjzj: {}, // 岸坡干净整洁
        adzbfg: {}, // 岸带植被覆盖
        sdbhdw: {}, // 湿地保护到位
        qpyfyg: {}, // 取排依法依规
        kjgkyx: {}, // 空间管控有序
        rhwrkz: {}, // 入河污染控制
        axzrst: {}, // 岸线生态自然
        szylcd: {} // 水质优劣程度
      }
    },
    created() {
      console.log(this.lakeSqal)
      this.childData(this.lakeSqal)
      console.log(this.targetDInfoData)
      this.targetDInfoData.forEach((item) => {
        if (item.code == 'C8') {
          // 水面清澈整洁
          let arrList = []
          this.smqczj.score = item.scoreVal.toFixed(1)
          this.smqczj.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.smqjPngs && del.smqjPngs != '') {
                let arr = del.smqjPngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.smqjDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.smqczj.data.push(param)
                })
              }
              if (del.sttmPngs && del.sttmPngs != '') {
                let arr = del.sttmPngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sttmDesc
                    // status: del.irrigateStatus ? '运行良好' : '运行不佳'
                  }
                  this.smqczj.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C9') {
          // 岸坡干净整洁
          this.apgjzj.score = item.scoreVal.toFixed(1)
          this.apgjzj.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.inSlopePngs && del.inSlopePngs != '') {
                let arr = del.inSlopePngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.inSlopeDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.apgjzj.data.push(param)
                })
              }
              if (del.outSlopePngs && del.outSlopePngs != '') {
                let arr = del.outSlopePngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.outSlopeDesc
                    // status: del.irrigateStatus ? '运行良好' : '运行不佳'
                  }
                  this.apgjzj.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C10') {
          //   岸带植被覆盖
          let totalArea = 0
          let lakesideVegetationArea = 0
          item.targetEntity?.detailList.forEach((par) => {
            totalArea += par.length && par.width ? parseInt(par.length * par.width) : 0
            lakesideVegetationArea +=
              par.length && par.width && par.percentage ? parseInt((par.length * par.width * par.percentage) / 100) : 0
          })
          let param = {
            score: item.scoreVal.toFixed(1),
            data: {
              total: totalArea,
              haveVegetationArea: lakesideVegetationArea
            }
          }
          this.adzbfg = param
        } else if (item.code == 'C11') {
          // 水质优劣程度
          this.szylcd.waterQuality = item.targetEntity?.detailList
          this.szylcd.score = item.scoreVal.toFixed(1)
        } else if (item.code == 'C12') {
          // 取排依法依规
          let param = {
            score: item.scoreVal.toFixed(1),
            takeWater: item.targetEntity.takeWater,
            drainWater: item.targetEntity.drainWater,
            drainWaterQuality: item.targetEntity.drainWaterQuality
          }
          this.qpyfyg = param
        } else if (item.code == 'C13') {
          //   入湖水质达标
          this.rhwrkz.score = item.scoreVal.toFixed(1)
          this.rhwrkz.data = {
            total: 100,
            standardMeasurement: item.targetEntity.standardRate
          }
        } else if (item.code == 'C14') {
          //空间管控有序
          let arrList = []
          this.kjgkyx.score = item.scoreVal.toFixed(1)
          this.kjgkyx.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.jzPng && del.jzPng != '') {
                let arr = del.jzPng.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.jzDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.kjgkyx.data.push(param)
                })
              }
              if (del.slPng && del.slPng != '') {
                let arr = del.slPng.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.slDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.kjgkyx.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C15') {
          //湿地保护到位
          this.sdbhdw.score = item.scoreVal.toFixed(1)
          this.sdbhdw.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar
                    // desc: del.jzDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.sdbhdw.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C16') {
          // 岸线自然生态
          let param = {
            score: item.scoreVal.toFixed(1),
            data: {
              totalLength: item.targetEntity.totalLength ? item.targetEntity.totalLength : 0,
              len: item.targetEntity.protectLength ? item.targetEntity.protectLength : 0
            }
          }
          this.axzrst = param
        }
      })
    },
    methods: {
      /** 遍历D层指标数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              this.targetDInfoData.push(item)
            }
          })
        } else {
          this.targetDInfoData.push(info)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .page-body {
    height: 60vh;
    //   max-height: 90vh;
    overflow: hidden;
    display: flex;
    .target-chart {
      width: 24%;
      display: grid;
      grid-template-rows: repeat(3, 33.3%);
    }
    .map-container {
      width: 50%;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(3, 33.3%);
      position: relative;
      .items-1 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      .items-2 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        height: 100%;
        width: 100%;
        transform: rotateX(0);
        backface-visibility: hidden;
        transition: 0.5s;
        position: absolute;
        left: 0;
        top: 0;
        animation: spin2 10s linear infinite;
        &:hover {
          animation-play-state: paused;
        }
      }
      .items-3 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
        height: 100%;
        width: 100%;
        transition: 0.5s;
        backface-visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        animation: spin1 10s linear infinite;
        &:hover {
          animation-play-state: paused;
        }
      }
    }
  }
  @keyframes spin1 {
    0% {
      transform: rotateX(0);
    }
    50% {
      transform: rotateX(180deg);
    }
    100% {
      transform: rotateX(0deg);
    }
  }
  @keyframes spin2 {
    0% {
      transform: rotateX(180deg);
    }
    50% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(-180deg);
    }
  }
  .target-title {
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.9);
    .target-title-icon {
      width: 10px;
      height: 10px;
      background-color: rgba(60, 212, 149, 0.9);
      border-radius: 50%;
      // <!--设置圆点与其后面的内容水平显示-->
      display: inline-block;
      margin-right: 5px;
    }
    .target-title-name {
      font-size: 1.7vh;
      margin-left: 20px;
    }
    .target-title-score {
      vertical-align: middle;
      margin-right: 20px;
      span {
        color: rgb(60, 212, 149);
        font-size: 20px;
      }
    }
  }
  .target-chart-lable {
    .target-chart-lable-p1 {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.4vh;
      span {
        color: rgb(60, 212, 149);
        font-size: 2.8vh;
      }
    }
    .target-chart-lable-p1 {
      color: rgb(255, 255, 255);
      font-size: 1.5vh;
    }
  }

  .hyaq {
    width: 80px;
    height: 90px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background: linear-gradient(90.3deg, rgba(60, 212, 149, 0.1) 0.19%, rgba(60, 212, 149, 0) 97.57%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
</style>
