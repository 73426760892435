// 苏州标准--湖泊对象管理水平维度指标数据展示页面
<template>
  <div class="page-body">
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            生态流量保障
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ stysbz.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(stysbz) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="smqczj.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            水流连通畅通
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ slltct.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(slltct) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="slltct.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            水生物种多样
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ sswzdy.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(sswzdy) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="sswzdy.data" :my-height="'150'" />
          </div>
        </div>

        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="map-container">
      <div
        class="items-1"
        style="
          width: 100%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          overflow-y: auto;
          position: relative;
        "
      >
        <Map :rl-data="objInfo.obj" />
        <!-- <div class="pic-container">
          <el-carousel :interval="2000" type="card" height="250px" @change="changeTarget">
            <el-carousel-item v-for="(item, index) in picList" :key="index">
              <img :src="item.url" alt="" width="420px" style="aspect-ratio: 16/9" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
      </div>

      <div class="items-3">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            底栖动物多样
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ dqdwdy.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(adzbfg) !== '{}'" style="display: flex; width: 100%">
          <Dqdw :target-data="dqdwdy.data" />
        </div>

        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
    <div class="target-chart">
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            水鸟栖息状况
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ snqxzk.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(snqxzk) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="snqxzk.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            鱼类保有指数
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ ylbyzs.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(ylbyzs) !== '{}'" style="display: flex">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="ylbyzs.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
      <div style="width: 100%">
        <div class="target-title">
          <div class="target-title-name">
            <div class="target-title-icon"></div>
            底泥清淤合理
          </div>
          <div class="target-title-score">
            得分:
            <span>{{ dnqyhl.score }}</span>
          </div>
        </div>
        <div v-if="JSON.stringify(dnqyhl) !== '{}'" style="text-align: center">
          <div style="height: 140px; width: 100%; overflow: hidden">
            <pic-show :pic-info="dnqyhl.data" :my-height="'150'" />
          </div>
        </div>
        <div v-else style="width: 100%">
          <none-target></none-target>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Map from '../map/objMap.vue'
  import Dqdw from './targetTable/dqsw.vue'

  export default {
    components: {
      Map,
      Dqdw
    },
    props: {
      riverYxqd: {
        type: Object,
        default: () => {}
      },
      objInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        targetDInfoData: [],

        stysbz: {}, // 生态流量保障
        slltct: {}, // 水流连通畅通
        fyzwdy: {}, // 浮游植物多样
        sswzdy: {}, // 水生物种多样
        ylbyzs: {}, // 鱼类保有指数
        snqxzk: {}, // 水鸟栖息状况
        dnqyhl: {}, // 底泥清淤合理
        axzrst: {}, // 岸线生态自然
        dqdwdy: {} // 底栖动物多样
      }
    },
    created() {
      console.log(this.riverYxqd)
      this.childData(this.riverYxqd)
      console.log(this.targetDInfoData)
      this.targetDInfoData.forEach((item) => {
        if (item.code == 'C18') {
          // 生态流量保障
        } else if (item.code == 'C19') {
          // 岸坡干净整洁
          this.slltct.score = item.scoreVal.toFixed(1)
          this.slltct.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.slltct.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C20') {
          //   浮游植物多样
        } else if (item.code == 'C21') {
          // 鱼类保有指数
          this.ylbyzs.score = item.scoreVal.toFixed(1)
          this.ylbyzs.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.ylbyzs.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C22') {
          // 水鸟栖息状况
          this.snqxzk.score = item.scoreVal.toFixed(1)
          this.snqxzk.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.snqxzk.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C23') {
          //   底栖动物多样
          this.dqdwdy.score = item.scoreVal.toFixed(1)
          this.dqdwdy.data = item.targetEntity
        } else if (item.code == 'C24') {
          // 水生物种多样
          this.sswzdy.score = item.scoreVal.toFixed(1)
          this.sswzdy.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.sswzdy.data.push(param)
                })
              }
            })
          }
        } else if (item.code == 'C25') {
          // 岸线自然生态
          this.dnqyhl.score = item.scoreVal.toFixed(1)
          this.dnqyhl.data = []
          if (item.targetEntity.detailList && item.targetEntity.detailList.length > 0) {
            item.targetEntity.detailList.forEach((del) => {
              if (del.pngs && del.pngs != '') {
                let arr = del.pngs.split(',')
                arr.forEach((ar) => {
                  let param = {
                    code: ar,
                    desc: del.sceneDesc
                    // status: del.designRequire ? '达到设计标准' : '不达设计标准'
                  }
                  this.dnqyhl.data.push(param)
                })
              }
            })
          }
        }
      })
    },
    methods: {
      /** 遍历D层指标数据 */
      childData(info) {
        if (info['children']) {
          info.children.forEach((item) => {
            if (item['children']) {
              this.childData(item)
            } else {
              this.targetDInfoData.push(item)
            }
          })
        } else {
          this.targetDInfoData.push(info)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .page-body {
    height: 60vh;
    //   max-height: 90vh;
    overflow: hidden;
    display: flex;
    .target-chart {
      width: 24%;
      display: grid;
    }
    .map-container {
      width: 52%;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: repeat(3, 33.3%);
      .items-1 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      .items-3 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
      }
    }
  }
  .target-title {
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(60, 212, 149, 0.1) 0%, rgba(60, 212, 149, 0) 91.85%);
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.9);
    .target-title-icon {
      width: 10px;
      height: 10px;
      background-color: rgba(60, 212, 149, 0.9);
      border-radius: 50%;
      // <!--设置圆点与其后面的内容水平显示-->
      display: inline-block;
      margin-right: 5px;
    }
    .target-title-name {
      font-size: 1.7vh;
      margin-left: 20px;
    }
    .target-title-score {
      vertical-align: middle;
      margin-right: 20px;
      span {
        color: rgb(60, 212, 149);
        font-size: 20px;
      }
    }
  }
  .target-chart-lable {
    .target-chart-lable-p1 {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.4vh;
      span {
        color: rgb(60, 212, 149);
        font-size: 2.8vh;
      }
    }
    .target-chart-lable-p1 {
      color: rgb(255, 255, 255);
      font-size: 1.5vh;
    }
  }

  .hyaq {
    width: 80px;
    height: 90px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    background: linear-gradient(90.3deg, rgba(60, 212, 149, 0.1) 0.19%, rgba(60, 212, 149, 0) 97.57%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
</style>
