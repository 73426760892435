<template>
  <div>
    <div class="rl-score-box">
      <div class="lakeFraction-top">
        <div class="area-rl-score">
          <p>综合得分</p>
          <span v-if="isActive">{{ objScore }}</span>
          <span v-else
            >{{ objScore }}<span class="local-score">+{{ localScore }}</span></span
          >分
          <div class="qr-code-img" style="cursor: pointer" @click="goQrcode"></div>
        </div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :visible.sync="qrcodeFlag" width="20vw" title="评估二维码" top="20vh">
      <div v-if="qrcodeFlag" id="qrcodeId" ref="qrcode" class="qrcode-container" @click="download"></div>
      <!-- </div>
      </div> -->
    </el-dialog>
  </div>
</template>
<script>
  import QRCode from 'qrcodejs2'

  export default {
    name: '',
    components: {},
    props: {
      rlInfo: {
        type: Object,
        default: () => {}
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        qrcodeInfo: {},
        qrcodeFlag: false,
        objScore: null,
        localScore: null
      }
    },
    computed: {},
    watch: {
      rlInfo: {
        immediate: true,
        handler(val) {
          if (JSON.stringify(val) != '{}') {
            this.loadData(val)
          }
        },
        deep: true
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 数据初始化
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData(data) {
        let choosenRlInfo = data
        let scheme = choosenRlInfo.target
        console.log('===', scheme, choosenRlInfo)
        this.qrcodeInfo = {
          id: choosenRlInfo.token,
          name: choosenRlInfo.obj.name,
          year: choosenRlInfo.pro.year
        }
        this.objScore = scheme.baseScoreVal ? scheme.baseScoreVal.toFixed(1) : 0
        this.localScore = scheme.localScoreVal ? scheme.localScoreVal.toFixed(1) : 0
      },
      /**
       * @description  二维码信息跳转
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      goQrcode() {
        this.qrcodeFlag = true
        let id = this.qrcodeInfo.id
        let name = this.qrcodeInfo.name
        let year = this.qrcodeInfo.year
        let flag = this.isActive
        setTimeout(() => {
          let url =
            window.location.origin +
            window.location.pathname +
            '#/qrcode/' +
            id +
            '/' +
            encodeURIComponent(name) +
            '/' +
            year +
            '/' +
            flag
          // document.getElementById("qrcodeId").innerHTML = "";
          let qrcode = new QRCode('qrcodeId', {
            // 第一个参数是组件的id值
            text: url, // 生成二维码的文本
            width: 200,
            height: 200,
            colorDark: '#000', // 二维码色
            colorLight: '#fff', // 二维码背景色
            correctLevel: QRCode.CorrectLevel.H // 容错等级
          })
        }, 0)
      },
      /**
       * @description 二维码下载
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      download() {
        let canvasData = this.$refs.qrcode.getElementsByTagName('canvas')
        let a = document.createElement('a')
        let event = new MouseEvent('click') // 创建一个单击事件
        a.href = canvasData[0].toDataURL('image/png')
        a.download = '公众满意度调查问卷二维码'
        a.dispatchEvent(event) // 触发a的单击事件
      }
    }
  }
</script>
<style lang="less" scoped>
  @import '../../../assets/css/elui.less';

  .rl-score-box {
    position: absolute;
    z-index: 11;
    left: 23vw;
    top: 100px;
    .lakeFraction-top {
      // width: 170px;
      padding-right: 8px;
      height: 88px;
      background: rgba(20, 31, 41, 0.3);
      display: flex;
      .area-rl-score {
        color: rgba(255, 255, 255, 0.7);
        font-family: PingFang SC;
        font-size: 16px;
        vertical-align: middle;
        margin-left: 16px;
        // width: 130px;
        p {
          margin: 12px 0 0 0;
        }
        span {
          color: rgba(60, 212, 149, 1);
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 40px;
          line-height: 40px;
          text-transform: lowercase;
        }
      }
    }
  }
  .qrcode-container {
    // margin: 12px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .qr-code-main {
    border-radius: 8px;
  }
  .qr-code {
    position: absolute;
    right: 23vw;
    bottom: 24px;
    width: 170px;
    height: 170px;
    border-radius: 8px;
    text-align: center;
    background: rgba(20, 31, 41, 1);
    .obj {
      color: #fff;
      margin-top: 4px;
    }
  }
  .qr-code::before,
  .qr-code::after {
    content: '';
    margin: -5%;
    border-width: 1px;
    border-style: solid;
    border-image-source: linear-gradient(170.76deg, rgba(51, 194, 255, 0.8) -2.13%, rgba(124, 238, 170, 0) 148.73%);
    border-image-slice: 1;
    // // box-sizing: border-box;
    // border-radius: 10px;
    // border: 1px solid rgba(51, 194, 255, 0.8);
    border-radius: 8px;

    animation: clipMe 8s linear infinite;
  }
  .qr-code::before {
    animation-delay: -4s;
  }
  .qr-code::before,
  .qr-code::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @keyframes clipMe {
    0%,
    100% {
      clip: rect(0px, 190px, 2px, 0px);
    }
    25% {
      clip: rect(0px, 2px, 190px, 0px);
    }
    50% {
      clip: rect(178px, 190px, 190px, 0px);
    }
    75% {
      clip: rect(0px, 190px, 190px, 178px);
    }
  }

  .qr-code-img {
    display: inline-block;
    background-image: url('../../../assets/icon/qrcode.png');
    width: 35px;
    height: 35px;
    // position: absolute;
    right: 10px;
    top: 35px;
    background-size: 35px;
  }

  .local-score {
    display: inline-block;
    font-size: 20px !important;
  }
</style>
