<template>
  <div></div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
  }
</script>
<style lang="less" scoped></style>
