<template>
  <div id="proSelection" class="pro-seletion">
    <div class="mark">
      <el-select
        v-model="proValue"
        placeholder="请选择"
        style="width: 100%"
        :popper-append-to-body="false"
        @change="getInfo"
      >
        <!-- <el-option
          v-for="item in proOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        >
        </el-option> -->
        <el-option v-for="item in proOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
  import { Project } from '@/api/pro/index'
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        proInfoList: [],
        proOptions: [],
        proValue: null
      }
    },
    computed: {},
    watch: {},
    created() {
      this.loadPro()
    },
    mounted() {},
    methods: {
      loadPro() {
        this.proOptions = []
        let param = {
          pageSize: 100
        }
        Project.getListByuser(param).then((res) => {
          this.proInfoList = res.data.data
          this.proInfoList.forEach((pro) => {
            // let param = {
            //   value: pro.id,
            //   label: pro.year + '年度'
            // }
            // this.proOptions.push(param)
            this.proOptions.push(pro)
          })
          this.proValue = this.proOptions[this.proOptions.length - 1].id
          this.getInfo()
        })
      },
      getInfo() {
        this.$parent.loadData()
      }
    }
  }
</script>
<style lang="less" scoped>
  .pro-seletion {
    position: absolute;
    z-index: 20;
    right: 449px;
    top: 100px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    .mark {
      display: flex;

      /deep/ .el-input__inner {
        background-color: rgba(20, 31, 41, 0.3);
        border: 0;
        color: #fff;
        width: 100%;
        text-align: center;
      }
    }
  }
  /deep/ .el-select-dropdown {
    background-color: rgb(20, 31, 41) !important;
    border: none;
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: transparent;
    }
  }
  /deep/ .popper__arrow {
    color: rgb(20, 31, 41) !important;
  }
</style>
