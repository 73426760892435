<template>
  <div id="tag" class="map-legend">
    <div class="mark">
      <div class="legend-box">
        <div class="legend-1"></div>
      </div>

      <span>非常健康</span>
    </div>
    <div class="mark">
      <div class="legend-box">
        <div class="legend-2"></div>
      </div>
      <span>健康</span>
    </div>
    <div class="mark">
      <div class="legend-box">
        <div class="legend-3"></div>
      </div>
      <span>亚健康</span>
    </div>
    <div class="mark">
      <div class="legend-box">
        <div class="legend-4"></div>
      </div>
      <span>不健康</span>
    </div>
    <div class="mark">
      <div class="legend-box">
        <div class="legend-5"></div>
      </div>
      病态
    </div>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
  }
</script>
<style lang="less" scoped>
  .map-legend {
    width: 5.8vw;
    position: absolute;
    z-index: 2;
    left: 23vw;
    bottom: 24px;
    color: rgba(255, 255, 255, 0.9);
    font-family: 'PingFang SC';
    font-size: 14px;
    background: rgba(20, 31, 41, 0.3);
    backdrop-filter: blur(20px);
    // background: rgba(196, 196, 196, 1);
    // box-shadow: 0px 14px 42px rgba(26, 62, 153, 0.12);
    transition-property: right;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    .mark {
      position: relative;
      margin: 1.5vh 0.8vw 0.7vh 0.8vw;
      height: 16px;
      line-height: 16px;
      div {
        display: inline-block;
      }
      .legend-box {
        width: 16px;
        height: 16px;
      }
      .legend-1 {
        width: 10px;
        height: 10px;
        margin: 3px 3px 0 3px;
        background-color: rgba(26, 177, 214, 1);
      }
      .legend-2 {
        width: 10px;
        height: 10px;
        margin: 3px 3px 0 3px;
        background-color: rgba(124, 234, 189, 1);
      }
      .legend-3 {
        width: 10px;
        height: 10px;
        margin: 3px 3px 0 3px;
        background-color: rgba(251, 252, 84, 1);
      }
      .legend-4 {
        width: 10px;
        height: 10px;
        margin: 3px 3px 0 3px;
        background-color: rgba(239, 138, 52, 1);
      }
      .legend-5 {
        width: 10px;
        height: 10px;
        background-color: rgba(213, 56, 38, 1);
        margin: 3px 3px 0 3px;
      }
    }
  }
</style>
