<template>
  <div class="benthic-page">
    <el-table
      :data="totalTaleData"
      header-cell-class-name="table-header-cls"
      style="width: 100%; font-size: 12px; text-align: center"
    >
      <el-table-column :label="'营养状态指数  ' + score + ' 分'" align="center" header-align="center">
        <el-table-column prop="mon" label="日期" align="center" header-align="center"> </el-table-column>
        <el-table-column prop="zongLin" label="总磷(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.zongLin }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="zongDan" label="总氮(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.zongDan }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="yeLvSu" label="叶绿素α(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.yeLvSu }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="gaoMengSuanYan" label="高锰酸盐指数(mg/L)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.gaoMengSuanYan }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="touMingDu" label="透明度(m)" align="center" header-align="center">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.touMingDu }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="synthesis"
          label="营养状态指数"
          align="center"
          header-align="center"
          fixed="right"
        ></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import { Site } from '@/api/scheme/index.js'

  export default {
    // 水质优劣程度
    name: 'Yyzt',
    components: {},
    props: {
      targetData: {
        type: Object,
        default: () => {}
      },
      score: {
        type: [Number, String],
        default: null
      }
    },
    data() {
      return {
        flag: true,

        finalScore: null,
        activeName: 'first',
        // 计算过程中表格数据
        // 计算过程单次采样数据（总）
        data: [],

        totalTaleData: [],

        siteInfo: [],
        siteList: [],
        len: 0, // 采样次数

        id: null,
        queryExt: {},
        attachs: '',

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.getSiteList(val, val.schemeId)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description  获取对象站点信息
       * @author hu_ty
       * @since
       * @param {number} val 对象id
       *
       */
      getSiteList(val, i) {
        let id
        if (i && i != 'undefined') {
          id = i
        }
        let param = {
          schemeId: id,
          pageSize: 100
        }
        Site.getList(param).then((res) => {
          let siteInfo1 = res.data.data.list
          let arr = siteInfo1.filter((site) => site.siteType === 'Normal')
          this.siteInfo = arr
          this.createData()
          this.loadData(val)
        })
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        let arr = data.detailList
        // 1.找出采样的站点信息进行数据分类第一步准备
        let site = []
        arr.forEach((item) => {
          site.push(item.siteId)
          const i = this.siteInfo.find((i) => {
            return i.id == item.siteId
          })
        })
        let newSite = [...new Set(site)]

        // 2.依照采样站点信息将数据分组
        this.data = []
        newSite.forEach((site) => {
          let tableData = []
          arr.forEach((item) => {
            if (item.siteId === site) {
              tableData.push(item)
            }
          })
          let newArr = []
          this.siteList.forEach((si) => {
            if (si.tableData[0].siteId == site) {
              for (let ii = 0; ii < this.len; ii++) {
                newArr[ii] = Object.assign(si.tableData[ii], tableData[ii])
              }
              // newArr[this.len] = si.tableData[this.len]
            }
          })
          let param = {
            tableData: newArr
          }
          this.data.push(param)
        })
        // 3.添加新的监测点信息
        this.siteList.forEach((site) => {
          const i = newSite.findIndex((i) => {
            return i == site.tableData[0].siteId
          })
          if (i == -1) {
            this.data.push(site)
          }
        })

        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
        this.alculateResults()
      },
      createData() {
        this.siteList = []
        this.siteInfo.forEach((item) => {
          this.len = item.szylTimes
          let arr = []
          for (let i = 1; i <= this.len; i++) {
            let timeParam = {
              site: item.name + '第' + i + '次采样',
              siteId: item.id,
              samplingDate: '',
              yeLvSu: NaN,
              yeLvSuScore: NaN,
              gaoMengSuanYan: NaN,
              gaoMengSuanYanScore: NaN,
              zongDan: NaN,
              zongDanScore: NaN,
              zongLin: NaN,
              zongLinScore: NaN,
              synthesis: null,
              touMingDu: null,
              touMingDuScore: null
            }
            arr.push(timeParam)
          }
          let param = {
            tableData: JSON.parse(JSON.stringify(arr))
          }
          this.siteList.push(param)
        })
      },
      /**
       * @description 表格数据内容依据GB3838 评定水质类别
       * @author hu_ty
       * @since
       * @param {object} data 所在行数据（单次采样化验数据）
       *
       */
      countLine(data) {
        if (data.yeLvSu && data.yeLvSu != 'NaN') {
          let yeLvSu = parseFloat(data.yeLvSu)
          let grade
          switch (true) {
            case yeLvSu <= 0.0005:
              grade = (yeLvSu / 0.0005) * 10
              break
            case yeLvSu <= 0.001:
              grade = 10 + ((yeLvSu - 0.0005) / 0.0005) * 10
              break
            case yeLvSu <= 0.002:
              grade = 20 + ((yeLvSu - 0.001) / 0.001) * 10
              break
            case yeLvSu <= 0.004:
              grade = 30 + ((yeLvSu - 0.002) / 0.002) * 10
              break
            case yeLvSu <= 0.01:
              grade = 40 + ((yeLvSu - 0.004) / 0.006) * 10
              break
            case yeLvSu <= 0.026:
              grade = 50 + ((yeLvSu - 0.01) / 0.016) * 10
              break
            case yeLvSu <= 0.064:
              grade = 60 + ((yeLvSu - 0.026) / 0.038) * 10
              break
            case yeLvSu <= 0.16:
              grade = 70 + ((yeLvSu - 0.064) / 0.096) * 10
              break
            case yeLvSu <= 0.4:
              grade = 80 + ((yeLvSu - 0.16) / 0.24) * 10
              break
            case yeLvSu <= 1.0:
              grade = 90 + ((yeLvSu - 0.4) / 0.6) * 10
              break
            case yeLvSu > 1.0:
              grade = 100
              break
            default:
          }
          data.yeLvSuScore = grade
        }
        if (data.gaoMengSuanYan && data.gaoMengSuanYan != 'NaN') {
          let gaoMengSuanYan = parseFloat(data.gaoMengSuanYan)
          let grade
          switch (true) {
            case gaoMengSuanYan <= 0.15:
              grade = (gaoMengSuanYan / 0.15) * 10
              break
            case gaoMengSuanYan <= 0.4:
              grade = 10 + ((gaoMengSuanYan - 0.15) / 0.25) * 10
              break
            case gaoMengSuanYan <= 1:
              grade = 20 + ((gaoMengSuanYan - 0.4) / 0.6) * 10
              break
            case gaoMengSuanYan <= 2:
              grade = 30 + ((gaoMengSuanYan - 1) / 1) * 10
              break
            case gaoMengSuanYan <= 4:
              grade = 40 + ((gaoMengSuanYan - 2) / 2) * 10
              break
            case gaoMengSuanYan <= 8:
              grade = 50 + ((gaoMengSuanYan - 4) / 4) * 10
              break
            case gaoMengSuanYan <= 10:
              grade = 60 + ((gaoMengSuanYan - 8) / 2) * 10
              break
            case gaoMengSuanYan <= 25:
              grade = 70 + ((gaoMengSuanYan - 10) / 15) * 10
              break
            case gaoMengSuanYan <= 40:
              grade = 80 + ((gaoMengSuanYan - 25) / 15) * 10
              break
            case gaoMengSuanYan <= 60:
              grade = 90 + ((gaoMengSuanYan - 40) / 20) * 10
              break
            case gaoMengSuanYan > 60:
              grade = 100
              break
            default:
          }
          data.gaoMengSuanYanScore = grade
        }
        if (data.zongLin && data.zongLin != 'NaN') {
          let zongLin = parseFloat(data.zongLin)
          let grade
          switch (true) {
            case zongLin <= 0.001:
              grade = (zongLin / 0.001) * 10
              break
            case zongLin <= 0.004:
              grade = 10 + ((zongLin - 0.001) / 0.003) * 10
              break
            case zongLin <= 0.01:
              grade = 20 + ((zongLin - 0.004) / 0.006) * 10
              break
            case zongLin <= 0.025:
              grade = 30 + ((zongLin - 0.01) / 0.015) * 10
              break
            case zongLin <= 0.05:
              grade = 40 + ((zongLin - 0.025) / 0.025) * 10
              break
            case zongLin <= 0.1:
              grade = 50 + ((zongLin - 0.05) / 0.05) * 10
              break
            case zongLin <= 0.2:
              grade = 60 + ((zongLin - 0.1) / 0.1) * 10
              break
            case zongLin <= 0.6:
              grade = 70 + ((zongLin - 0.2) / 0.4) * 10
              break
            case zongLin <= 0.9:
              grade = 80 + ((zongLin - 0.6) / 0.3) * 10
              break
            case zongLin <= 1.3:
              grade = 90 + ((zongLin - 0.9) / 0.4) * 10
              break
            case zongLin > 1.3:
              grade = 100
              break
            default:
          }
          data.zongLinScore = grade
        }
        if (data.zongDan && data.zongDan != 'NaN') {
          let zongDan = parseFloat(data.zongDan)
          let grade
          switch (true) {
            case zongDan <= 0.02:
              grade = (zongDan / 0.02) * 10
              break
            case zongDan <= 0.05:
              grade = 10 + ((zongDan - 0.02) / 0.03) * 10
              break
            case zongDan <= 0.1:
              grade = 20 + ((zongDan - 0.05) / 0.05) * 10
              break
            case zongDan <= 0.3:
              grade = 30 + ((zongDan - 0.1) / 0.2) * 10
              break
            case zongDan <= 0.5:
              grade = 40 + ((zongDan - 0.3) / 0.2) * 10
              break
            case zongDan <= 1:
              grade = 50 + ((zongDan - 0.5) / 0.5) * 10
              break
            case zongDan <= 2:
              grade = 60 + ((zongDan - 1) / 1) * 10
              break
            case zongDan <= 6:
              grade = 70 + ((zongDan - 2) / 4) * 10
              break
            case zongDan <= 9:
              grade = 80 + ((zongDan - 6) / 3) * 10
              break
            case zongDan <= 16:
              grade = 90 + ((zongDan - 9) / 7) * 10
              break
            case zongDan > 16:
              grade = 100
              break
            default:
          }
          data.zongDanScore = grade
        }
        if (data.touMingDu && data.touMingDu != 'NaN') {
          let touMingDu = parseFloat(data.touMingDu)
          let grade
          switch (true) {
            case touMingDu <= 0.12:
              grade = 100
              break
            case touMingDu <= 0.2:
              grade = 100 - ((touMingDu - 0.12) / 0.08) * 10
              break
            case touMingDu <= 0.3:
              grade = 90 - ((touMingDu - 0.2) / 0.1) * 10
              break
            case touMingDu <= 0.4:
              grade = 80 - ((touMingDu - 0.3) / 0.1) * 10
              break
            case touMingDu <= 0.5:
              grade = 70 - ((touMingDu - 0.4) / 0.1) * 10
              break
            case touMingDu <= 1:
              grade = 60 - ((touMingDu - 0.5) / 0.5) * 10
              break
            case touMingDu <= 1.5:
              grade = 50 - ((touMingDu - 1) / 0.5) * 10
              break
            case touMingDu <= 3:
              grade = 40 - ((touMingDu - 1.5) / 1.5) * 10
              break
            case touMingDu <= 5:
              grade = 30 - ((touMingDu - 3) / 2) * 10
              break
            case touMingDu <= 10:
              grade = 20 - ((touMingDu - 5) / 5) * 10
              break
            case touMingDu > 10:
              grade = 0
              break
            default:
          }
          data.touMingDuScore = grade
        }
        let gradelist = [
          data.yeLvSuScore,
          data.zongDanScore,
          data.zongLinScore,
          data.gaoMengSuanYanScore,
          data.touMingDuScore
        ]
        let totalScore = 0
        let count = 0
        gradelist.forEach((grade) => {
          if (grade != null && grade != 'null') {
            count++
            totalScore += grade
          }
        })
        data.synthesis = totalScore / count

        return data
      },
      count(data, scope, domain) {
        data = this.countLine(data)
      },
      /**
       * @description  统计相同月份，年度平均结果
       * @author hu_ty
       * @since 22-06-30
       * @param {*}
       *
       */
      alculateResults() {
        let arr = []
        let monthList = [] // 采样月份列表（年月）
        this.data.forEach((item) => {
          item.tableData.forEach((da) => {
            if (da.samplingDate && da.samplingDate !== '-') {
              arr.push(da)
              monthList.push({ month: da.samplingDate.split('-')[0] + '-' + da.samplingDate.split('-')[1] })
            }
          })
        })
        // debugger
        // 去除 monthList 中的重复对象
        var hash = {}
        monthList = monthList.reduce(function (item, next) {
          hash[next.month] ? '' : (hash[next.month] = true && item.push(next))
          return item
        }, [])

        // 对应采样月份中插入相应的采样化验数据
        monthList.forEach((item) => {
          item.data = []
          arr.forEach((data) => {
            if (data.samplingDate.includes(item.month)) {
              item.data.push(data)
            }
          })
        })

        // 获取水质采样属性列表
        let propertyName = []
        monthList.forEach((item) => {
          item.data.forEach((da) => {
            for (let key in da) {
              propertyName.push(key)
            }
          })
        })

        // 计算同月份的平均数据
        let array = []
        monthList.forEach((data) => {
          let newdata = {}
          newdata.mon = data.month
          propertyName.forEach((pro) => {
            let i = 0
            let len = 0
            data.data.forEach((sim) => {
              if (sim[pro] != 'NaN') {
                i += parseFloat(sim[pro])
                len += 1
              }
            })
            newdata[pro] = (i / len).toFixed(1)
          })
          array.push(newdata)
        })

        // 计算各指标的评定类别并返回table 数据
        this.totalTaleData = []
        array.forEach((tol) => {
          const data = this.countLine(tol)
          this.totalTaleData.push(data)
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .benthic-page {
    .el-row {
      margin: 16px;
    }
  }
  .data-container {
    max-height: 600px;
    overflow: auto;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .table-tow-row-container {
    display: flex;
    flex-direction: column;
    div {
      flex: 1;
    }
  }

  /deep/ input {
    border: 0;
    color: rgb(0, 0, 0) 80%;
    text-align: center;
  }
</style>
