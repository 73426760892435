<template>
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  export default {
    components: {
      Pie
    },
    props: {
      openDoorInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      var a = this.openDoorInfo.total
      var e = this.openDoorInfo.ni
      this.options = {
        title: {
          text: (e / a).toFixed(2) * 100 + '%',
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: '25',
            color: '#FFFFFF',
            fontFamily: 'DINAlternate-Bold, DINAlternate',
            foontWeight: '600'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['85%', '95%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: 'rgb(60, 212, 149)' },
                      { offset: 1, color: 'rgba(60, 212, 149,0.3)' }
                    ])
                  ]
                ]
              }
            },
            data: [
              {
                value: e,
                name: '',
                itemStyle: {
                  normal: {
                    color: 'rgb(60, 212, 149)'
                  }
                },
                label: {
                  show: false
                }
              },
              {
                value: a - e,
                name: '',
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: 'rgba(60, 212, 149,0.1)'
                  }
                }
              }
            ]
          },

          {
            name: '',
            type: 'gauge',
            radius: '122%',
            center: ['50%', '50%'],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 50,
            hoverAnimation: true,
            axisTick: {
              show: false
            },
            splitLine: {
              length: 8,
              lineStyle: {
                width: 2,
                color: 'rgb(20, 31, 41)'
              }
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            detail: {
              show: false
            }
          }
          // {
          //   type: "gauge",
          //   radius: "90%",
          //   center: ["50%", "50%"],
          //   clockwise: false,
          //   startAngle: 0,
          //   endAngle: 359.9,
          //   splitNumber: 40,
          //   detail: {
          //     offsetCenter: [0, -200],
          //     formatter: " "
          //   },
          //   pointer: {
          //     show: false
          //   },
          //   axisLine: {
          //     show: true,
          //     lineStyle: {
          //       color: [
          //         [0, "rgba(60, 212, 149, 1)"],
          //         [e / a, "#1DE2A4"],
          //         [1, "rgba(60, 212, 149, 0.1)"]
          //       ],
          //       width: 10
          //     }
          //   },
          //   axisTick: {
          //     show: false
          //   },
          //   splitLine: {
          //     show: false,
          //     length: 60,
          //     lineStyle: {
          //       color: "rgba(60, 212, 149)",
          //       // color:'rgb(20, 31, 41,0.9)',
          //       width: 5
          //     }
          //   },
          //   axisLabel: {
          //     show: false
          //   }
          // }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
    //   .dv-dec-1 {
    //     position: relative;
    //     width: 100px;
    //     height: 20px;
    //     top: -3px;
    //   }
    //   .chart-box {
    //     margin-top: 16px;
    //     width: 170px;
    //     height: 170px;
    //     .active-ring-name {
    //       padding-top: 10px;
    //     }
    //   }
  }
</style>
