<template>
  <!-- 生态水位满足率 -->
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  // import 'https://cdn.jsdelivr.net/npm/echarts-liquidfill@2/dist/echarts-liquidfill.min.js'
  import liquidfill from 'echarts-liquidfill'
  export default {
    components: {
      Pie
    },
    props: {
      waterQualityStandardInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      let total = this.waterQualityStandardInfo.total // 全部测次
      let num = this.waterQualityStandardInfo.standardMeasurement // 满足测次
      this.options = {
        series: [
          {
            type: 'liquidFill',
            radius: '100%',
            center: ['50%', '50%'],
            data: [num / total, num / total, num / total], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'rgb(3, 10, 32)'
            },
            // 修改波浪颜色
            color: ['rgb(60, 212, 149)'], //所有波浪一个颜色
            // color:['yellow','red','pink'], 每个波浪不同颜色，颜色数组长度为对应的波浪个数
            label: {
              normal: {
                formatter: (num / total).toFixed(2) * 100 + '%',
                textStyle: {
                  fontSize: 20,
                  color: 'rgb(255, 255, 255)'
                }
              }
            },
            outline: {
              show: false
            }
          }
        ]
      }
      // function numb() {
      //   num = num + 5;
      // //   myChart.setOption(option, true);
      // }
      // setInterval(function() {
      //   numb();
      // }, 100);
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
  }
</style>
