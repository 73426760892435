var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rl-score-info-box",attrs:{"id":"rlBox"}},[_c('div',{staticClass:"title-text",class:_vm.score > 90
        ? 'colorOver90 score borderLeftBlue'
        : _vm.score > 80
        ? 'colorOver80 score borderLeftGreen'
        : _vm.score > 60
        ? 'colorOver60 score borderLeftYellow'
        : _vm.score > 40
        ? 'colorOver40 score borderLeftOrange'
        : 'score colorOther borderLeftRed'},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.objName))]),_c('span',{staticClass:"score"},[_vm._v(_vm._s(_vm.score))]),_c('span',{staticClass:"point"},[_vm._v("分")])]),_c('div',{staticClass:"text-score"},[_vm._m(0),_c('div',{staticClass:"score-box"},_vm._l((_vm.delScoreList),function(item,index){return _c('div',{key:index,staticClass:"norm-index"},[_c('p',{staticClass:"norm-healthy"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"norm-grade"},[_vm._v(" "+_vm._s(item.scoreVal.toFixed(1))+" "),_c('span',[_vm._v("分")])])])}),0),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popLine"},[_c('div',{staticClass:"lin"},[_vm._v("-")]),_c('div',{staticClass:"tran"}),_c('div',{staticClass:"lin"},[_vm._v("-")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popLine"},[_c('div',{staticClass:"lin"},[_vm._v("-")]),_c('div',{staticClass:"tran"}),_c('div',{staticClass:"lin"},[_vm._v("-")])])}]

export { render, staticRenderFns }