<template>
  <!-- 岸线开发利用率 -->
  <div id="centerLeft1">
    <Pie :options="options"></Pie>
  </div>
</template>

<script>
  import Pie from '@/components/echart/pie.vue'
  import * as echarts from 'echarts'
  export default {
    components: {
      Pie
    },
    props: {
      shorelineDevelopmentInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        options: {}
      }
    },
    created() {
      var a = this.shorelineDevelopmentInfo.total
      var e = this.shorelineDevelopmentInfo.occupyLen
      this.options = {
        title: [
          {
            text: a ? ((e / a) * 100).toFixed(2) + '%' : '--',
            x: 'center',
            y: 'center',
            textStyle: {
              fontSize: '25',
              color: '#FFFFFF',
              fontFamily: 'DINAlternate-Bold, DINAlternate',
              foontWeight: '600'
            }
          }
          // {
          //   text: (e / a).toFixed(2) * 100 + "%",
          //   x: "65%",
          //   y: "center",
          //   textStyle: {
          //     fontSize: "25",
          //     color: "#FFFFFF",
          //     fontFamily: "DINAlternate-Bold, DINAlternate",
          //     foontWeight: "600"
          //   }
          // }
        ],
        series: [
          {
            type: 'pie',
            radius: ['80%', '95%'],
            center: ['50%', '50%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: 'rgb(60, 212, 149)' },
                      { offset: 1, color: 'rgba(60, 212, 149,0.3)' }
                    ])
                  ]
                ]
              }
            },
            data: [
              {
                value: e,
                name: '',
                itemStyle: {
                  normal: {
                    color: 'rgb(60, 212, 149)'
                  }
                },
                label: {
                  show: false
                }
              },
              {
                value: a - e,
                name: '',
                label: {
                  normal: {
                    show: false
                  }
                },
                itemStyle: {
                  normal: {
                    color: 'rgba(60, 212, 149,0.1)'
                  }
                }
              }
            ]
          },

          {
            name: '',
            type: 'gauge',
            radius: '120%',
            center: ['50%', '50%'],
            startAngle: 90,
            endAngle: 450,
            splitNumber: 25,
            hoverAnimation: true,
            axisTick: {
              show: false
            },
            splitLine: {
              length: 10,
              lineStyle: {
                width: 4,
                color: 'rgb(20, 31, 41)'
              }
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            detail: {
              show: false
            }
          }
          // {
          //   type: "pie",
          //   radius: ["70%", "85%"],
          //   center: ["75%", "50%"],
          //   silent: true,
          //   clockwise: true,
          //   startAngle: 90,
          //   z: 0,
          //   zlevel: 0,
          //   axisLine: {
          //     lineStyle: {
          //       color: [
          //         [
          //           1,
          //           new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //             { offset: 0, color: "rgb(60, 212, 149)" },
          //             { offset: 1, color: "rgba(60, 212, 149,0.3)" }
          //           ])
          //         ]
          //       ]
          //     }
          //   },
          //   data: [
          //     {
          //       value: e,
          //       name: "",
          //       itemStyle: {
          //         normal: {
          //           color: "rgb(60, 212, 149)"
          //         }
          //       },
          //       label: {
          //         show: false
          //       }
          //     },
          //     {
          //       value: a - e,
          //       name: "",
          //       label: {
          //         normal: {
          //           show: false
          //         }
          //       },
          //       itemStyle: {
          //         normal: {
          //           color: "rgba(60, 212, 149,0.1)"
          //         }
          //       }
          //     }
          //   ]
          // },

          // {
          //   name: "",
          //   type: "gauge",
          //   radius: "110%",
          //   center: ["75%", "50%"],
          //   startAngle: 90,
          //   endAngle: 450,
          //   splitNumber: 25,
          //   hoverAnimation: true,
          //   axisTick: {
          //     show: false
          //   },
          //   splitLine: {
          //     length: 10,
          //     lineStyle: {
          //       width: 4,
          //       color: "rgb(20, 31, 41)"
          //     }
          //   },
          //   axisLabel: {
          //     show: false
          //   },
          //   pointer: {
          //     show: false
          //   },
          //   axisLine: {
          //     lineStyle: {
          //       opacity: 0
          //     }
          //   },
          //   detail: {
          //     show: false
          //   }
          // }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  #centerLeft1 {
    //   $box-width: 100%;
    //   $box-height: 100%;
    //   padding: 16px;
    height: 100%;
    min-width: 100%;
    border-radius: 5px;
    // background-color: red;
    .bg-color-black {
      // height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
    //   .dv-dec-1 {
    //     position: relative;
    //     width: 100px;
    //     height: 20px;
    //     top: -3px;
    //   }
    //   .chart-box {
    //     margin-top: 16px;
    //     width: 170px;
    //     height: 170px;
    //     .active-ring-name {
    //       padding-top: 10px;
    //     }
    //   }
  }
</style>
